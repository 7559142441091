import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as AccountAchievementActions from './account-achievements.actions';
import {accountAchievementsSelector} from './account-achievements.selector';
import {map, switchMap, withLatestFrom} from "rxjs";
import {AchievementService} from "../../services/http/achievement.service";

@Injectable()
export class AccountAchievementEffect {
  constructor(
    private store: Store,
    private actions$: Actions,
    private readonly achievementService: AchievementService,
  ) {
  }

  updateAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountAchievementActions.UPDATE),
        withLatestFrom(this.store.select(accountAchievementsSelector)),
        // tap(([, universe]) => this.sentryService.setUser(universe)),
      ),
    {dispatch: false},
  );

  refreshResources$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountAchievementActions.REFRESH),
        switchMap(() => {
          console.log("reloading achievements");
          return this.achievementService.getAccountAchievements().pipe(
            map(accountAchievement => {
              return AccountAchievementActions.update({accountAchievement})
            })
          )
        }),
      )
  );
}
