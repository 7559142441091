import {Component} from '@angular/core';
import {MarketService} from "../../../../services/http/market.service";
import {MarketOffer} from "../../../../models/MarketOffer";
import {TableModule} from "primeng/table";
import {ButtonModule} from "primeng/button";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import {planetSelector} from "../../../../store/planet";
import {filter, map, tap} from "rxjs/operators";
import {Planet} from "../../../../models/Planet";
import {ResourcesUtils} from "../../../../utils/ResourcesUtils";
import {refresh as refreshResources, planetResourceSelector} from "../../../../store/resource";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {PlanetResource} from "../../../../models/PlanetResource";
import {cloneDeep} from "lodash-es";
import {Resource} from "../../../../models/Resource";
import {CreateMarketOffer} from "../../../../models/requests/CreateMarketOffer";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ResourceType} from "../../../../models/Enum/ResourceType";
import {OverlayModule} from "primeng/overlay";
import {DialogModule} from "primeng/dialog";
import {DropdownModule} from "primeng/dropdown";
import {InputNumberModule} from "primeng/inputnumber";
import {MessageService} from "primeng/api";
import {DatePipe} from "@angular/common";
import {DefaultMessageResponse} from "../../../../models/DefaultMessageResponse";
import {SharedModule} from "../../../../shared.module";

@UntilDestroy()
@Component({
  selector: 'app-market',
  standalone: true,
  imports: [
    TableModule,
    ButtonModule,
    TranslateModule,
    OverlayModule,
    DialogModule,
    DropdownModule,
    InputNumberModule,
    FormsModule,
    ReactiveFormsModule,
    DatePipe,
    SharedModule
  ],
  templateUrl: './market.component.html',
  styleUrl: './market.component.scss'
})
export class MarketComponent {

  marketOffers: MarketOffer[] = [];
  isLoading = true;
  universeAccountId: number;
  resources: PlanetResource[] = [];
  resourceList: PlanetResource[] = [];
  showOwnOffers = false;
  showGlobalOffers = true;
  currentPage = 1;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  showCreateOffer = false;
  private planet: Planet;
  marketOfferForm = this.fb.group({
    resources_buying: this.fb.group({
      resource: this.fb.control<PlanetResource>(null),
      amount: this.fb.control(1),
    }),
    resources_selling: this.fb.group({
      resource: this.fb.control<PlanetResource>(null),
      amount: this.fb.control(1)
    }),
  });
  forceDisable = false;


  constructor(
    private readonly store: Store,
    private readonly marketService: MarketService,
    private readonly fb: FormBuilder,
    private readonly toastService: MessageService,
    private readonly translate: TranslateService
  ) {
    this.store
      .pipe(
        select(planetSelector),
        filter((planet: Planet) => !!planet),
        tap((planet: Planet) => {
          this.planet = planet;
          this.universeAccountId = planet.universe_account_id;
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store
      .pipe(
        select(planetResourceSelector),
        filter((resources: PlanetResource[]) => !!resources && resources.length > 0),
        tap((resources) => {
          this.resources = cloneDeep<PlanetResource[]>(resources).filter((resource) => {
            return resource.type === ResourceType.BUILD;
          });
          if (this.resourceList.length === 0) {
            this.resourceList = this.resources.sort((a, b) => a.id - b.id);
            if (this.marketOfferForm.value.resources_buying.resource === null) {
              this.marketOfferForm.patchValue({
                resources_buying: {
                  resource: this.resourceList[1]
                }
              });
            }
            if (this.marketOfferForm.value.resources_selling.resource === null) {
              this.marketOfferForm.patchValue({
                resources_selling: {
                  resource: this.resourceList[0]
                }
              });
            }
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.refreshList()
  }

  getMaxValue(id: number | null = null) {
    return Math.floor(this.resources.find(r => r.id === id)?.pivot.value ?? 0);
  }

  refreshList(currentPage: number = null) {
    if (currentPage === null) {
      currentPage = this.currentPage;
    }
    this.isLoading = true;
    this.marketOffers = [];
    let type = 'all';
    if (this.showOwnOffers) {
      type = 'own';
    } else if (this.showGlobalOffers) {
      type = 'global';
    }
    this.marketService.getMarketOffers(type, currentPage).subscribe((paginatedOffers) => {
      this.marketOffers = paginatedOffers.data;
      this.currentPage = paginatedOffers.current_page;
      this.hasNextPage = paginatedOffers.next_page_url !== null;
      this.hasPrevPage = paginatedOffers.prev_page_url !== null;
      this.isLoading = false;
    });
  }

  buyOffer(offerId: string) {
    this.marketService.buyOffer(offerId, this.planet.id, 'planet').subscribe((response) => {
      if (response?.success) {
        this.toastService.add({severity: 'success', summary: 'Success', detail: response.message});
        this.store.dispatch(refreshResources());
      }
      this.refreshList();
    });
  }

  deleteOffer(offerId: string) {
    this.marketService.deleteOffer(offerId).subscribe((response) => {
      if (response?.success) {
        this.toastService.add({severity: 'success', summary: 'Success', detail: response.message});
      } else {
        this.toastService.add({severity: 'error', summary: 'Error', detail: response.message});
      }
      this.refreshList();
      this.store.dispatch(refreshResources());
    });
  }

  canBuy(resources) {
    let formattedResources: Resource[] = [];
    resources.forEach(resource => {
      formattedResources.push({
        name: resource.slug,
        value: resource.pivot.amount
      });
    });
    return !ResourcesUtils.isBuyable(this.resources, formattedResources)
  }

  createOffer() {
    let createMarketOffer = new CreateMarketOffer();
    createMarketOffer.from_id = this.planet.id;
    createMarketOffer.from_type = 'planet';
    createMarketOffer.type = 'global';
    createMarketOffer.resources_buying = [{
      resource_id: this.marketOfferForm.value.resources_buying.resource.id,
      amount: this.marketOfferForm.value.resources_buying.amount
    }];
    createMarketOffer.resources_selling = [
      {
        resource_id: this.marketOfferForm.value.resources_selling.resource.id,
        amount: this.marketOfferForm.value.resources_selling.amount
      }
    ];
    this.forceDisable = true;
    this.marketService.createOffer(createMarketOffer).subscribe((response) => {
      if (Object.hasOwn(response, 'id')) {
        this.marketOfferForm.patchValue({
          resources_buying: {
            resource: this.resourceList[1],
            amount: 1
          },
          resources_selling: {
            resource: this.resourceList[0],
            amount: 1
          }
        });
        this.showCreateOffer = false;
        if (this.showOwnOffers) {
          this.refreshList();
        }
        this.store.dispatch(refreshResources());
        this.translate.get('market.toast.offer_created').subscribe((res: string) => {
          this.toastService.add({severity: 'success', summary: 'Success', detail: res});
        });

      } else {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: (response as DefaultMessageResponse).message
        });
      }
      this.forceDisable = false;
    });
  }

  getRemainingTime(endTime: string): number {
    let endDateTime = new Date(endTime);
    return Math.round((endDateTime.getTime() - (new Date()).getTime()) / 1000);
  }
}
