<div>
  @if (tradeRoute && ((selectedSourcePlanet && selectedTargetPlanet && selectedResource) || isCreation)) {
    <p-card>
      <div class="flex align-items-center">
        <div>
          <p-dropdown [options]="planets" optionLabel="displayName" [(ngModel)]="selectedSourcePlanet"></p-dropdown>
        </div>
        <div>
          <fa-icon [icon]="faAngleDoubleRight"/>
        </div>
        <div>
          <p-dropdown [options]="planets" optionLabel="displayName" [(ngModel)]="selectedTargetPlanet"></p-dropdown>
        </div>
      </div>
      <div class="flex">
        <p-dropdown [options]="resources" optionLabel="slug" [(ngModel)]="selectedResource"></p-dropdown>
        <p-inputNumber [(ngModel)]="tradeRoute.quantity" [min]="1"></p-inputNumber>
      </div>
      <div>
        <p-calendar [(ngModel)]="tradeRoute.trigger_time" dataType="string" [timeOnly]="true" placeholder="H:m"
                    appendTo="body"/>
      </div>
      @if (!isCreation) {
        <div>
          <span>Status: {{ isActive() ? 'actif ( jusqu\'au ' + tradeRoute.active_until + ')' : 'inactif' }}</span>
        </div>
      }
      <ng-template pTemplate="footer">
        @if (isCreation) {
          <p-button (onClick)="create()" label="Créer"/>
        } @else {
          <p-button (onClick)="update()" label="Mettre à jour"/>
          @if (isActive()) {
            <p-button class="pl-2" severity="danger" (onClick)="deactivate()" label="Désactiver"/>
          }
          @if (canRenewActivation()) {
            <p-button class="pl-2" severity="success" (onClick)="activate()"
                      [label]="isActive() ? 'Renouveler pour 7 jours' : 'Activer pour 7 jours'"/>
          }
        }
      </ng-template>
    </p-card>
  }
</div>
