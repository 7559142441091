import {Component, Input, ViewChild} from '@angular/core';
import {UniverseService} from "../../../../services/http/universe.service";
import {SpyReport} from "../../../../models/SpyReport";
import {TranslateModule} from "@ngx-translate/core";
import {AccordionModule} from "primeng/accordion";
import {DialogModule} from "primeng/dialog";
import {SimulationReportComponent} from "../simulation/simulation-report/simulation-report.component";
import {BattleSimulatorComponent} from "../battle-simulator/battle-simulator.component";
import {ButtonModule} from "primeng/button";

@Component({
  selector: 'app-spy-report',
  standalone: true,
  imports: [
    TranslateModule,
    AccordionModule,
    DialogModule,
    SimulationReportComponent,
    BattleSimulatorComponent,
    ButtonModule
  ],
  templateUrl: './spy-report.component.html',
  styleUrl: './spy-report.component.scss'
})
export class SpyReportComponent {
  @Input() spyReport: SpyReport;
  @ViewChild(BattleSimulatorComponent) battleSimulator: BattleSimulatorComponent;
  showSimulator = false;

  get reportDate() {
    return (new Date(this.spyReport.created_at)).toLocaleString();
  }
  openSimulator() {
    this.battleSimulator.fillWithSpyData(this.spyReport.spy_data);
    this.showSimulator = true;
  }
}
