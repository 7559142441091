import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as BuildingQueueActions from './building-queue.actions';
import {BuildingQueueSelector} from './building-queue.selector';
import {exhaustMap, map, switchMap, take, interval, tap, withLatestFrom, Subscription} from "rxjs";
import {BuildingService} from "../../services/http/building.service";
import {BuildingQueue} from "../../models/BuildingQueue";
import * as ResourceActions from "../resource/planet-resource.actions";
import {refreshOneBuilding} from "../buildings";
import * as PlanetActions from "../planet";

@Injectable()
export class BuildingQueueEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private buildingService: BuildingService,
  ) {
  }

  subscription: Subscription;

  tick() {
    this.subscription = interval(1000).pipe(
      switchMap(() => this.store.select(BuildingQueueSelector)),
      take(1),
      tap((currentQueue: BuildingQueue[]) => {
        if (!currentQueue || 0 === currentQueue.length) {
          return;
        }
        let currentItem = currentQueue[0];
        let startDate = new Date(currentItem.starting_date);
        let endDate = new Date(currentItem.ending_date);
        let startTime = startDate.getTime();
        let maxTime = (endDate.getTime() - startDate.getTime())
        let now = new Date();
        let elapsedTime = (now.getTime()) - startTime;
        let remainingTime = (maxTime - elapsedTime) / 1000;
        if (remainingTime <= 0) {
          setTimeout(() => {
            this.store.dispatch(BuildingQueueActions.refresh())
            this.store.dispatch(refreshOneBuilding({buildingId: currentItem.building}));
            this.store.dispatch(ResourceActions.refresh())
            this.store.dispatch(PlanetActions.refresh())
          }, 500)
        }
      }),
      tap(() => this.tick())
    ).subscribe();
  }

  updateAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BuildingQueueActions.UPDATE),
        withLatestFrom(this.store.select(BuildingQueueSelector)),
        // tap(([, universe]) => this.sentryService.setUser(universe)),
      ),
    {dispatch: false},
  );

  refreshAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BuildingQueueActions.REFRESH),
        exhaustMap(() => this.buildingService.getBuildingQueue().pipe(
          map(buildingQueue => {
            console.log("reloading building queue");
            return BuildingQueueActions.update({buildingQueue})
          })
        )),
        tap(() => {
          if (!this.subscription) {
            this.tick();
          }
        })
      ),
  );
}
