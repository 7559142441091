import {Component, Input} from '@angular/core';
import {Resource} from "../../../../models/Resource";

@Component({
  selector: 'app-display-current-next-resources',
  templateUrl: './display-current-next-resources.component.html',
  styleUrls: ['./display-current-next-resources.component.scss']
})
export class DisplayCurrentNextResourcesComponent {
  @Input() datas: Resource[];
  @Input() datasToSearch: Resource[];

  findResourceValueByName(name: string): number {
    let value = 0;
    this.datasToSearch.forEach((resource) => {
      if (resource.name == name) {
        value = resource.value;
      }
    })
    return value;
  }
}
