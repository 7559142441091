import {Component} from '@angular/core';
import {PlanetService} from "../../../../services/http/planet.service";
import {TranslateModule} from "@ngx-translate/core";
import {TableModule} from 'primeng/table';
import {ProductionInfo} from "../../../../models/ProductionInfo";
import {SharedModule} from "../../../../shared.module";
import {select, Store} from "@ngrx/store";
import {planetSelector} from "../../../../store/planet";
import {tap} from "rxjs/operators";
import {Planet} from "../../../../models/Planet";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ReloadAfterPlanetChanged, ReloadService} from "../../../../services/ReloadService";
import {ResourceType} from "../../../../models/Enum/ResourceType";

interface Column {
  field: string;
  header: string;
}

@UntilDestroy()
@Component({
  selector: 'app-production-info',
  standalone: true,
  imports: [
    TranslateModule,
    TableModule,
    SharedModule
  ],
  templateUrl: './production-info.component.html',
  styleUrl: './production-info.component.scss'
})
export class ProductionInfoComponent implements ReloadAfterPlanetChanged {

  data: any[] = [];

  cols: Column[] = [];
  planet: Planet;

  constructor(
    private readonly store: Store,
    private readonly planetService: PlanetService,
    private readonly reloadService: ReloadService,
  ) {
    this.store.pipe(
      select(planetSelector),
      tap(planet => {
        this.planet = planet;
      }),
      untilDestroyed(this),
    ).subscribe();
    this.loadProductionInfo();
  }

  readonly planetChangedSub = this.reloadService.planetChanged
    .pipe(
      tap(() => this.loadProductionInfo()),
      untilDestroyed(this),
    )
    .subscribe();

  loadProductionInfo() {
    this.data = [];
    this.cols = [];
    this.planetService.getPlanetProductionInfo().subscribe(productionInfo => {
      this.formatProductionInfo(productionInfo);
    });
  }

  formatProductionInfo(productionInfo: ProductionInfo) {
    this.data = [];
    let baseProduction = [];
    let buildingProduction = [];
    let totalProduction = [];
    let storage = [];
    this.cols = [
      {field: 'name', header: ''},
    ];
    productionInfo.resources.forEach(resource => {
      this.cols.push({field: resource.name, header: resource.name});
      buildingProduction[resource.name] = 0;
      totalProduction[resource.name] = resource.total;
      storage[resource.name] = resource.storage;
      baseProduction[resource.name] = resource.base_production;
    });
    this.data.push({
      name: 'productionInfo.table.base',
      ...baseProduction
    });

    productionInfo.buildings.forEach(building => {
      const row = {name: `building.list.${building.name}.name`, level: building.level};

      building.production.forEach(production => {
        row[production.name] = production.value;
        buildingProduction[production.name] += production.value;
      });
      building.consumption.forEach(consumption => {
        row[consumption.name] = -consumption.value;
        if (consumption.name !== ResourceType.ENERGY) {
          buildingProduction[consumption.name] -= consumption.value;
        }
      });
      this.data.push(row);
    });
    productionInfo.ships.forEach(ship => {
      const row = {name: `hangar.list.${ship.name}.name`, level: ship.count};

      ship.production.forEach(production => {
        row[production.name] = production.value;
        buildingProduction[production.name] += production.value;
      });
      this.data.push(row);
    });
    let bonusProduction = [];
    for (let key in totalProduction) {
      bonusProduction[key] = totalProduction[key] - buildingProduction[key] - baseProduction[key];
    }
    this.data.push({
      name: 'productionInfo.table.bonus',
      ...bonusProduction
    });
    this.data.push({
      name: 'productionInfo.table.storage',
      ...storage
    });
    this.data.push({
      name: 'productionInfo.table.total',
      ...totalProduction
    });
    let dailyProduction = [];
    let weeklyProduction = [];

    for (let key in totalProduction) {
      if (null !== baseProduction[key]) {
        dailyProduction[key] = totalProduction[key] * 24;
        weeklyProduction[key] = totalProduction[key] * 24 * 7;
      }
    }
    this.data.push({
      name: 'productionInfo.table.daily',
      ...dailyProduction
    });
    this.data.push({
      name: 'productionInfo.table.weekly',
      ...weeklyProduction
    });

  }

}
