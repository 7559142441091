// import {inject} from '@angular/core';
// import {ActivatedRoute, Router} from '@angular/router';
// import {map} from 'rxjs';
// import * as routes from "../routes";
// import {UniverseService} from "../services/universe.service";
// import {universeSelector, update as updateUniverse} from "../store/universe";
// import {select, Store} from "@ngrx/store";
// import {filter, tap} from "rxjs/operators";
// import {Universe} from "../models/Universe";
//
// let isUniverseDefined = false;
//
// export const UniverseGuard = () => {
//   const activatedRoute = inject(ActivatedRoute);
//   activatedRoute.paramMap.subscribe(
//     params => {
//       console.log(params)
//     }
//   );
//   const route = activatedRoute.snapshot;
//   const router = inject(Router);
//   const store = inject(Store);
//   store
//     .pipe(
//       select(universeSelector),
//       filter((universe: Universe) => !!universe),
//       tap(() => {
//         isUniverseDefined = true;
//       }),
//     )
//     .subscribe();
//
// console.log(route);
//   if (isUniverseDefined){
//     return true;
//   }
//   const universeId = route.params['universeId'];
//   console.log(route.params);
//   console.log('universeId =>' + universeId);
//
//   const universeService = inject(UniverseService);
//   return universeService.getUniverse(universeId).pipe(map((universe) => {
//       if (universe) {
//         store.dispatch(updateUniverse({universe}));
//         return true;
//       } else {
//         router.navigate([routes.ROUTE_UNIVERSES]);
//         return false;
//       }
//     }
//   ))
// }

import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {UniverseService} from "../services/http/universe.service";
import * as routes from "../routes";
import {universeSelector, update as updateUniverse} from "../store/universe";
import {select, Store} from "@ngrx/store";
import {filter, tap} from "rxjs/operators";
import {Universe} from "../models/Universe";
import {Observable} from "rxjs";
import {map} from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class UniverseGuard implements CanActivate {

 isUniverseDefined = false;

  constructor(
    private store: Store,
    private universeService: UniverseService,
    private router:Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean|Observable<boolean> {
    this.store
    .pipe(
      select(universeSelector),
      filter((universe: Universe) => !!universe),
      tap(() => {
        this.isUniverseDefined = true;
      }),
    )
    .subscribe();

  if (this.isUniverseDefined){
    return true;
  }
  const universeId = next.params['universeId'];
  console.log('universeId =>' + universeId);

  return this.universeService.getUniverse(universeId).pipe(map((universe) => {
      if (universe) {
        this.store.dispatch(updateUniverse({universe}));
        return true;
      } else {
        this.router.navigate([routes.ROUTE_UNIVERSES]);
        return false;
      }
    }
  ))
  }
}
