<div class="flex justify-content-center">
    @if (usersPoints.length > 0) {
      <p-table [value]="usersPoints" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header">
          <tr>
            <th>#</th>
            <th>Username</th>
            <th>Points</th>
            <th>Building</th>
            <th>Research</th>
            <th>Ship</th>
            <th>Defense</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-userPoint let-rowIndex="rowIndex">
          <tr>
            <td>{{rowIndex+1}}.</td>
            <td>{{ userPoint.username }}</td>
            <td>{{ userPoint.points.building + userPoint.points.research + userPoint.points.ship + userPoint.points.defense | numberDot }}</td>
            <td>{{ userPoint.points.building | numberDot }}</td>
            <td>{{ userPoint.points.research | numberDot }}</td>
            <td>{{ userPoint.points.ship | numberDot }}</td>
            <td>{{ userPoint.points.defense | numberDot }}</td>
          </tr>
        </ng-template>
      </p-table>
    } @else {
      loading
    }

</div>
