import {Component, Input} from '@angular/core';
import {SimulationReport} from "../../../../../models/SimulationReport";
import {SharedModule} from "../../../../../shared.module";
import {SimulationReportUserCardComponent} from "../simulation-report-user-card/simulation-report-user-card.component";
import {TabViewModule} from "primeng/tabview";

@Component({
  selector: 'app-simulation-report',
  standalone: true,
  imports: [
    SharedModule,
    SimulationReportUserCardComponent,
    TabViewModule
  ],
  templateUrl: './simulation-report.component.html',
  styleUrl: './simulation-report.component.scss'
})
export class SimulationReportComponent {
  @Input() data: SimulationReport;

}
