<p-inputNumber [(ngModel)]="x" [min]="-universe.max_system_x" [max]="universe.max_system_x"></p-inputNumber>
<p-inputNumber [(ngModel)]="y" [min]="-universe.max_system_y" [max]="universe.max_system_y"></p-inputNumber>
<p-button label="Search" (click)="getSystemData()"></p-button>

<p-dataView #dv [value]="dataToDisplay" layout="list">
  <ng-template let-planets pTemplate="listItem">
    @for (planet of planets; track planet.system_position) {
      <div class="col-12">
        <div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
          <div>{{planet.system_position}}</div>
          <div class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round">IMAGE</div>
          <!--        <img class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" [src]="'https://primefaces.org/cdn/primeng/images/demo/product/' + product.image" [alt]="product.name" />-->
          <div class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
            <div class="flex flex-column align-items-center sm:align-items-start gap-3">
              <div class="text-2xl font-bold text-900">{{ planet.name }}</div>
              <div class="flex align-items-center gap-3">
                {{planet.owner_name}}
                <!--                            <span class="flex align-items-center gap-2">-->
                <!--                                <i class="pi pi-tag"></i>-->
                <!--                                <span class="font-semibold">a</span>-->
                <!--                            </span>-->
              </div>
            </div>
            <div class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
              ACTIONS
            </div>
          </div>
        </div>
      </div>
    }
  </ng-template>
</p-dataView>
