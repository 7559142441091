import {Injectable} from '@angular/core';
import {BaseHttpService} from "./base-http.service";
import {Universe} from "../../models/Universe";
import {catchError} from "rxjs";
import {Planet} from "../../models/Planet";
import {HttpParams} from "@angular/common/http";
import {Records} from "../../models/Records";
import {Points} from "../../models/Points";
import {CombatReport} from "../../models/CombatReport";
import {SpyReport} from "../../models/SpyReport";

@Injectable({
  providedIn: 'root'
})
export class UniverseService extends BaseHttpService {
  getUniverses() {
    return this.http.get<Universe[]>('api/universes/list').pipe(
      catchError(this.handleError<Universe[]>('getUniverses', []))
    );
  }

  getUniverse(id) {
    return this.http.get<Universe>('api/universe/' + id).pipe(
      catchError(this.handleError<Universe>('getUniverse'))
    );
  }

  getOverviewMessage() {
    return this.http.get<string>('api/universe/{universeId}/overview').pipe(
      catchError(this.handleError<string>('getOverviewMessage', ''))
    );
  }

  getPlanetInSystem(x: number, y: number, planet: number) {
    return this.http.get<Planet[]>('api/universe/{universeId}/galaxy/list', {
      params: (new HttpParams()).set('x', x).set('y', y).set('planet', planet)
    }).pipe(
      catchError(this.handleError<Planet[]>('getPlanetInSystem', []))
    );
  }

  getUsersPoints() {
    return this.http.get<{ points: Points, name: string }[]>('api/universe/{universeId}/usersPoints',).pipe(
      catchError(this.handleError<{ points: Points, name: string }[]>('getUsersPoints', []))
    );
  }

  getRecords() {
    return this.http.get<Records>('api/universe/{universeId}/records',).pipe(
      catchError(this.handleError<Records>('getRecords'))
    );
  }

  getEmpire() {
    return this.http.get<any>('api/universe/{universeId}/empire',).pipe(
      catchError(this.handleError<any>('getEmpire'))
    );
  }

  getSpyReport(id: string) {
    return this.http.get<SpyReport>('api/universe/{universeId}/spyReports/' + id,).pipe(
      catchError(this.handleError<SpyReport>('getSpyReport'))
    );
  }
}
