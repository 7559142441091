<div>
  <p-card [subheader]="building.level === 0 ? '' : 'niveau '+building.level">
    <ng-template pTemplate="title">
      <span class="cursor-pointer" (mouseover)="descriptionPanel.show($event)"
            (mouseout)="descriptionPanel.hide()">{{building.name}}</span>
      <p-overlayPanel #descriptionPanel>
        <ng-template pTemplate="content">
          <div style="max-width: 25vw;">
            <span>{{building.description}}</span>
          </div>
        </ng-template>
      </p-overlayPanel>
    </ng-template>
    <!--    <ng-template pTemplate="header">-->
    <!--      <img alt="Card" src="https://primefaces.org/cdn/primeng/images/usercard.png" />-->
    <!--    </ng-template>-->
    <div>
      <div>
        Temps de construction du prochain niveau
        <ul>
          <li>{{building.build_time | secondToDHMS}}</li>
        </ul>
      </div>
      <div>
                <span class="cursor-pointer" [class]="{'colorRed': !isBuyable, 'colorGreen': isBuyable}"
                      (mouseover)="costPanel.show($event)" (mouseout)="costPanel.hide()">Coût pour construire le prochain niveau</span>
        <p-overlayPanel #costPanel>
          <ng-template pTemplate="content">
            <app-costs [costs]="building.price"></app-costs>
          </ng-template>
        </p-overlayPanel>
      </div>
      @if (building.energy_needed.length > 0) {
        <div>
          <span class="cursor-pointer"
                [class]="{'colorRed': !isNeedMet, 'colorGreen': isNeedMet}"
                (mouseover)="needPanel.show($event)" (mouseout)="needPanel.hide()">Resource requise pour construire le prochain niveau</span>
          <p-overlayPanel #needPanel>
            <ng-template pTemplate="content">
              <app-costs [costs]="building.energy_needed"></app-costs>
            </ng-template>
          </p-overlayPanel>
        </div>
      }
      @if (building.requirements.length > 0) {
        <div [class]="{'colorRed': !requirementsMet, 'colorGreen': requirementsMet}">
                <span class="cursor-pointer" (mouseover)="requirementPanel.show($event)"
                      (mouseout)="requirementPanel.hide()">Prérequis pour construire le bâtiment</span>
          <p-overlayPanel #requirementPanel>
            <ng-template pTemplate="content">
              <app-requirements #requirement [requirements]="building.requirements"></app-requirements>
            </ng-template>
          </p-overlayPanel>
        </div>
      }
      @if (building.production_next_level.length > 0) {
        <div>
          <span class="cursor-pointer" (mouseover)="prodPanel.show($event)" (mouseout)="prodPanel.hide()">Production au prochain niveau</span>
          <p-overlayPanel #prodPanel>
            <ng-template pTemplate="content">
              <app-display-current-next-resources [datas]="building.production_next_level"
                                                  [datasToSearch]="building.production"></app-display-current-next-resources>
            </ng-template>
          </p-overlayPanel>
        </div>
      }
      @if (building.storage_next_level.length > 0) {
        <div>
          <span class="cursor-pointer" (mouseover)="storagePanel.show($event)" (mouseout)="storagePanel.hide()">Stockage pour le prochain niveau</span>
          <p-overlayPanel #storagePanel>
            <ng-template pTemplate="content">
              <app-display-current-next-resources [datas]="building.storage_next_level"
                                                  [datasToSearch]="building.storage"></app-display-current-next-resources>
            </ng-template>
          </p-overlayPanel>
        </div>
      }
      @if (building.consumption_next_level.length > 0) {
        <div>
                <span class="cursor-pointer" (mouseover)="consumptionPanel.show($event)"
                      (mouseout)="consumptionPanel.hide()">Consommation du prochain niveau</span>
          <p-overlayPanel #consumptionPanel>
            <ng-template pTemplate="content">
              <app-consumption [datas]="building.consumption_next_level"
                               [datasToSearch]="building.consumption"></app-consumption>
            </ng-template>
          </p-overlayPanel>
        </div>
      }
    </div>
    @if (building.level > 0) {
      <div>
        Temps pour déconstruire le bâtiment
        <ul>
          <li>{{building.destroy_time | secondToDHMS}}</li>
        </ul>
      </div>
    }
    @if (building.level > 0) {
      <div>
                <span class="cursor-pointer" [class]="{'colorRed': !isBuyableDestroy, 'colorGreen': isBuyableDestroy}"
                      (mouseover)="costDestroyPanel.show($event)" (mouseout)="costDestroyPanel.hide()">Coût pour déconstruire le bâtiment</span>
        <p-overlayPanel #costDestroyPanel>
          <ng-template pTemplate="content">
            <app-costs [costs]="building.price_destroy"></app-costs>
          </ng-template>
        </p-overlayPanel>
      </div>
    }
    <ng-template pTemplate="footer">
      <p-button [disabled]="forceDisable" (onClick)="addBuildingToQueue()">
        @if (building.level === 0 && building.next_level === 1) {
          <fa-icon [icon]="faHammer"></fa-icon>
        } @else if (building.level > 0 || building.next_level > 1) {
          <fa-icon [icon]="faAnglesUp"></fa-icon>
        }
        <span
          [style]="{ 'margin-left': '.5em' }">{{building.level === 0 && building.next_level === 1 ? 'Construire le bâtiment' : 'Augmenter au niveau ' + building.next_level}}</span>
      </p-button>
      @if (building.level > 0) {
        <p-button [disabled]="!isBuyableDestroy || forceDisable"
                  (onClick)="showDestroyConfirmation($event)" styleClass="p-button-danger"
                  [style]="{ 'margin-left': '2.5em' }">
          @if (building.level === 1) {
            <fa-icon [icon]="faX"></fa-icon>
          } @else if (building.level > 1) {
            <fa-icon [icon]="faAnglesDown"></fa-icon>
          }
        </p-button>
      }
    </ng-template>
  </p-card>
</div>
