import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanetComponent } from './planet.component';
import { OverviewComponent } from './overview/overview.component';
import {TopNavComponent} from "./top-nav/top-nav.component";
import {PlanetRoutingModule} from "./planet-routing.module";
import {SharedModule} from "../../../shared.module";
import { BuildingsComponent } from './buildings/buildings.component';
import {SharedUniverseModule} from "../shared/shared-universe.module";
import {DockModule} from "primeng/dock";
import {CardModule} from "primeng/card";
import { HangarComponent } from './hangar/hangar.component';
import {LaboratoryComponent} from "./laboratory/laboratory.component";
import { GalaxyComponent } from './galaxy/galaxy.component';
import {DataViewModule} from "primeng/dataview";
import { MissionCenterComponent } from './mission-center/mission-center.component';
import {DropdownModule} from "primeng/dropdown";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {InputNumberModule} from 'primeng/inputnumber';
import {ButtonModule} from "primeng/button";
import { UsersPointsComponent } from './users-points/users-points.component';
import {OverlayPanelModule} from "primeng/overlaypanel";
import {TableModule} from "primeng/table";
import { MessagesComponent } from './messages/messages.component';
import {PanelModule} from "primeng/panel";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {InputTextModule} from "primeng/inputtext";
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {BadgeModule} from "primeng/badge";
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { RecordsComponent } from './records/records.component';
import {ScrollPanelModule} from "primeng/scrollpanel";
import { ScrapDealerComponent } from '../shared/scrap-dealer/scrap-dealer.component';
import {DialogModule} from "primeng/dialog";
import {SidenavComponent} from "../shared/sidenav/sidenav.component";
import {OrganizationChartModule} from "primeng/organizationchart";
import {TabViewModule} from "primeng/tabview";
import {DailyBonusDialogComponent} from "../shared/daily-bonus-dialog/daily-bonus-dialog.component";
import {TranslateModule} from "@ngx-translate/core";
import {SpyReportComponent} from "../shared/spy-report/spy-report.component";
import {BattleSimulatorComponent} from "../shared/battle-simulator/battle-simulator.component";



@NgModule({
  declarations: [
    PlanetComponent,
    OverviewComponent,
    TopNavComponent,
    BuildingsComponent,
    HangarComponent,
    LaboratoryComponent,
    GalaxyComponent,
    MissionCenterComponent,
    UsersPointsComponent,
    MessagesComponent,
    RecordsComponent,
    ScrapDealerComponent,
  ],
    imports: [
        CommonModule,
        PlanetRoutingModule,
        SharedModule,
        SharedUniverseModule,
        DockModule,
        CardModule,
        DataViewModule,
        DropdownModule,
        FormsModule,
        FontAwesomeModule,
        InputNumberModule,
        ButtonModule,
        OverlayPanelModule,
        TableModule,
        PanelModule,
        ConfirmDialogModule,
        InputTextModule,
        ConfirmPopupModule,
        BadgeModule,
        VirtualScrollerModule,
        ScrollPanelModule,
        ReactiveFormsModule,
        DialogModule,
        SidenavComponent,
        OrganizationChartModule,
        TabViewModule,
        DailyBonusDialogComponent,
        TranslateModule,
        SpyReportComponent,
        BattleSimulatorComponent,
    ]
})
export class PlanetModule { }
