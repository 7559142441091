export class CreateMarketOffer {
  type: string
  from_type: string
  from_id: number
  resources_selling: CreateMarketOfferResource[]
  resources_buying: CreateMarketOfferResource[]
}

class CreateMarketOfferResource {
  resource_id: number
  amount: number
}
