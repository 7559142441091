import {Building} from "../models/Building";
import {Research} from "../models/Research";
import {Requirement} from "../models/Requirement";
import {RequirementTree} from "../models/AbstractRequirement";

export class RequirementsUtils {
    static getModelLevel(buildings: Building[], researches: Research[], modelType: string, modelId: string | number) {
        switch (modelType) {
            case 'Building':
                let building = buildings?.find((element) => element.id == modelId);
                return building?.level;
            case 'Research':
                let research = researches?.find((element) => element.id == modelId);
                return research?.level;
            default:
                return -1;
        }
    }

    static isRequirementMet(buildings: Building[], researches: Research[], requirements: Requirement[]): boolean {
        if (!requirements) {
            return true;
        }
        let isMet = true;

        requirements.forEach((data) => {
            let level = this.getModelLevel(buildings, researches, data.type, data.id);
            if (level < data.level) {
                isMet = false;
            }
        })
        return isMet;
    }

  static extractTechTreeBranch(currentBranch: RequirementTree) {
    let data = {
      expanded: true,
      type: 'techTree',
      data: {
        name: currentBranch.name,
        level: currentBranch.current_level,
        level_needed: currentBranch.level_needed
      },
      children: []
    };

    currentBranch.branch.forEach((branch) => {
      data.children.push(this.extractTechTreeBranch(branch))
    });

    return data;
  }
}
