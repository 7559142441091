@if (showHeader && hangarQueues?.length > 0) {
  <div>
    <span translate>hangar.queue.header</span>
  </div>
}
@for (queue of hangarQueues; track queue.id; let isFirst = $first;) {
  <div>
    @if (isFirst) {
      <div>{{queue.name}} => {{queue.built_count}}/{{queue.count}} <span>=> {{remainingTime | secondToDHMS}}</span>=>  <button (click)="removeQueueItem(queue, isFirst)">Remove</button></div>
      <div>
        <p-progressBar [value]="percentCurrentItem"></p-progressBar>
      </div>
    } @else {
      <div>{{queue.name}} => {{queue.built_count}}/{{queue.count}} =>  <button (click)="removeQueueItem(queue, isFirst)">Remove</button></div>
    }
  </div>

}
