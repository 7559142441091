<div style="max-width: 90vw">
  <ul style="list-style: none">
    <li>{{ extraData?.username }}</li>
    <li>{{ extraData?.points | numberDot }}</li>
    <li>Armes: {{ (1 + extraData?.bonus.attack.percentage) * 100 | numberDot }}% -
      Blindage: {{ (1 + extraData?.bonus.defense.percentage) * 100 | numberDot }}% -
      Boucliers: {{ (1 + extraData?.bonus.shield.percentage) * 100 | numberDot }}%
    </li>
  </ul>
  <div class="flex align-content-center justify-content-center overflow-y-auto" style="max-width: 90vw">
    @for (unit of getDefense(); track unit.id) {
      <ng-container *tldLet="getEndDefenseById(unit.id) as endUnit">
        <ng-container *tldLet="getRebuildCount(unit.id) as rebuildCount">
          <div>
            <ul style="list-style: none">
              <li>{{ unit.name }}</li>
              @if (!isRecap) {
                <li>Nombre: {{ unit.count }}
                  @if (!endUnit) {
                    <span class="colorRed">(-{{ unit.count }})</span>
                  } @else if (unit.count !== endUnit.count) {
                    <span class="colorRed">(-{{ unit.count - endUnit.count }})</span>
                  }
                </li>
                <li>Armes: {{ unit.weaponPower * unit.count }}</li>
                <li>Blindage: {{ unit.hull * unit.count }}</li>
                <li>Boucliers: {{ unit.shieldPower * unit.count }}</li>
              } @else {
                <li>Nombre début: {{ unit.count }}</li>
                <li>Nombre détruit:  @if (!endUnit) {
                  <span class="colorRed">-{{ unit.count }}</span>
                } @else if (unit.count !== endUnit.count) {
                  <span class="colorRed">-{{ unit.count - endUnit.count }}</span>
                } @else {
                  0
                }</li>
                <li>Reconstruit: <span
                  [class]="{colorGreen: rebuildCount > 0}">{{ rebuildCount > 0 ? '+' + rebuildCount : 0 }}</span></li>
                <li>Nombre fin:
                  @if (!endUnit) {
                    {{ rebuildCount }}
                  } @else if (unit.count !== endUnit.count) {
                    {{ endUnit.count + rebuildCount }}
                  } @else {
                    {{ unit.count + rebuildCount }}
                  }
                </li>
              }
            </ul>
          </div>
        </ng-container>
      </ng-container>
    }
    @for (unit of getShip(); track unit.id) {
      <ng-container *tldLet="getEndShipById(unit.id) as endUnit">
        <div>
          <ul style="list-style: none">
            <li>{{ unit.name }}</li>
            @if (!isRecap) {
              <li>Nombre: {{ unit.count }}
                @if (!endUnit) {
                  <span class="colorRed">(-{{ unit.count }})</span>
                } @else if (unit.count !== endUnit.count) {
                  <span class="colorRed">(-{{ unit.count - endUnit.count }})</span>
                }
              </li>
              <li>Armes: {{ unit.weaponPower * unit.count }}</li>
              <li>Blindage: {{ unit.hull * unit.count }}</li>
              <li>Boucliers: {{ unit.shieldPower * unit.count }}</li>
            } @else {
              <li>Nombre début: {{ unit.count }}</li>
              <li>Nombre détruit:  @if (!endUnit) {
                <span class="colorRed">-{{ unit.count }}</span>
              } @else if (unit.count !== endUnit.count) {
                <span class="colorRed">-{{ unit.count - endUnit.count }}</span>
              } @else {
                0
              }</li>
              <li>Nombre fin:
                @if (!endUnit) {
                  0
                } @else if (unit.count !== endUnit.count) {
                  {{ endUnit.count }}
                } @else {
                  {{ unit.count }}
                }
              </li>
            }
          </ul>
        </div>
      </ng-container>
    }
  </div>
</div>
