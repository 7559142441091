<p-card [subheader]="'Disponible: ' + ship.count">
  <ng-template pTemplate="title">
    <span class="cursor-pointer" (click)="showDialog()">{{ship.name}}
      <fa-icon style="font-size: 1rem" [icon]="faCircleInfo"/></span>
    <p-dialog [header]="ship.name" [(visible)]="visibleDialog"  (onHide)="onHide()" (onShow)="onShow()">
      <p-tabView [(activeIndex)]="activeIndex">
        <p-tabPanel header="Infos tech" >
          <div>
            <span>{{ship.description}}</span>
          </div>
          <div class="w-full">
            <p-table [value]="infoTech">
              <ng-template pTemplate="header">
                <tr>
                  <th colspan="2" class="text-center">Données techniques</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-data>
                <tr>
                  <td class="text-center">{{ data.desc }}</td>
                  @if (data.value !== 0 && (data.value - data.base) !== 0) {
                    <td class="text-center">
                      <div>{{ data.value | numberDot }}</div>
                      <div>
                      </div>
                      (
                      {{data.base| numberDot}} + <span class="colorGreen">{{(data.value - data.base)| numberDot}}</span>
                      )
                    </td>
                  } @else {
                    <td class="text-center">{{ data.value | numberDot }}</td>
                  }
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Tech tree">
          <p-organizationChart [value]="techTree">
            <ng-template let-node pTemplate="techTree">
              <div class="p-2 text-center">
                <div class="font-bold">{{ node.data.name }}</div>
                @if (node.data.level_needed > 0) {
                  <div [class]="{'colorRed': node.data.level<node.data.level_needed, 'colorGreen': node.data.level==node.data.level_needed}">
                    {{ node.data.level }} / {{ node.data.level_needed }}
                  </div>
                }
              </div>
            </ng-template>
          </p-organizationChart>
        </p-tabPanel>
        <p-tabPanel header="Rapid Fire">
          @for (data of rapidFire; track data.name) {
            <div>
              <span>{{data.name}}</span> => <span class="colorGreen">{{data.value | numberDot}}</span> ({{(data.value -1) / data.value * 100 | number: '1.0-2'}} %)
            </div>
          }
        </p-tabPanel>
      </p-tabView>
    </p-dialog>
  </ng-template>
  <!--    <ng-template pTemplate="header">-->
  <!--      <img alt="Card" src="https://primefaces.org/cdn/primeng/images/usercard.png" />-->
  <!--    </ng-template>-->
  <div>
    <div>
      Temps de construction pour {{count}}
      <ul>
        <li>{{ship.build_time * count | secondToDHMS}}</li>
      </ul>
    </div>
    <div>
      Coût pour construire {{count}}
      <app-costs [costs]="ship.price" [count]="count"></app-costs>
    </div>
    @if (ship.requirements.length > 0) {
      <div [class]="{'colorRed': !requirementsMet, 'colorGreen': requirementsMet}">
        <span class="cursor-pointer" (mouseover)="requirementPanel.show($event)" (mouseout)="requirementPanel.hide()">Prérequis pour construire</span>
        <p-overlayPanel #requirementPanel>
          <ng-template pTemplate="content">
            <app-requirements [requirements]="ship.requirements"></app-requirements>
          </ng-template>
        </p-overlayPanel>
      </div>
    }
    @if (ship.production.length > 0) {
      <div>
        Production
        <ul>
          @for (data of ship.production; track data.name) {
            <li> {{data.name}} <!--=> {{data.value | numberDot}}--></li>
          }
        </ul>
      </div>
    }
  </div>
  <ng-template pTemplate="footer">
    <div>
      <p-inputNumber [(ngModel)]="count" [min]="1"></p-inputNumber>
    </div>
    <div>
      <p-button [disabled]="!isBuyable || !requirementsMet || count < 1 || null === count || forceDisable"
                (onClick)="addShipToQueue()" [label]="('common.build' | translate) + ' x' + count"></p-button>
    </div>
  </ng-template>
</p-card>
