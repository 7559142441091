@if (combatReport) {
  <div class="text-center">
    Les flottes suivantes s'opposent : {{ combatReport.created_at }}:
    <p-tabView styleClass="center-p-nav-header">
      @if (combatReport.rounds.length > 0) {
        <p-tabPanel [header]="'Recap'">
          <div>
            @for (attacker of combatReport.rounds[0].initialData.attackers; track attackerIndex; let attackerIndex = $index) {
              <div>
                <app-combat-report-user-card [isRecap]="true" [data]="attacker"
                                             [endData]="getEndData(combatReport.rounds[combatReport.rounds.length-1].endData.attackers, attacker.userId)"
                                             [extraData]="getUserExtraData(attacker.userId)"/>
              </div>
            }
            vs
            @for (defender of combatReport.rounds[0].initialData.defenders; track defenderIndex; let defenderIndex = $index) {
              <div>
                <app-combat-report-user-card [isRecap]="true" [data]="defender"
                                             [endData]="getEndData(combatReport.rounds[combatReport.rounds.length-1].endData.defenders, defender.userId)"
                                             [extraData]="getUserExtraData(defender.userId)"
                                             [defenceGiveBack]="combatReport.extra_data.defenceGiveBack"
                />
              </div>
            }
          </div>
        </p-tabPanel>
      }
      @for (round of combatReport.rounds; track index; let index = $index) {
        <p-tabPanel [header]="'Round #' + (index + 1)">
          <div>
            @for (attacker of round.initialData.attackers; track attackerIndex; let attackerIndex = $index) {
              <div>
                <app-combat-report-user-card [data]="attacker"
                                             [endData]="getEndData(round.endData.attackers, attacker.userId)"
                                             [extraData]="getUserExtraData(attacker.userId)"/>
              </div>
            }
            vs
            @for (defender of round.initialData.defenders; track defenderIndex; let defenderIndex = $index) {
              <div>
                <app-combat-report-user-card [data]="defender"
                                             [endData]="getEndData(round.endData.defenders, defender.userId)"
                                             [extraData]="getUserExtraData(defender.userId)"/>
              </div>
            }
          </div>
          <div>
            <div>La flotte attaquante tire avec une force de {{ round.shotData.attackers.damage | numberDot }} sur le
              defenseur. Les boucliers du defenseur absorbent {{ round.shotData.attackers.shieldAbsorbed | numberDot }}
              Points de degats
            </div>
            <div>La flotte du defenseur tire avec une force de {{ round.shotData.defenders.damage | numberDot }} sur
              l'attaquant. Les boucliers du de l'attaquant
              absorbent {{ round.shotData.defenders.shieldAbsorbed | numberDot }} Points de degats
            </div>
          </div>
        </p-tabPanel>
      }
    </p-tabView>
    <div>
      @switch (combatReport.attack_result) {
        @case ('win') {
          <span>L'attaquant a gagne la bataille !</span>
        }
        @case ('lose') {
          <span>Le defenseur a gagne la bataille !</span>
        }
        @default {
          <span>Le combat se termine par un match nul !</span>
        }
      }
    </div>
    <p-tabView styleClass="center-p-nav-header">
      @if (combatReport.extra_data?.resourceStolen.length > 0) {
        <p-tabPanel [header]="'Resources volées'">
          <div>
            <span>Les ressources suivantes ont été volées :</span>
            <div>
              @for (resource of combatReport.extra_data.resourceStolen; track resourceIndex; let resourceIndex = $index) {
                <div>
                  <span>{{ resource.amount | numberDot }} {{ resource.name }}</span>
                </div>
              }
            </div>
          </div>
        </p-tabPanel>
      }
      @if (combatReport.extra_data?.debrisField.length > 0) {
        <p-tabPanel [header]="'Champs de dérbis'">
          <div>
            <span>Ressources dans le champ de débris :</span>
            <div>
              @for (resource of combatReport.extra_data.debrisField; track resourceIndex; let resourceIndex = $index) {
                <div>
                  <span>{{ resource.amount | numberDot }} {{ resource.name }}</span>
                </div>
              }
            </div>
          </div>
        </p-tabPanel>
      }
    </p-tabView>

  </div>
} @else {
  loading...
}
