import {Injectable} from '@angular/core';
import {BaseHttpService} from "./base-http.service";
import {Universe} from "../../models/Universe";
import {catchError} from "rxjs";
import {Planet} from "../../models/Planet";
import {HttpParams} from "@angular/common/http";
import {Records} from "../../models/Records";
import {Points} from "../../models/Points";
import {CombatReport} from "../../models/CombatReport";
import {SimulationReport} from "../../models/SimulationReport";

@Injectable({
  providedIn: 'root'
})
export class BattleService extends BaseHttpService {
  getCombatReport(id: string) {
    return this.http.get<CombatReport>('api/universe/{universeId}/combatReports/' + id,).pipe(
      catchError(this.handleError<CombatReport>('getCombatReport'))
    );
  }

  postSimulateBattle(data: any) {
    return this.http.post<SimulationReport>('api/universe/{universeId}/simulateBattle', data).pipe(
      catchError(this.handleError<SimulationReport>('postSimulateBattle'))
    );
  }
}
