import {Component, Input} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {ShipsSelector} from "../../../../store/ships";
import {filter, tap} from "rxjs/operators";
import {Ship} from "../../../../models/Ship";
import {DefensesSelector} from "../../../../store/defenses";
import {Defense} from "../../../../models/Defense";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {InputNumberModule} from "primeng/inputnumber";
import {FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {cloneDeep} from "lodash-es";
import {take} from "rxjs";
import {SharedModule} from "../../../../shared.module";
import {ButtonModule} from "primeng/button";
import {BattleService} from "../../../../services/http/battle.service";
import {DialogModule} from "primeng/dialog";
import {SharedUniverseModule} from "../shared-universe.module";
import {SimulationReportComponent} from "../simulation/simulation-report/simulation-report.component";
import {SimulationReport} from "../../../../models/SimulationReport";
import {TranslateModule} from "@ngx-translate/core";
import {SpyData, SpyReport} from "../../../../models/SpyReport";
import {ResearchesSelector} from "../../../../store/researches";
import {Research} from "../../../../models/Research";

@UntilDestroy()
@Component({
  selector: 'app-battle-simulator',
  standalone: true,
  imports: [
    InputNumberModule,
    ReactiveFormsModule,
    SharedModule,
    ButtonModule,
    DialogModule,
    SharedUniverseModule,
    SimulationReportComponent,
    TranslateModule
  ],
  templateUrl: './battle-simulator.component.html',
  styleUrl: './battle-simulator.component.scss'
})
export class BattleSimulatorComponent {

  shipList: Ship[];
  defenseList: Defense[];
  researches: Research[];
  battleForm = this.fb.group({
    attacker: this.fb.group({
      bonus: this.fb.group({
        attack: this.fb.control(0),
        shield: this.fb.control(0),
        hull: this.fb.control(0),
      }),
      ships: this.fb.array<FormGroup>([])
    }),
    defender: this.fb.group({
      bonus: this.fb.group({
        attack: this.fb.control(0),
        shield: this.fb.control(0),
        hull: this.fb.control(0),
      }),
      ships: this.fb.array<FormGroup>([]),
      defenses: this.fb.array<FormGroup>([])
    }),
  });
  showSimulationReportDialog: boolean;
  simulationReport: SimulationReport;

  constructor(
    private readonly store: Store,
    private readonly fb: FormBuilder,
    private readonly battleService: BattleService,
  ) {
    this.store.pipe(
      select(ShipsSelector),
      filter((ships) => !!ships && ships.length > 0),
      take(1),
      tap((ships) => {
        this.shipList = cloneDeep<Ship[]>(ships);
        this.initShipList();
      }),
      untilDestroyed(this),
    ).subscribe();
    this.store.pipe(
      select(DefensesSelector),
      filter((defenses) => !!defenses.length && defenses.length > 0),
      take(1),
      tap((defenses) => {
        this.defenseList = cloneDeep<Defense[]>(defenses);
        this.initDefenseList();
      }),
      untilDestroyed(this),
    )
      .subscribe();
    this.store.pipe(
      select(ResearchesSelector),
      filter((researches) => !!researches && researches.length > 0),
      tap((researches) => {
        this.researches = cloneDeep<Research[]>(researches);
      }),
    ).subscribe();
  }

  get attackerShip() {
    return this.battleForm.controls.attacker.controls.ships as FormArray<FormGroup<{
      ship: FormControl,
      count: FormControl
    }>>;
  }

  get defenderShip() {
    return this.battleForm.controls.defender.controls.ships as FormArray<FormGroup<{
      ship: FormControl,
      count: FormControl
    }>>;
  }

  get defenderDefense() {
    return this.battleForm.controls.defender.controls.defenses as FormArray<FormGroup<{
      defense: FormControl,
      count: FormControl
    }>>;
  }

  getShipFromId(id: number) {
    return this.shipList.find((ship) => ship.id === id);
  }

  getDefenseFromId(id: number) {
    return this.defenseList.find((defense) => defense.id === id);
  }

  fillWithMyData(){
    this.battleForm.controls.attacker.controls.bonus.controls.attack.setValue(this.researches.find((r) => r.slug.includes('military_tech'))?.level || 0);
    this.battleForm.controls.attacker.controls.bonus.controls.shield.setValue(this.researches.find((r) => r.slug.includes('shield_tech'))?.level || 0);
    this.battleForm.controls.attacker.controls.bonus.controls.hull.setValue(this.researches.find((r) => r.slug.includes('defence_tech'))?.level || 0);
    this.battleForm.controls.attacker.controls.ships.controls.forEach((control, index) => {
      control.controls['count'].setValue(this.shipList.find((ship) => ship.id === control.controls['ship'].value)?.count ||0);
    });
  }

  public fillWithSpyData(spyData: SpyData){
    this.battleForm.controls.defender.controls.bonus.controls.attack.setValue(spyData.tech.find((t) => t.name.includes('military_tech'))?.level || 0);
    this.battleForm.controls.defender.controls.bonus.controls.shield.setValue(spyData.tech.find((t) => t.name.includes('shield_tech'))?.level || 0);
    this.battleForm.controls.defender.controls.bonus.controls.hull.setValue(spyData.tech.find((t) => t.name.includes('defence_tech'))?.level || 0);
    this.battleForm.controls.defender.controls.ships.controls.forEach((control, index) => {
      control.controls['count'].setValue(spyData.ships.find((ship) => ship.id === control.controls['ship'].value)?.count ||0);
    });
    this.battleForm.controls.defender.controls.defenses.controls.forEach((control, index) => {
      control.controls['count'].setValue(spyData.defenses.find((defense) => defense.id === control.controls['defense'].value)?.count ||0);
    });
  }

  initShipList() {
    this.shipList.forEach((ship) => {
      this.battleForm.controls.attacker.controls.ships.push(this.fb.group({
        ship: [ship.id],
        count: [0]
      }));
      this.battleForm.controls.defender.controls.ships.push(this.fb.group({
        ship: [ship.id],
        count: [0]
      }));
    });
  }

  initDefenseList() {
    this.defenseList.forEach((defense) => {
      this.battleForm.controls.defender.controls.defenses.push(this.fb.group({
        defense: [defense.id],
        count: [0]
      }));
    });
  }

  get attackerUnitCount() {
    return this.attackerShip.value.reduce((acc, ship) => acc + ship.count, 0);
  }
  get defenderUnitCount() {
    return this.defenderShip.value.reduce((acc, ship) => acc + ship.count, 0) + this.defenderDefense.value.reduce((acc, defense) => acc + defense.count, 0);
  }

  prepareRequest() {
    const attackerShips = this.attackerShip.value.filter((ship) => ship.count > 0);
    const defenderShips = this.defenderShip.value.filter((ship) => ship.count > 0);
    const defenderDefenses = this.defenderDefense.value.filter((defense) => defense.count > 0);

    return {
      attackers: [{
        bonus: {
          attack: this.battleForm.controls.attacker.controls.bonus.controls.attack.value,
          shield: this.battleForm.controls.attacker.controls.bonus.controls.shield.value,
          hull: this.battleForm.controls.attacker.controls.bonus.controls.hull.value,
        },
        units: attackerShips.map((ship) => {
          return {
            id: ship.ship,
            type: 'ship',
            count: ship.count
          }
        })
      }],
      defenders: [{
        bonus: {
          attack: this.battleForm.controls.defender.controls.bonus.controls.attack.value,
          shield: this.battleForm.controls.defender.controls.bonus.controls.shield.value,
          hull: this.battleForm.controls.defender.controls.bonus.controls.hull.value,
        },
        units: defenderShips.map((ship) => {
          return {
            id: ship.ship,
            type: 'ship',
            count: ship.count
          }
        }).concat(defenderDefenses.map((defense) => {
          return {
            id: defense.defense,
            type: 'defense',
            count: defense.count
          }
        })),
      }]
    };
  }

  simulateBattle() {
    this.showSimulationReportDialog = true;
    this.battleService.postSimulateBattle(this.prepareRequest()).subscribe((data) => {
      this.simulationReport = data;
    });
  }
}
