@if(records) {
  <div class="pt-5 pl-8 pr-8 flex flex-wrap justify-content-between">
    <div class="flex-grow-1 pl-8 pr-8 pb-5">
      @if (records?.buildings) {
        <p-table [value]="Object.values(records.buildings)">
          <ng-template pTemplate="header">
            <tr>
              <th>Bâtiment</th>
              <th>Niveau</th>
              <th>Joueur(s)</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-data>
            <ng-container *tldLet="(data | cast : Record) as record">
              <tr>
                <td>{{ record.name }}</td>
                <td>{{ record.level }}</td>
                <td>
                  <ul>
                    @for (user of record.users; track user) {
                      <li>{{ user }}</li>
                    }
                  </ul>
                </td>
              </tr>
            </ng-container>
          </ng-template>
        </p-table>
      }
    </div>
    <div class="flex-grow-1 pl-8 pr-8 pb-5">
      @if (records?.researches) {
        <p-table [value]="Object.values(records.researches)">
          <ng-template pTemplate="header">
            <tr>
              <th>Recherche</th>
              <th>Niveau</th>
              <th>Joueur(s)</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-data>
            <ng-container *tldLet="(data | cast : Record) as record">
              <tr>
                <td>{{ record.name }}</td>
                <td>{{ record.level }}</td>
                <td>
                  <ul>
                    @for (user of record.users; track user) {
                      <li>{{ user }}</li>
                    }
                  </ul>
                </td>
              </tr>
            </ng-container>
          </ng-template>
        </p-table>
      }
    </div>
  </div>
} @else {
  <div class="text-center pt-2">
    <span>loading</span>
  </div>
}
