<p-button (onClick)="showCreateOffer = true" [label]="'market.create_offer' | translate"/>
<p-table [value]="marketOffers" [tableStyle]="{'min-width': '60rem'}">
  <ng-template pTemplate="caption">
    <div class="flex align-items-center justify-content-between">
      <div>
        <span translate>market.header</span>
        <p-button (onClick)="showGlobalOffers = true; showOwnOffers = false; refreshList();"
                  [outlined]="!showGlobalOffers"
                  [text]="!showGlobalOffers" [label]="'market.table.show_global_offer' | translate"/>
        <p-button (onClick)="showGlobalOffers = false; showOwnOffers = true; refreshList();" [outlined]="!showOwnOffers"
                  [text]="!showOwnOffers" [label]="'market.table.show_my_offer' | translate"/>
      </div>
      <p-button icon="pi pi-refresh" (onClick)="refreshList()"/>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th><span translate>market.table.selling</span></th>
      <th><span translate>market.table.buying</span></th>
      <th><span translate>market.table.expiration</span></th>
      <th><span translate>market.table.action</span></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-offer>
    <tr>
      <td>
        <ul>
          @for (resource of offer.resources_selling; track resource.id) {
            <li>{{ resource.pivot.amount }} {{ resource.slug }}</li>
          }
        </ul>
      </td>
      <td>
        <ul>
          @for (resource of offer.resources_buying; track resource.id) {
            <li>{{ resource.pivot.amount }} {{ resource.slug }}</li>
          }
        </ul>
      </td>
      <td>
        {{ getRemainingTime(offer.expires_at) | secondToDHMS }}
      </td>
      <td>
        @if (offer.universe_account_id === universeAccountId) {
          <p-button severity="danger" icon="pi pi-trash" (onClick)="deleteOffer(offer.id)"/>
        } @else {
          <p-button icon="pi pi-shopping-cart" (onClick)="buyOffer(offer.id)"
                    [disabled]="canBuy(offer.resources_buying)"/>
        }
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="4" class="text-center">
        @if (isLoading) {
          <span translate>common.loading</span>
        } @else {
          <span translate>market.empty</span>
        }
      </td>
    </tr>
  </ng-template>
</p-table>
<div class="text-center">
  <p-button [disabled]="!hasPrevPage || isLoading" (onClick)="refreshList(currentPage-1)"><</p-button>
  <p-button [disabled]="!hasNextPage || isLoading" (onClick)="refreshList(currentPage+1)">></p-button>
</div>
<p-dialog [header]="'market.dialog.create' | translate" [(visible)]="showCreateOffer" [modal]="true" [closable]="true" appendTo="body">
  <div class="h-16rem">
    <form [formGroup]="marketOfferForm">
      <span translate>market.table.selling</span>
      <div formArrayName="resources_selling">
        <p-dropdown [options]="resourceList" optionLabel="slug"
                    [formControl]="marketOfferForm.controls.resources_selling.controls.resource"></p-dropdown>
        <p-inputNumber [min]="1" [formControl]="marketOfferForm.controls.resources_selling.controls.amount"
                       [max]="getMaxValue(marketOfferForm.controls.resources_selling.controls.resource.value?.id)"
                       mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0"></p-inputNumber>
      </div>
      <span translate>market.table.buying</span>
      <div formArrayName="resources_buying">
        <p-dropdown [options]="resourceList" optionLabel="slug"
                    [formControl]="marketOfferForm.controls.resources_buying.controls.resource"></p-dropdown>
        <p-inputNumber [min]="1"
                       [formControl]="marketOfferForm.controls.resources_buying.controls.amount" mode="decimal"
                       [minFractionDigits]="0" [maxFractionDigits]="0"></p-inputNumber>
      </div>
    </form>
    <p-button [disabled]="forceDisable" (onClick)="createOffer()" [label]="'market.dialog.create' | translate"/>
  </div>
</p-dialog>
