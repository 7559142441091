<ul>
  @for (data of costs; track data.name) {
    <li>
      <ng-container *tldLet="(null !== count ? data.value * count : data.value) as value">
        {{data.name}}: {{value | numberDot}}
        <ng-container *tldLet="getLeftToPayByName(data.name, value) as leftToPay">
          @if (leftToPay) {
            <span class="colorRed">({{ leftToPay | numberDot}})</span>
          }
        </ng-container>
      </ng-container>
    </li>
  }
</ul>
