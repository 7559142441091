<div style="max-width: 90vw">
  <ul style="list-style: none">
    <li>{{ data.userId}}</li>
<!--    <li>les points</li>-->
<!--    <li>Armes: {{(1 + data.bonus.attack.percentage) * 100 }}% - Blindage: {{(1 + data.bonus.defense.percentage) * 100 }}% - Boucliers: {{(1 + data.bonus.shield.percentage) * 100 }}%</li>-->
  </ul>
  <div class="flex align-content-center justify-content-center overflow-y-auto" style="max-width: 90vw">
    @for (unit of getShip(); track unit.id) {
      <div>
        <ul style="list-style: none">
          <ng-container *tldLet="unit.ship as ship">
            <li>{{ship.name}}</li>
            <li>Nombre: {{unit.count}}</li>
            <li>Armes: {{unit.attack * unit.count}}</li>
            <li>Blindage: {{unit.hull * unit.count}}</li>
            <li>Boucliers: {{unit.shield * unit.count}}</li>
          </ng-container>
        </ul>
      </div>
    }
    @for (unit of getDefense(); track unit.id) {
      <div>
        <ul style="list-style: none">
          <ng-container *tldLet="unit.defense as defense">
            <li>{{defense.name}}</li>
            <li>Nombre: {{unit.count}}</li>
            <li>Armes: {{unit.attack * unit.count}}</li>
            <li>Blindage: {{unit.hull * unit.count}}</li>
            <li>Boucliers: {{unit.shield * unit.count}}</li>
          </ng-container>
        </ul>
      </div>
    }
  </div>
</div>
