import {Component} from '@angular/core';
import {UniverseService} from "../../../../services/http/universe.service";
import {ResourceType} from "../../../../models/Enum/ResourceType";

@Component({
  selector: 'app-empire',
  templateUrl: './empire.component.html',
  styleUrls: ['./empire.component.scss']
})
export class EmpireComponent {

  columns: string[] = [];
  values: any[] = [];

  constructor(
    private readonly universeService: UniverseService
  ) {
    universeService.getEmpire().subscribe((data) => {
      this.formatTable(data);
    })
  }

  formatTable(data) {
    this.columns = ['Name'];
    this.values = [
      ['Position'],
      ['Field'],
    ];

    data.forEach((value, index) => {
      this.columns.push(value.name);
      let valueIndex = 0;
      this.values[valueIndex++].push(value.position);
      this.values[valueIndex++].push(value.used_field + '/' + value.max_field);
      value.resources.forEach((resource: any) => {
        if (resource.type === ResourceType.ENERGY){
          return;
        }
        if (typeof this.values[valueIndex] === 'undefined'){
          this.values[valueIndex] = [];
        }
        if (0 === index) {
          this.values[valueIndex].push(resource.slug + ' (current / prod)');
          this.values[valueIndex++].push(resource.pivot.value + ' / ' + resource.pivot.current_production + '/h');
        } else {
          this.values[valueIndex++].push(resource.pivot.value + ' / ' + resource.pivot.current_production + '/h');
        }
      })
      value.buildings.forEach((building: any) => {
        if (typeof this.values[valueIndex] === 'undefined'){
          this.values[valueIndex] = [];
        }
        if (0 === index) {
          this.values[valueIndex].push(building.name);
          this.values[valueIndex++].push('-');
        } else {
          this.values[valueIndex++].push(building.level);
        }
      })
    })
  }
}
