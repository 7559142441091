import {Component, Input} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {Ship} from "../../../../models/Ship";
import {Defense} from "../../../../models/Defense";
import {ShipsSelector} from "../../../../store/ships";
import {tap} from "rxjs/operators";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {DefensesSelector} from "../../../../store/defenses";
import {CombatPlayer} from "../../../../models/CombatRound";

@UntilDestroy()
@Component({
  selector: 'app-combat-report-user-card',
  templateUrl: './combat-report-user-card.component.html',
  styleUrls: ['./combat-report-user-card.component.scss']
})
export class CombatReportUserCardComponent {
  @Input() data: CombatPlayer;
  @Input() endData: CombatPlayer;
  @Input() extraData: any;
  @Input() isRecap: boolean = false;
  @Input() defenceGiveBack: any[] | undefined;

  shipList: Ship[] = [];
  defenseList: Defense[] = [];

  constructor(
    private readonly store: Store
  ) {
    store
      .pipe(
        select(ShipsSelector),
        tap((shipList) => {
          this.shipList = shipList;
        }),
        untilDestroyed(this),
      )
      .subscribe();
    store
      .pipe(
        select(DefensesSelector),
        tap((defenseList) => {
          this.defenseList = defenseList;
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  getShip() {
    return this.data.units.filter((unit) => unit.type === 'ship').sort((a, b) => a.id - b.id).map((unit) => {
      return {
        ...unit,
        name: this.getShipName(unit.id)
      }
    })
  }

  getEndShipById(id) {
    return this.endData.units.filter((unit) => unit.type === 'ship').find((unit) => unit.id === id)
  }

  getDefense() {
    return this.data.units.filter((unit) => unit.type === 'defense').sort((a, b) => a.id - b.id).map((unit) => {
      return {
        ...unit,
        name: this.getDefenseName(unit.id)
      }
    })
  }

  getRebuildCount(id) {
    return this.defenceGiveBack?.find((unit) => unit.id == id)?.amount ?? 0;
  }

  getEndDefenseById(id) {
    return this.endData.units.filter((unit) => unit.type === 'defense').find((unit) => unit.id === id)
  }

  getShipName(id) {
    return this.shipList.find((ship) => ship.id === id)?.name ?? id;
  }

  getDefenseName(id) {
    return this.defenseList.find((defense) => defense.id === id)?.name ?? id;
  }
}
