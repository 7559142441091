import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { TLDLetDirectiveContext } from './tld-let-context';

@Directive({
    selector: '[tldLet]',
})
export class TLDLetDirective<T> {
  @Input() tldLet!: T;

  constructor(
    viewContainer: ViewContainerRef,
    templateRef: TemplateRef<TLDLetDirectiveContext<T>>,
  ) {
    viewContainer.createEmbeddedView(
      templateRef,
      new TLDLetDirectiveContext<T>(this),
    );
  }
  static ngTemplateContextGuard<T>(
    _directive: TLDLetDirective<T>,
    _context: any,
  ): _context is TLDLetDirective<T> {
    return true;
  }
}
