import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Building} from "../../../../models/Building";
import {PlanetResource} from "../../../../models/PlanetResource";
import {BuildingService} from "../../../../services/http/building.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {refresh as refreshResources, planetResourceSelector} from "../../../../store/resource";
import {BuildingsSelector, refreshOneBuilding} from "../../../../store/buildings";

import {select, Store} from "@ngrx/store";
import {refresh as refreshBuildingQueue} from "../../../../store/building-queue";
import {tap} from "rxjs/operators";
import {OverlayPanel} from "primeng/overlaypanel";
import {CostsComponent} from "../costs/costs.component";
import {RequirementsComponent} from "../requirements/requirements.component";
import {ResourcesUtils} from "../../../../utils/ResourcesUtils";
import {RequirementsUtils} from "../../../../utils/RequirementsUtils";
import {ResearchesSelector} from "../../../../store/researches";
import {Research} from "../../../../models/Research";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {faHammer, faAnglesUp, faAnglesDown, faX} from "@fortawesome/free-solid-svg-icons";

@UntilDestroy()
@Component({
  selector: 'app-building-card',
  templateUrl: './building-card.component.html',
  styleUrls: ['./building-card.component.scss']
})
export class BuildingCardComponent implements AfterViewInit {
  @Input() building: Building;
  @ViewChild('costPanel') costPanel: OverlayPanel;
  @ViewChild('requirementPanel') requirementPanel: OverlayPanel;
  @ViewChild('requirement') requirement: RequirementsComponent;

  protected readonly faHammer = faHammer;
  protected readonly faAnglesUp = faAnglesUp;
  protected readonly faAnglesDown = faAnglesDown;
  protected readonly faX = faX;

  forceDisable = false;
  resources: PlanetResource[] = null;
  isBuyable = true;
  isNeedMet = true;
  isBuyableDestroy = true;
  requirementsMet = true;
  private buildings: Building[] = [];
  private researches: Research[] = [];

  constructor(
    private buildingService: BuildingService,
    private toastService: MessageService,
    private store: Store,
    private confirmationService: ConfirmationService,
  ) {
    this.store
      .pipe(
        select(planetResourceSelector),
        tap((resources) => {
          this.resources = resources;
          if (typeof this.building !== 'undefined') {
            this.isBuyable = ResourcesUtils.isBuyable(this.resources, this.building.price);
            this.isNeedMet = ResourcesUtils.isBuyable(this.resources, this.building.energy_needed);
            this.isBuyableDestroy = ResourcesUtils.isBuyable(this.resources, this.building.price_destroy);
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store
      .pipe(select(BuildingsSelector), tap((buildings) => {
          this.buildings = buildings;
          if (typeof this.building !== 'undefined') {
            this.requirementsMet = RequirementsUtils.isRequirementMet(this.buildings, this.researches, this.building.requirements)
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store
      .pipe(select(ResearchesSelector), tap((researches) => {
          this.researches = researches;
          if (typeof this.building !== 'undefined') {
            this.requirementsMet = RequirementsUtils.isRequirementMet(this.buildings, this.researches, this.building.requirements)
          }
        }),
        untilDestroyed(this),
      ).subscribe();
  }

  showDestroyConfirmation(event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      header: 'Déconstruire ' + this.building.name + ' ?',
      message: this.building.level > 1 ? 'Déconstruire le bâtiment au niveau ' + (this.building.level - 1) : 'Déconstruire le bâtiment',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.addBuildingToQueue(true);
      },
      reject: () => {
      }
    });
  }

  addBuildingToQueue(destroy = false) {
    this.forceDisable = true;
    this.buildingService.addBuildingToQueue(this.building, destroy).subscribe((response) => {
      this.forceDisable = false;
      let severity;
      let summary;

      if (response.success) {
        severity = 'success';
        summary = 'Success'
        setTimeout(() => {
          this.store.dispatch(refreshOneBuilding({buildingId: this.building.id}));
          this.store.dispatch(refreshBuildingQueue());
        }, 500);
        this.store.dispatch(refreshResources());
      } else {
        severity = 'error';
        summary = 'Error'
      }
      this.toastService.add({severity, summary, detail: response.message});
    })
  }

  ngAfterViewInit() {
    if (typeof this.building !== 'undefined') {
      this.isBuyable = ResourcesUtils.isBuyable(this.resources, this.building.price);
      this.isNeedMet = ResourcesUtils.isBuyable(this.resources, this.building.energy_needed);
      this.requirementsMet = RequirementsUtils.isRequirementMet(this.buildings, this.researches, this.building.requirements)
    }
  }
}
