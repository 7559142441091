@if (showHeader && buildingQueue?.length > 0) {
  <div>
    <span translate>building.queue.header</span>
  </div>
}
@for (queue of buildingQueue; track queue.id; let isFirst = $first;) {
  <div>
    @if (isFirst) {
      <div>{{ queue.name }} => {{ queue.level }} <span>=> {{ remainingTime | secondToDHMS }}</span> =>
        <button (click)="removeQueueItem(queue, isFirst)">Remove</button>
      </div>
      <div>
        <p-progressBar [value]="percentCurrentItem"></p-progressBar>
      </div>
    } @else {
      <div>{{ queue.name }} => {{ queue.level }} =>
        <button (click)="removeQueueItem(queue, isFirst)">Remove</button>
      </div>
    }
  </div>
}
