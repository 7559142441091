export enum MessageType {
  SPY = 'spy',
  BUILDING = 'building',
  PLAYER = 'player',
  ALLY = 'ally',
  FIGHT = 'fight',
  SYSTEM = 'system',
  TRANSPORT = 'transport',
  EXPEDITION = 'expedition',
  GAME = 'game',
}
