import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as TradeRoutesActions from './trade-routes.actions';
import {tradeRoutesSelector} from './trade-routes.selector';
import {map, switchMap, withLatestFrom} from "rxjs";
import {TradeRouteService} from "../../services/http/trade-route.service";

@Injectable()
export class TradeRoutesEffect {
  constructor(
    private store: Store,
    private actions$: Actions,
    private readonly tradeRoutesService: TradeRouteService,
  ) {
  }

  updateAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TradeRoutesActions.UPDATE),
        withLatestFrom(this.store.select(tradeRoutesSelector)),
        // tap(([, universe]) => this.sentryService.setUser(universe)),
      ),
    {dispatch: false},
  );

  refreshResources$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TradeRoutesActions.REFRESH),
        switchMap(() => {
          console.log("reloading tradeRoutes");
          return this.tradeRoutesService.getTradeRoutes().pipe(
            map(tradeRoutes => {
              return TradeRoutesActions.update({tradeRoutes})
            })
          )
        }),
      )
  );
}
