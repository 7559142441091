import {Component} from '@angular/core';
import {TradeRoute} from "../../../../models/TradeRoute";
import {select, Store} from "@ngrx/store";
import {tradeRoutesSelector} from "../../../../store/trade-routes";
import {tap} from "rxjs/operators";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {refresh as refreshTradeRoutes} from "../../../../store/trade-routes";

@UntilDestroy()
@Component({
  selector: 'app-trade-route-manager',
  templateUrl: './trade-route-manager.component.html',
  styleUrls: ['./trade-route-manager.component.scss']
})
export class TradeRouteManagerComponent {

  tradeRoutes: TradeRoute[] = [];
  visibleDialog: boolean = false;

  constructor(private readonly store: Store) {
    this.store.pipe(
      select(tradeRoutesSelector),
      tap((tradeRoutes) => {
        this.tradeRoutes = tradeRoutes;
      }),
      untilDestroyed(this),
    ).subscribe();
    store.dispatch(refreshTradeRoutes());
  }

  tradeRouteCreated() {
    this.visibleDialog = false;
    this.store.dispatch(refreshTradeRoutes());
  }
}
