<div class="text-center">
  <div class="inline-flex">
    @for (type of messageType; track type) {
      <div style="padding: 10px" class="cursor-pointer messageCount"
           [class]="{'active': type === selectedCategory}"
           (click)="getMessageListByType(type, 1)">
        <div>
          <div>
            <span translate>message.category.{{type}}.title</span>
          </div>
          <div>
            <ng-container *tldLet="getMessageCountByType(type) as messageCount">
              <span>{{messageCount.not_read}}/{{messageCount.read + messageCount.not_read}}</span>
            </ng-container>
          </div>
        </div>
      </div>
    }
  </div>
</div>
<div class="text-center pt-5 ml-8 mr-8">
  @if (null === selectedCategory) {
    <div>
      <span>Cliquez sur une categorie pour charger les messages</span>
    </div>
  } @else if (messages.length === 0) {
    <div>
      <span>Pas de message dans la category <b translate>message.category.{{selectedCategory}}.title</b></span>
    </div>
  } @else {
    <div>
      <div class="pb-2">
        <p-button (onClick)="markTypeAsRead()">Read all</p-button>
        <p-button class="ml-2" (onClick)="deleteAllMessageFromType()">Delete all</p-button>
      </div>
      <p-scrollPanel  [style]="{ height: '50vh' }">
        @for (message of messages; track message.id) {
          <p-panel [collapsed]="true" [toggleable]="true" iconPos="start" toggler="header"
                   (collapsedChange)="markMessageAsRead($event, message.id)">
            <ng-template pTemplate="header">
      <span class="cursor-pointer"
            [style.font-weight]="null === message.read_at ? 'bold' : 'normal'">{{getDisplayableDate(message.created_at)}}
        | {{message.subject}}</span>
              <fa-icon class="colorRed cursor-pointer ml-3 mr-3" [icon]="faTrash"
                       (click)="deleteMessage($event, message)"></fa-icon>
            </ng-template>
            <div [innerHTML]="message.message">
            </div>
            @if (message.extra_data) {
              <div class="pt-3">
                <ng-container *tldLet="message.extra_data as extraData">
                  @if (extraData.combat_report_id) {
                    <p-button (onClick)="openCombatReport(extraData.combat_report_id)">Ouvrir le rapport de combat</p-button>
                  } @else if (extraData.spy_report_id){
                    <p-button (onClick)="openSpyReport(extraData.spy_report_id)">Ouvrir le rapport d'espionnage</p-button>
                  }
                </ng-container>
              </div>
            }
          </p-panel>
        }
      </p-scrollPanel>
      <p-button [disabled]="prevPage === null || forceDisable" (onClick)="getMessageListByType(selectedCategory, currentPage-1)"><</p-button>
      <p-button [disabled]="nextPage === null || forceDisable" (onClick)="getMessageListByType(selectedCategory, currentPage+1)">></p-button>
      <p-dialog [(visible)]="showCombatReportDialog" (onHide)="combatReportData = null" appendTo="body">
        <app-combat-report [combatReport]="combatReportData"/>
      </p-dialog>
      <p-dialog [(visible)]="showSpyReportDialog" (onHide)="spyReport = null" appendTo="body">
        <app-spy-report [spyReport]="spyReport"/>
      </p-dialog>
    </div>
  }
</div>
