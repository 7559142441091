import {Component, Input} from '@angular/core';
import {RouterLink, RouterLinkActive} from "@angular/router";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {NgStyle} from "@angular/common";
import {BadgeModule} from "primeng/badge";

@Component({
  selector: 'app-sidenav-link',
  standalone: true,
  imports: [
    RouterLink,
    FaIconComponent,
    NgStyle,
    RouterLinkActive,
    BadgeModule
  ],
  templateUrl: './sidenav-link.component.html',
  styleUrl: './sidenav-link.component.scss'
})
export class SidenavLinkComponent {
  @Input() item!: { route: string; icon: IconProp; label: string }
  @Input() showBadge!: boolean

  constructor() {
  }

}
