import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from '@ngrx/effects';

import * as PlanetResourceActions from './planet-resource.actions';
import {planetResourceSelector} from './planet-resource.selector';
import {exhaustMap, map, switchMap, take, interval, tap, withLatestFrom, Subscription} from "rxjs";
import {PlanetService} from "../../services/http/planet.service";
import {PlanetResource} from "../../models/PlanetResource";
import {cloneDeep} from "lodash-es";

@Injectable()
export class PlanetResourceEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private planetService: PlanetService,
  ) {
  }

  subscription: Subscription;

  tick() {
    this.subscription = interval(1000).pipe(
      switchMap(() => this.store.select(planetResourceSelector)),
      take(1),
      tap((currentResources: PlanetResource[]) => {
        let newResources: PlanetResource[] = [];
        currentResources?.forEach((resource, index) => {
          newResources[index] = cloneDeep<PlanetResource>(resource);
          if (resource.type !== 'energy') {
            if (resource.pivot.value < resource.pivot.max_value) {
              let prodPerSec = resource.pivot.current_production / 3600;
              let newVal = resource.pivot.value + prodPerSec;
              if (newVal > resource.pivot.max_value) {
                newVal = resource.pivot.max_value;
              }
              newResources[index].pivot.value = newVal;
            }
          }
        });
        this.store.dispatch(PlanetResourceActions.update({planetResources: newResources}))
      }),
      tap(() => this.tick())
    ).subscribe();
  }

  updateAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PlanetResourceActions.UPDATE),
        withLatestFrom(this.store.select(planetResourceSelector)),
        // tap(([, universe]) => this.sentryService.setUser(universe)),
      ),
    {dispatch: false},
  );

  refreshResources$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PlanetResourceActions.REFRESH),
        switchMap (() => {
          console.log("reloading resources");
          return this.planetService.getPlanetResources().pipe(
            map(planetResources => {
              return PlanetResourceActions.update({planetResources})
            })
          )
        }),
        tap(() => {
          if (!this.subscription) {
            this.tick();
          }
        })
      )
  );
}
