import { Component } from '@angular/core';
import {UniverseService} from "../../../../services/http/universe.service";
import {Record, Records} from "../../../../models/Records";

@Component({
  selector: 'app-records',
  templateUrl: './records.component.html',
  styleUrls: ['./records.component.scss']
})
export class RecordsComponent {
  records: Records;


  constructor(
    private readonly universeService: UniverseService
  ) {
    this.universeService.getRecords().subscribe(records => {
      this.records = records;
      console.log(records);
    });
  }

  protected readonly Record = Record;
  protected readonly Object = Object;
  protected readonly location = location;
}
