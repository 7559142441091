import { Component, ViewChild } from '@angular/core';
import {BuildingService} from "../../../../services/http/building.service";
import {Building} from "../../../../models/Building";
import {refresh as refreshBuildings, BuildingsSelector} from "../../../../store/buildings";
import {BuildingQueueComponent} from "../../shared/building-queue/building-queue.component";
import {select, Store} from "@ngrx/store";
import {tap} from "rxjs/operators";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-buildings',
  templateUrl: './buildings.component.html',
  styleUrls: ['./buildings.component.scss']
})
export class BuildingsComponent {
  buildingList: Building[] = [];
  constructor(
    private store: Store
  ) {
    store
      .pipe(
        select(BuildingsSelector),
        tap((buildingList) => {
          //console.log('refresh building', buildingList)
          this.buildingList = buildingList;
        }),
        untilDestroyed(this),
      )
      .subscribe();
    store.dispatch(refreshBuildings());
  }
}
