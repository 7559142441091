import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as ResearchQueueActions from './research-queue.actions';
import {ResearchQueueSelector} from './research-queue.selector';
import {exhaustMap, map, switchMap, take, interval, tap, withLatestFrom, Subscription} from "rxjs";
import {ResearchService} from "../../services/http/research.service";
import * as ResearchesActions from "../researches/researches.actions";
import * as ResourceActions from "../resource/planet-resource.actions";
import {ResearchQueue} from "../../models/ResearchQueue";

@Injectable()
export class ResearchQueueEffects {
    constructor(
        private store: Store,
        private actions$: Actions,
        private researchService: ResearchService,
    ) {
    }

    subscription: Subscription;

    tick() {
        this.subscription = interval(1000).pipe(
            switchMap(() => this.store.select(ResearchQueueSelector)),
            take(1),
            tap((currentQueue: ResearchQueue[]) => {
                if (!currentQueue || 0 === currentQueue.length) {
                    return;
                }
                let currentItem = currentQueue[0];
                let startDate = new Date(currentItem.starting_date);
                let endDate = new Date(currentItem.ending_date);
                let startTime = startDate.getTime();
                let maxTime = (endDate.getTime() - startDate.getTime())
                let now = new Date();
                let elapsedTime = (now.getTime()) - startTime;
                let remainingTime = (maxTime - elapsedTime) / 1000;
                if (remainingTime <= 0) {
                    setTimeout(() => {
                        this.store.dispatch(ResearchesActions.refresh())
                        setTimeout(() => this.store.dispatch(ResearchQueueActions.refresh()), 250)
                        setTimeout(() => this.store.dispatch(ResourceActions.refresh()), 250)
                    }, 500)
                }

            }),
            tap(() => this.tick())
        ).subscribe();
    }

    updateAction$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ResearchQueueActions.UPDATE),
                withLatestFrom(this.store.select(ResearchQueueSelector)),
                // tap(([, universe]) => this.sentryService.setUser(universe)),
            ),
        {dispatch: false},
    );

    refreshAction$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ResearchQueueActions.REFRESH),
                exhaustMap(() => this.researchService.getResearchQueue().pipe(
                    map(researchQueue => {
                        console.log("reloading research queue");
                        return ResearchQueueActions.update({researchQueue})
                    })
                )),
                tap(() => {
                    if (!this.subscription) {
                        this.tick();
                    }
                })
            ),
    );
}
