@if (hangar && hangar.level > 0) {
  <div>
    <app-hangar-queue></app-hangar-queue>
    <div class="text-center pt-5">
      <p-button (onClick)="showShip = true; showDefense = false; showScrapDealer = false;" [outlined]="!showShip" [text]="!showShip"><span translate>hangar.buttons.ships</span></p-button>
      <p-button (onClick)="showShip = false; showDefense = true; showScrapDealer = false;" [outlined]="!showDefense" [text]="!showDefense" class="pl-3"><span translate>hangar.buttons.defenses</span></p-button>
      <p-button (onClick)="showShip = false; showDefense = false; showScrapDealer = true;" [outlined]="!showScrapDealer" [text]="!showScrapDealer" class="pl-3"><span translate>hangar.buttons.scrapDealer</span></p-button>
    </div>
    <div class="pt-5">
      @if (showShip) {
        <div class="list">
          @for (ship of shipList; track ship.id) {
            <div>
              <app-ship-card [ship]="ship"></app-ship-card>
            </div>
          }
        </div>
      } @else if (showDefense) {
        <div class="list">
          @for (defense of defenseList; track defense.id) {
            <div>
              <app-defense-card [defense]="defense"></app-defense-card>
            </div>
          }
        </div>
      } @else if (showScrapDealer) {
        <app-scrap-dealer></app-scrap-dealer>
      }
    </div>
  </div>
} @else {
  <div class="flex text-center justify-content-center">
    <span translate [translateParams]="{name: 'building.list.hangar.name' | translate}">common.missing_on_planet</span>
  </div>
}
