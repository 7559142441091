import {MissionType} from "./Enum/MissionType";
import {MissingStatus} from "./Enum/MissingStatus";
import {Resource} from "./Resource";

export class Fleet {
  public id: string | undefined
  public universe_id: number | undefined
  public mission_type: MissionType | undefined
  public mission_status: MissingStatus | undefined
  public target_system_x: number | undefined
  public target_system_y: number | undefined
  public target_system_position: number | undefined
  public start_date: string | undefined
  public reach_date: string | undefined
  public end_stay_date: string | undefined
  public end_date: string | undefined
  public units: Unit[] | null | undefined
  public returning_units: Unit[] | null | undefined
  public cargo: Resource[] | undefined
  public returning_cargo: Resource[] | undefined
  public is_lock: number | undefined
  public start_name: string | undefined
  public start_system_x: number | undefined
  public start_system_y: number | undefined
  public start_system_position: number | undefined
  public formattedUnit: {name:string, count:number}[] | undefined
}

export class Unit {
  ship: number
  count: number
}
