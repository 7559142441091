@if (data) {
  <div class="text-center">
    <p-tabView styleClass="center-p-nav-header">
      @for (round of data.rounds; track index; let index = $index) {
        <p-tabPanel  [header]="'Round #' + (index + 1)">
          <div>
            @for (attacker of round.initialData.attackers; track attackerIndex; let attackerIndex = $index) {
              <div>
                <app-simulation-report-user-card [data]="attacker"></app-simulation-report-user-card>
              </div>
            }
            vs
            @for (defender of round.initialData.defenders; track defenderIndex; let defenderIndex = $index) {
              <div>
                <app-simulation-report-user-card [data]="defender"></app-simulation-report-user-card>
              </div>
            }
          </div>
          <div>
            <div>La flotte attaquante tire avec une force de {{ round.shotData.attackers.damage | numberDot }} sur le
              defenseur. Les boucliers du defenseur absorbent {{ round.shotData.attackers.shieldAbsorbed | numberDot }}
              Points de degats
            </div>
            <div>La flotte du defenseur tire avec une force de {{ round.shotData.defenders.damage | numberDot }} sur
              l'attaquant. Les boucliers du de l'attaquant
              absorbent {{ round.shotData.defenders.shieldAbsorbed | numberDot }} Points de degats
            </div>
          </div>
        </p-tabPanel>
      }
    </p-tabView>

    <div>
      @switch (data.result) {
        @case ('win') {
          <span>L'attaquant a gagne la bataille !</span>
        }
        @case ('lose') {
          <span>Le defenseur a gagne la bataille !</span>
        }
        @default {
          <span>Le combat se termine par un match nul !</span>
        }
      }
    </div>
  </div>
} @else {
  loading...
}
