import {AfterViewInit, Component, Input} from '@angular/core';
import {PlanetResource} from "../../../../models/PlanetResource";
import {MessageService} from "primeng/api";
import {select, Store} from "@ngrx/store";
import {planetResourceSelector, refresh as refreshResources} from "../../../../store/resource";
import {tap} from "rxjs/operators";
import {ResearchService} from "../../../../services/http/research.service";
import {refresh as refreshResearchQueue} from "../../../../store/research-queue";
import {Research} from "../../../../models/Research";
import {refresh as refreshResearches, ResearchesSelector} from "../../../../store/researches";
import {Resource} from "../../../../models/Resource";
import {ResourcesUtils} from "../../../../utils/ResourcesUtils";
import {BuildingsSelector} from "../../../../store/buildings";
import {RequirementsUtils} from "../../../../utils/RequirementsUtils";
import {Building} from "../../../../models/Building";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: 'app-research-card',
    templateUrl: './research-card.component.html',
    styleUrls: ['./research-card.component.scss']
})
export class ResearchCardComponent implements AfterViewInit {
    @Input() research: Research;

    forceDisable = false;
    resources: PlanetResource[] = [];
    requirementsMet = false;
    isBuyable = true;
    private buildings: Building[] = [];
    private researches: Research[] = [];

    constructor(
        private researchService: ResearchService,
        private toastService: MessageService,
        private store: Store,
    ) {
        this.store
            .pipe(
                select(planetResourceSelector),
                tap((resources) => {
                    this.resources = resources;
                    if (typeof this.research !== 'undefined') {
                        this.isBuyable = ResourcesUtils.isBuyable(this.resources, this.research.price);
                    }
                }),
              untilDestroyed(this),
            )
            .subscribe();
        this.store
            .pipe(select(BuildingsSelector), tap((buildings) => {
                this.buildings = buildings;
                if (typeof this.research !== 'undefined') {
                    this.requirementsMet = RequirementsUtils.isRequirementMet(this.buildings, this.researches, this.research.requirements)
                }
            }),
              untilDestroyed(this),
              )
            .subscribe();
        this.store
            .pipe(select(ResearchesSelector), tap((researches) => {
                this.researches = researches;
                if (typeof this.research !== 'undefined') {
                    this.requirementsMet = RequirementsUtils.isRequirementMet(this.buildings, this.researches, this.research.requirements)
                }
            }),
              untilDestroyed(this),
              )
            .subscribe();
    }

    addToQueue() {
        this.forceDisable = true;
        this.researchService.addToQueue(this.research).subscribe((response) => {
            this.forceDisable = false;
            let severity: string;
            let summary: string;

            if (response.success) {
                severity = 'success';
                summary = 'Success'
                setTimeout(() => this.store.dispatch(refreshResearchQueue()), 250);
                setTimeout(() => this.store.dispatch(refreshResearches()), 500);
                setTimeout(() => this.store.dispatch(refreshResources()), 750);
            } else {
                severity = 'error';
                summary = 'Error'
            }
            this.toastService.add({severity, summary, detail: response.message});
        })
    }

    ngAfterViewInit() {
        if (typeof this.research !== 'undefined') {
            this.isBuyable = ResourcesUtils.isBuyable(this.resources, this.research.price);
            this.requirementsMet = RequirementsUtils.isRequirementMet(this.buildings, this.researches, this.research.requirements)
        }
    }
}
