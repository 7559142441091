import {Component, HostBinding} from '@angular/core';
import {SidenavService} from "./sidenav.service";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {
  faChevronRight,
  faGlobe,
  faCity,
  faWarehouse,
  faRecycle,
  faShuttleSpace,
  faSatelliteDish,
  faFlaskVial,
  faTableList,
  faListOl,
  faArrowUpRightDots,
  faInbox,
  faComments,
  faClipboardCheck
} from "@fortawesome/free-solid-svg-icons";
import {ButtonModule} from "primeng/button";
import {SidenavLinkComponent} from "../sidenav-link/sidenav-link.component";
import {Store} from "@ngrx/store";
import {MessageCountSelector} from "../../../../store/messages-count";
import {map} from "rxjs";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {MessageCount} from "../../../../models/MessageCount";

@UntilDestroy()
@Component({
  selector: 'app-sidenav',
  standalone: true,
  imports: [
    FaIconComponent,
    ButtonModule,
    SidenavLinkComponent
  ],
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss'
})
export class SidenavComponent {

  navItem = [
    {
      label: 'Overview',
      route: 'overview',
      icon: faGlobe,
    },
    {
      label: 'Buildings',
      route: 'buildings',
      icon: faCity,
    },
    {
      label: 'Hangar',
      route: 'hangar',
      icon: faWarehouse,
    },
    {
      label: 'Mission Center',
      route: 'missionCenter',
      icon: faShuttleSpace,
    },
    {
      label: 'Galaxy',
      route: 'galaxy',
      icon: faSatelliteDish,
    },
    {
      label: 'Laboratory',
      route: 'laboratory',
      icon: faFlaskVial,
    },
    {
      label: 'Info',
      route: 'info',
      icon: faTableList,
    },
    {
      label: 'UsersPoints',
      route: 'usersPoints',
      icon: faListOl,
    },
    {
      label: 'Achievements',
      route: 'achievements',
      icon: faClipboardCheck,
    },
    {
      label: 'Records',
      route: 'records',
      icon: faArrowUpRightDots,
    },
    {
      label: 'Messages',
      route: 'messages',
      icon: faInbox,
    },
    {
      label: 'Chat',
      route: 'chat',
      icon: faComments,
    },
  ];

  sidenavIcon = faChevronRight;
  private messageCountByType: MessageCount[] = [];

  constructor(
    private store: Store,
    public readonly sidenavService: SidenavService
  ) {
    store.select(MessageCountSelector).pipe(
      map((messagesCount) => this.messageCountByType = messagesCount),
      untilDestroyed(this),
    ).subscribe()
  }

  get messageCount() {
    let unreadCount = 0;
    this.messageCountByType.forEach(messageCount => {
      unreadCount += messageCount.not_read;
    })

    return unreadCount;
  }

  @HostBinding('class.is-expanded')
  get isExpanded() {
    return this.sidenavService.isExpanded;
  }
}
