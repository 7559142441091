<div>
  <app-building-queue></app-building-queue>
  <div class="building-list">
    @for (building of buildingList; track building.id) {
      <div>
        <app-building-card [building]="building"></app-building-card>
      </div>
    }
  </div>
</div>
