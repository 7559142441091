import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError, map, Observable, switchMap, throwError} from "rxjs";
import {AuthService} from "../services/auth.service";
import {JwtHelperService} from "../services/jwt-helper.service";
import {Router} from "@angular/router";
import * as route from "../routes";
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly toastService: MessageService,
    private readonly jwtHelper: JwtHelperService
  ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!request.url.startsWith('api/') || request.url.startsWith('api/languages')) {
      return next.handle(request);
    }
    return this.authService.isAuthenticated().pipe(switchMap(token => {
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      return next.handle(request).pipe(
        catchError((err) => {
          console.log(err);
          switch (err.status) {
            case 499:
              this.router.navigate([route.ROUTE_ACCOUNT_FINALIZE])
              break;
            case 401:
              this.authService.logout();
              this.router.navigate([route.ROUTE_LOGIN]);
              break
            case 423:
              let jsonMessage = JSON.parse(err.error.maintenance_message);
              this.toastService.add({
                severity: 'warn',
                summary: 'Universe under maintenance',
                detail: jsonMessage[this.authService.userLocale??'en']
              })
              this.router.navigate([route.ROUTE_UNIVERSES]);
              break;
          }
          return throwError(err);
        })
      );
    }))
  }
}
