// import {inject} from '@angular/core';
// import {ActivatedRoute,Router} from '@angular/router';
// import {map} from 'rxjs';
// import {select, Store} from "@ngrx/store";
// import {update as updatePlanet} from "../store/planet";
// import * as routes from "../routes";
// import {PlanetService} from "../services/planet.service";
// import {planetSelector} from "../store/planet";
// import {filter, tap} from "rxjs/operators";
// import {Planet} from "../models/Planet";
//
// let isPlanetDefined = false;
//
// export const PlanetGuard = () => {
//   const activatedRoute = inject(ActivatedRoute);
//   const route = activatedRoute.snapshot;
//   const router = inject(Router);
//   const store = inject(Store);
//   store
//     .pipe(
//       select(planetSelector),
//       filter((planet: Planet) => !!planet),
//       tap(() => {
//         isPlanetDefined = true;
//       }),
//     )
//     .subscribe();
//
//   if (isPlanetDefined){
//     return true;
//   }
//
//   const planetId = route.params['planetId'];
//   console.log('planetId =>' + planetId);
//
//   const planetService = inject(PlanetService);
//   return planetService.getPlanet(planetId).pipe(map((planet) => {
//       if (planet) {
//         store.dispatch(updatePlanet({ planet}));
//         return true;
//       } else {
//         router.navigate([routes.ROUTE_UNIVERSES]);
//         return false;
//       }
//     }
//   ))
// }

import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import * as routes from "../routes";
import {planetSelector, update as updatePlanet} from "../store/planet";
import {select, Store} from "@ngrx/store";
import {filter, tap} from "rxjs/operators";
import {Observable} from "rxjs";
import {map} from 'rxjs';
import {Planet} from "../models/Planet";
import {PlanetService} from "../services/http/planet.service";


@Injectable({
  providedIn: 'root',
})
export class PlanetGuard implements CanActivate {

  isPlanetDefined = false;

  constructor(
    private store: Store,
    private planetService: PlanetService,
    private router:Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean|Observable<boolean> {
    this.store
      .pipe(
        select(planetSelector),
        filter((planet: Planet) => !!planet),
        tap(() => {
          this.isPlanetDefined = true;
        }),
      )
      .subscribe();

    if (this.isPlanetDefined){
      return true;
    }
    const planetId = next.params['planetId'];
    console.log('planetId =>' + planetId);


    return this.planetService.getPlanet(planetId).pipe(map((planet) => {
        if (planet) {
          this.store.dispatch(updatePlanet({ planet}));
          return true;
        } else {
          this.router.navigate([routes.ROUTE_UNIVERSES]);
          return false;
        }
      }
    ))
  }
}
