import {Component, Input, OnDestroy} from '@angular/core';
import {ConfirmationService, MessageService} from "primeng/api";
import {select, Store} from "@ngrx/store";
import {tap} from "rxjs";
import {cloneDeep} from "lodash-es";
import {refresh as refreshResources} from "../../../../store/resource";
import {HangarQueue} from "../../../../models/HangarQueue";
import {
  HangarQueueSelector,
  refresh as refreshHangarQueue,
  update as updateHangarQueue
} from "../../../../store/hangar-queue";
import {ShipsSelector, update as updateShips} from "../../../../store/ships";
import {Ship} from "../../../../models/Ship";
import {HangarService} from "../../../../services/http/hangar.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Defense} from "../../../../models/Defense";
import {DefensesSelector, update as updateDefenses} from "../../../../store/defenses";

@UntilDestroy()
@Component({
  selector: 'app-hangar-queue',
  templateUrl: './hangar-queue.component.html',
  styleUrls: ['./hangar-queue.component.scss']
})
export class HangarQueueComponent implements OnDestroy {
  @Input() showHeader = false;
  private readonly intervalId: number;


  hangarQueues: HangarQueue[] = [];
  ships: Ship[] = [];
  defenses: Defense[] = [];
  percentCurrentItem: number = 0;
  remainingTime: number = 0;
  modelIndex: number = null;


  constructor(
    private hangarService: HangarService,
    private toastService: MessageService,
    private store: Store,
    private confirmationService: ConfirmationService,
  ) {
    store
      .pipe(
        select(HangarQueueSelector),
        tap((hangarQueues) => {
          //console.log('refresh building', buildingList)
          this.hangarQueues = cloneDeep<HangarQueue[]>(hangarQueues);
        }),
        untilDestroyed(this),
      )
      .subscribe();
    store
      .pipe(
        select(ShipsSelector),
        tap((ships) => {
          //console.log('refresh building', buildingList)
          this.ships = cloneDeep<Ship[]>(ships);
        }),
        untilDestroyed(this),
      )
      .subscribe();
    store
      .pipe(
        select(DefensesSelector),
        tap((defenses) => {
          //console.log('refresh building', buildingList)
          this.defenses = cloneDeep<Defense[]>(defenses);
        }),
        untilDestroyed(this),
      )
      .subscribe();

    this.store.dispatch(refreshHangarQueue());
    this.intervalId = setInterval(() => {
      this.tick();
    }, 1000);
  }

  refreshAll() {
    this.store.dispatch(refreshHangarQueue());
    this.store.dispatch(refreshResources());
  }

  tick() {
    if (!this.hangarQueues || 0 === this.hangarQueues.length) {
      return;
    }
    let currentItem = this.hangarQueues[0];
    switch (currentItem.model_type) {
      case 'Ship':
        if (null === this.modelIndex || currentItem.model_id !== this?.ships[this.modelIndex]?.id) {
          this.modelIndex = this.ships?.findIndex(ship => ship.id === currentItem.model_id);
        }
        break
      case 'Defense':
        if (null === this.modelIndex || currentItem.model_id !== this?.defenses[this.modelIndex]?.id) {
          this.modelIndex = this.defenses?.findIndex(defense => defense.id === currentItem.model_id);
        }
        break;
    }

    let startDate = new Date(currentItem.starting_date);
    let endDate = new Date(currentItem.ending_date);
    let startTime = startDate.getTime();
    let maxTime = (endDate.getTime() - startDate.getTime())
    let now = new Date();
    let elapsedTime = (now.getTime()) - startTime;
    this.updateCount(currentItem, elapsedTime);
    this.percentCurrentItem = Math.min(Math.floor(elapsedTime / maxTime * 100), 100);
    this.remainingTime = Math.max((maxTime - elapsedTime) / 1000 + 1, 0);
    if (this.remainingTime <= 0 && this.percentCurrentItem >= 100) {
      this.modelIndex = null;
      this.hangarQueues.shift();
      this.percentCurrentItem = 0;
    }
  }

  updateCount(currentItem: HangarQueue, elapsedTime: number) {
    let alreadyBuiltCount = this.getAlreadyBuilt(elapsedTime, currentItem.unit_build_time);
    let builtCount = alreadyBuiltCount - currentItem.built_count;
    if (builtCount > 0) {
      switch (currentItem.model_type) {
        case 'Ship':
          this.ships[this.modelIndex].count += builtCount;
          this.store.dispatch(updateShips({ships: this.ships}))
          break
        case 'Defense':
          this.defenses[this.modelIndex].count += builtCount;
          this.store.dispatch(updateDefenses({defenses: this.defenses}))
          break;
      }

      this.hangarQueues[0].built_count += builtCount;
      this.store.dispatch(updateHangarQueue({hangarQueue: this.hangarQueues}))
      if (currentItem.model_type === 'Ship') {
        this.store.dispatch(refreshResources());
      }
    }
  }

  getAlreadyBuilt(elapsedTime, buildTime) {
    return Math.floor((elapsedTime / 1000) / buildTime);
  }

  ngOnDestroy() {
    clearInterval(this.intervalId)
  }

  removeQueueItem(item: HangarQueue, isFirst: boolean) {
    if (false === isFirst) {
      this.removeFromQueue(item.id);
      return;
    }
    this.confirmationService.confirm({
      header: 'Supprimer ' + item.slug + ' de la file ?',
      message: 'Vous allez récuperer que 75% des ressources engagées sur les vaisseaux restant à construire',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.removeFromQueue(item.id);
      },
      reject: () => {
      }
    });
  }

  removeFromQueue(id: string) {
    this.hangarService.removeShipFromQueue(id).subscribe((response) => {
      let severity;
      if (response.success) {
        severity = 'success';
        this.refreshAll();
      } else {
        severity = 'error';
      }
      this.toastService.add({severity, summary: 'Success', detail: response.message});
    })
  }
}
