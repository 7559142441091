import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DefensesFeatureKey} from "./defenses.reducer";
import {Defense} from "../../models/Defense";

export const DefensesSelector = createFeatureSelector<Defense[]>(DefensesFeatureKey);

export const selectDefensesById = ({defense_id}) => createSelector(
  DefensesSelector,
  (defenses: Defense[]) => defenses?.find(defense => defense.id === defense_id),
);
