import {Component} from '@angular/core';
import {AchievementService} from "../../../../services/http/achievement.service";
import {AchievementCategory} from "../../../../models/Achievement";
import {TabViewModule} from "primeng/tabview";
import {AchievementCardComponent} from "../../shared/achievement-card/achievement-card.component";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-achievements',
  standalone: true,
  imports: [
    TabViewModule,
    AchievementCardComponent,
    TranslateModule
  ],
  templateUrl: './achievements.component.html',
  styleUrl: './achievements.component.scss'
})
export class AchievementsComponent {

  achievementCategories: AchievementCategory[] = null;

  constructor(
    private readonly achievementService: AchievementService,
  ) {
    this.achievementService.getAchievements().subscribe(achievements => {
      this.achievementCategories = achievements;
    });
  }
}
