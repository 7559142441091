import {Component, Input} from '@angular/core';
import {CombatReport} from "../../../../models/CombatReport";
import {CombatPlayer} from "../../../../models/CombatRound";

@Component({
  selector: 'app-combat-report',
  templateUrl: './combat-report.component.html',
  styleUrls: ['./combat-report.component.scss']
})
export class CombatReportComponent {
  @Input() combatReport: CombatReport | null;

  getUserExtraData(userId) {
    return this.combatReport?.extra_data.users.find((user) => user.id == userId) ?? null;
  }

  protected readonly Object = Object;

  getEndData(attackers: CombatPlayer[], userId: string) {
    return attackers.find((attacker) => attacker.userId == userId);
  }
}
