import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {BuildingQueue} from "../../../../models/BuildingQueue";
import {BuildingService} from "../../../../services/http/building.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {take, tap} from "rxjs";
import {refresh as refreshResources} from "../../../../store/resource";
import {BuildingsSelector, refresh as refreshBuildings} from "../../../../store/buildings";
import {select, Store} from '@ngrx/store';
import {BuildingQueueSelector, refresh as refreshBuildingQueue} from "../../../../store/building-queue";
import {cloneDeep} from "lodash-es";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-building-queue',
  templateUrl: './building-queue.component.html',
  styleUrls: ['./building-queue.component.scss']
})
export class BuildingQueueComponent implements OnDestroy {
  @Input() showHeader = false;
  buildingQueue: BuildingQueue[] = [];

  private readonly intervalId: number;
  percentCurrentItem: number = 0;
  remainingTime: number = 0;

  constructor(
    private buildingService: BuildingService,
    private toastService: MessageService,
    private store: Store,
    private confirmationService: ConfirmationService,
  ) {
    store
      .pipe(
        select(BuildingQueueSelector),
        tap((buildingQueue) => {
          //console.log('refresh building', buildingList)
          this.buildingQueue = cloneDeep<BuildingQueue[]>(buildingQueue);
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store.dispatch(refreshBuildingQueue());
    this.intervalId = setInterval(() => {
      this.tick();
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.intervalId)
  }

  refreshAll() {
    this.store.dispatch(refreshBuildingQueue());
    this.store.dispatch(refreshBuildings());
    this.store.dispatch(refreshResources());
  }

  removeQueueItem(item: BuildingQueue, isFirst: boolean) {
    if (false === isFirst) {
      this.removeFromQueue(item.id);
      return;
    }
    this.confirmationService.confirm({
      header: 'Supprimer ' + item.name + ' de la file ?',
      message: 'Vous allez récuperer que 75% des ressources engagées',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.removeFromQueue(item.id);
      },
      reject: () => {
      }
    });
  }

  removeFromQueue(id: string) {
    this.buildingService.removeBuildingFromQueue(id).subscribe((response) => {
      let severity;
      if (response.success) {
        severity = 'success';
        this.refreshAll();
      } else {
        severity = 'error';
      }
      this.toastService.add({severity, summary: 'Success', detail: response.message});
    })
  }

  tick() {
    if (!this.buildingQueue || 0 === this.buildingQueue.length) {
      return;
    }
    let currentItem = this.buildingQueue[0];
    let startDate = new Date(currentItem.starting_date);
    let endDate = new Date(currentItem.ending_date);
    let startTime = startDate.getTime();
    let maxTime = (endDate.getTime() - startDate.getTime())
    let now = new Date();
    let elapsedTime = (now.getTime()) - startTime;
    this.percentCurrentItem = Math.min(Math.floor(elapsedTime / maxTime * 100), 100);
    this.remainingTime = Math.max((maxTime - elapsedTime) / 1000 + 1, 0);
    if (this.remainingTime <= 0 && this.percentCurrentItem >= 100) {
      this.buildingQueue.shift();
      this.percentCurrentItem = 0;
      this.refreshAll();
    }
  }
}
