import {Injectable} from '@angular/core';
import {BaseHttpService} from "./base-http.service";
import {Building} from "../../models/Building";
import {catchError, Observable} from "rxjs";
import {BuildingQueue} from "../../models/BuildingQueue";
import {DefaultMessageResponse} from "../../models/DefaultMessageResponse";

@Injectable({
  providedIn: 'root'
})
export class BuildingService extends BaseHttpService {
  getBuildingById(id: number) {
    return this.http.get<Building>('api/universe/{universeId}/planet/{planetId}/buildings/' + id).pipe(
      catchError(this.handleError<Building>('getBuildingById'))
    );
  }

  getBuildingList() {
    return this.http.get<Building[]>('api/universe/{universeId}/planet/{planetId}/buildings/list').pipe(
      catchError(this.handleError<Building[]>('getBuildingList', []))
    );
  }

  getBuildingQueue() {
    return this.http.get<BuildingQueue[]>('api/universe/{universeId}/planet/{planetId}/buildings/queue').pipe(
      catchError(this.handleError<BuildingQueue[]>('getBuildingQueue', []))
    );
  }

  addBuildingToQueue(building: Building, destroy: boolean): Observable<DefaultMessageResponse> {
    return this.http.post<DefaultMessageResponse>('api/universe/{universeId}/planet/{planetId}/buildings/addToQueue', {
      buildingId: building.id,
      destroy
    }).pipe(
      catchError(this.handleError<DefaultMessageResponse>('addBuildingToQueue'))
    );
  }

  removeBuildingFromQueue(queueId: string): Observable<DefaultMessageResponse> {
    return this.http.post<DefaultMessageResponse>('api/universe/{universeId}/planet/{planetId}/buildings/removeFromQueue', {id: queueId}).pipe(
      catchError(this.handleError<DefaultMessageResponse>('removeBuildingFromQueue'))
    );
  }

}
