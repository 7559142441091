@if (null !== achievementCategories) {
  <div class="achievements">
    <h2>Achievements</h2>
    <p-tabView>
      @for (achievementCategory of achievementCategories; track achievementCategory.name) {
        <p-tabPanel [header]="achievementCategory.name">
          <ng-template pTemplate="header">
            <span translate>achievements.title.{{achievementCategory.name}}</span>
          </ng-template>
          <div class="achievements-list">
            @for (achievement of achievementCategory.achievements; track achievement.id) {
              <div>
                <app-achievement-card [achievement]="achievement"/>
              </div>
            }
          </div>
        </p-tabPanel>
      }
    </p-tabView>
  </div>
}
