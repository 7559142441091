import {Component} from '@angular/core';
import {FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult} from "firebaseui-angular";
import {Router} from "@angular/router";
import * as route from "../../routes";
import {AuthService} from "../../services/auth.service";
import {AccountService} from '../../services/http/account.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(
    private auth: AuthService,
    private router: Router,
    private accountService: AccountService,
  ) {
    if (this.auth.isLoggedIn()) {
      this.router.navigate([route.ROUTE_UNIVERSES]);
    }
    this.accountService.databaseUser.subscribe(user => {
      if (user) {
        this.router.navigate([route.ROUTE_UNIVERSES]);
      }
    })
  }

  successCallback($event: FirebaseUISignInSuccessWithAuthResult) {
    console.log('loggedIn', $event)
  }

  errorCallback($event: FirebaseUISignInFailure) {
    console.log('error', $event)
  }

  uiShownCallback() {
    console.log('uiShownCallback')
  }
}
