import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UniverseComponent } from './universe.component';
import {UniverseRoutingModule} from "./universe-routing.module";
import {PlanetModule} from "./planet/planet.module";
import {SharedUniverseModule} from "./shared/shared-universe.module";



@NgModule({
  declarations: [
    UniverseComponent,
  ],
  imports: [
    SharedUniverseModule,
    CommonModule,
    UniverseRoutingModule,
    PlanetModule
  ]
})
export class UniverseModule { }
