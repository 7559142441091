<div class="sidenav-body-container">
  <div class="sidenav-body flex flex-column justify-content-center align-items-center">
    @for (item of navItem; track item.route) {
      <app-sidenav-link [item]="item" [showBadge]="item.route === 'messages' && messageCount > 0"/>
    }
  </div>
</div>
<!--<p-button-->
<!--  class="toggle-button"-->
<!--  [raised]="true"-->
<!--  [rounded]="true" severity="secondary"-->
<!--  (onClick)="this.sidenavService.toggleSidenav()"-->
<!--  [class.is-flipped]="sidenavService.isExpanded"-->
<!--&gt;-->
<!--  <fa-icon [icon]="sidenavIcon"></fa-icon>-->
<!--</p-button>-->
