import {Component, Input} from '@angular/core';
import {Achievement} from "../../../../models/Achievement";
import {CardModule} from "primeng/card";
import {ButtonModule} from "primeng/button";
import {select, Store} from "@ngrx/store";
import {tap} from "rxjs/operators";
import {BuildingsSelector} from "../../../../store/buildings";
import {ResearchesSelector} from "../../../../store/researches";
import {Research} from "../../../../models/Research";
import {Building} from "../../../../models/Building";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {SharedModule} from "../../../../shared.module";
import {AchievementService} from "../../../../services/http/achievement.service";
import {refresh as refreshUniverseAccountResources} from "../../../../store/universe-account-resource";
import {MessageService} from "primeng/api";
import {
  accountAchievementsSelector,
  refresh as refreshAccountAchievement
} from "../../../../store/account-achievements";
import {AccountAchievement} from "../../../../models/AccountAchievement";
import {OverlayPanelModule} from "primeng/overlaypanel";

@UntilDestroy()
@Component({
  selector: 'app-achievement-card',
  standalone: true,
  imports: [
    CardModule,
    ButtonModule,
    SharedModule,
    OverlayPanelModule,
  ],
  templateUrl: './achievement-card.component.html',
  styleUrl: './achievement-card.component.scss'
})
export class AchievementCardComponent {
  @Input() achievement: Achievement;

  private buildings: Building[] = [];
  private researches: Research[] = [];
  forceDisable: boolean;
  private accountAchievement: AccountAchievement[] = [];

  constructor(
    private readonly store: Store,
    private readonly achievementService: AchievementService,
    private readonly toastService: MessageService
  ) {
    this.store
      .pipe(select(BuildingsSelector), tap((buildings) => {
          this.buildings = buildings;
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store
      .pipe(select(ResearchesSelector), tap((researches) => {
          this.researches = researches;
        }),
        untilDestroyed(this),
      ).subscribe();
    this.store
      .pipe(select(accountAchievementsSelector), tap((accountAchievement) => {
          this.accountAchievement = accountAchievement;
        }),
        untilDestroyed(this),
      ).subscribe();
  }

  getNeed(modelType: string, modelId: string | number) {
    switch (modelType) {
      case 'building':
        return this.buildings.find((element) => element.id == modelId);
      case 'research':
        return this.researches.find((element) => element.id == modelId);
    }
    return null;
  }

  isAllNeedReach() {
    if (this.achievement.needs.length === 0) {
      return true;
    }

    return this.achievement.needs.every((need) => {
      const model = this.getNeed(need.model_type, need.model_id)
      return model && model.level >= need.value;
    });
  }

  isAlreadyClaimed() {
    return !!this.accountAchievement.find((element) => element.id == this.achievement.id);
  }

  claimAchievement() {
    this.forceDisable = true;
    this.achievementService.claimAchievement(this.achievement.id).subscribe(response => {
      if (response.success) {
        this.toastService.add({severity: 'success', summary: 'Success', detail: response.message});
        this.store.dispatch(refreshUniverseAccountResources());
        this.store.dispatch(refreshAccountAchievement());
      } else {
        this.forceDisable = false;
      }
    });
  }
}
