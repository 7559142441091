import {AuthService} from "../services/auth.service";
import {inject} from "@angular/core";
import {Router} from "@angular/router";
import * as routes from '../routes';
import {map} from "rxjs";

export const authGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.isAuthenticated().pipe(map((token) => {
    if (token) {
      return true
    } else {
      router.navigate([routes.ROUTE_LOGIN]);
      return false;
    }
  }))
};
