import {Component, Input, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {refresh as refreshShips, ShipsSelector} from "../../../../store/ships";
import {debounceTime, distinctUntilChanged, tap} from "rxjs";
import {cloneDeep} from "lodash-es";
import {Ship} from "../../../../models/Ship";
import {FleetService} from "../../../../services/http/fleet.service";
import {GetAvailableMissionRequest} from "../../../../models/requests/GetAvailableMissionRequest";
import {planetSelector} from "../../../../store/planet";
import {Planet} from "../../../../models/Planet";
import {universeSelector} from "../../../../store/universe";
import {Universe} from "../../../../models/Universe";
import {FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {CheckSendFleetRequest} from "../../../../models/requests/CheckSendFleetRequest";
import {Resource} from "../../../../models/Resource";
import {refresh as refreshFlyingFleets} from "../../../../store/flying-fleets";
import {planetResourceSelector, refresh as refreshResources} from "../../../../store/resource";
import {Target} from "../../../../models/requests/Target";
import {MissionType} from "../../../../models/Enum/MissionType";
import {ShipType} from "../../../../models/Enum/ShipType";
import {MessageService} from "primeng/api";
import {PlanetResource} from "../../../../models/PlanetResource";
import {ResourceType} from "../../../../models/Enum/ResourceType";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ReloadAfterPlanetChanged, ReloadService} from "../../../../services/ReloadService";
import {planetListSelector} from "../../../../store/planet-list";

@UntilDestroy()
@Component({
  selector: 'app-send-fleet',
  templateUrl: './send-fleet.component.html',
  styleUrls: ['./send-fleet.component.scss']
})
export class SendFleetComponent implements OnInit, ReloadAfterPlanetChanged {

  @Input() inputTarget: Target = null;
  @Input() inputTargetMission: string = null;

  forceDisable = false;

  sendFleetForm = this.fb.group({
    targetModel: ['planet'],
    mission: [''],
    targetSystemX: [0],
    targetSystemY: [0],
    targetSystemPosition: [1],
    speedFactor: [100],
    shipToSend: this.fb.array<FormGroup>([]),
    cargo: this.fb.array<FormGroup>([]),
  });
  ships: Ship[];
  availableMission: string[] = [];
  currentPlanet: Planet;
  availableTarget = ['planet', 'moon'];
  universe: Universe;
  duration = 0;
  consumptions: Resource[] = [];
  canSendFleet: boolean = false;
  resource: PlanetResource[] = [];
  planets: Planet[] = [];

  constructor(
    private readonly fleetService: FleetService,
    private readonly store: Store,
    private readonly fb: FormBuilder,
    private toastService: MessageService,
    private readonly reloadService: ReloadService
  ) {
    this.sendFleetForm.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe(res => {
        //console.log("send data", res);
        if (this.canSendMissionAvailable()) {
          this.getAvailableMission();
        }
        if (this.canSendCheckSendFleet()) {
          this.checkSendFleet();
        }
      });
  }


  readonly planetChangedSub = this.reloadService.planetChanged
    .pipe(
      tap(() => this.resetSendFleetForm()),
      untilDestroyed(this),
    )
    .subscribe();

  ngOnInit() {
    this.store
      .pipe(
        select(planetResourceSelector),
        tap((resource) => {
          this.resource = cloneDeep<PlanetResource[]>(resource);
          this.resource?.forEach(resource => {
            if (resource.type !== ResourceType.BUILD) {
              return;
            }
            let alreadyInGroup = false;
            this.sendFleetForm.controls.cargo.controls.forEach((value, index) => {
              if (resource.slug === value.controls['slug'].value) {
                alreadyInGroup = true;
              }
            })
            if (alreadyInGroup) {
              return;
            }
            const cargoControl = this.fb.group({
              slug: [resource.slug],
              value: [0]
            });
            this.sendFleetForm.controls.cargo.push(cargoControl);
          });
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store
      .pipe(
        select(ShipsSelector),
        tap((ships) => {
          this.ships = cloneDeep<Ship[]>(ships);
          this.initShipList();
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store
      .pipe(
        select(universeSelector),
        tap((universe) => {
          this.universe = universe;
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store
      .pipe(
        select(planetListSelector),
        tap((planets) => {
          this.planets = planets;
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store.pipe(
      select(planetSelector),
      tap((planet) => {
        this.currentPlanet = planet;
        if (!this.inputTarget) {
          this.sendFleetForm.controls.targetSystemX.setValue(planet.system_x);
          this.sendFleetForm.controls.targetSystemY.setValue(planet.system_y);
          this.sendFleetForm.controls.targetSystemPosition.setValue(planet.system_position);
        } else {
          this.sendFleetForm.controls.targetSystemX.setValue(this.inputTarget.system_x);
          this.sendFleetForm.controls.targetSystemY.setValue(this.inputTarget.system_y);
          this.sendFleetForm.controls.targetSystemPosition.setValue(this.inputTarget.system_position);
        }
      }),
      untilDestroyed(this),
    ).subscribe();
  }

  private initShipList() {
    if (this.ships) {
      this.ships.forEach(ship => {
        if (ship.speed > 0) {
          let alreadyInGroup = false;
          let controlIndex = null;
          this.sendFleetForm.controls.shipToSend.controls.forEach((value, index) => {
            if (ship.id === value.controls['ship'].value) {
              alreadyInGroup = true;
              controlIndex = index;
            }
          })
          if (ship.count === 0 && alreadyInGroup) {
            this.sendFleetForm.controls.shipToSend.controls.splice(controlIndex, 1)
          }
          if (alreadyInGroup || ship.count === 0) {
            return;
          }
          let count = 0;
          if (this.inputTargetMission) {
            switch (this.inputTargetMission) {
              case MissionType.COLONIZATION:
                if (ship.type === ShipType.COLONIZATION && ship.count > 0) {
                  count = 1;
                }
                break;
            }
          }
          const shipControl = this.fb.group({
            ship: [ship.id],
            count: [count]
          });
          this.sendFleetForm.controls.shipToSend.push(shipControl);
          this.sortShipToSend();
        }
      });
    }
  }

  sortShipToSend() {
    let array = this.sendFleetForm.controls.shipToSend.value
    array.sort((a, b) => a.ship - b.ship)
    this.sendFleetForm.controls.shipToSend.patchValue(array)
  }

  private canSendMissionAvailable(): boolean {
    let data = this.sendFleetForm.value;

    if (0 === data.targetModel.length) {
      return false;
    }

    if (
      typeof data.targetSystemX === 'undefined'
      || null === data.targetSystemX
      || typeof data.targetSystemY === 'undefined'
      || null === data.targetSystemY
      || typeof data.targetSystemPosition === 'undefined'
      || null === data.targetSystemPosition
    ) {
      return false;
    }

    let hasShip = false;
    data.shipToSend.forEach(ship => {
      if (parseInt(ship.count) > 0) {
        hasShip = true;
      }
    });

    if (false === hasShip) {
      this.availableMission = [];
      this.sendFleetForm.controls.mission.setValue('');
      this.duration = 0;
      this.consumptions = [];
      this.sendFleetForm.controls.cargo.controls.forEach((value) => {
        value.controls['value'].setValue(0);
      });
    }

    return hasShip;
  }

  canSendCheckSendFleet(): boolean {
    let data = this.sendFleetForm.value;

    if (!this.canSendMissionAvailable()) {
      return false;
    }

    let enough = true;
    data.cargo.forEach(cargo => {
      const resource = this.resource.find(resource => resource.slug === cargo.slug);
      if (cargo.value > resource.pivot.value) {
        enough = false
      }
    })

    if (!enough) {
      return false;
    }

    if (0 > this.availableCargo) {
      return false;
    }

    return 0 !== data.mission.length;
  }

  get shipToSend() {
    return this.sendFleetForm.get('shipToSend') as FormArray<FormGroup<{ ship: FormControl, count: FormControl }>>;
  }


  getShipFromId(id: number) {
    return this.ships.find((ship) => ship.id === id);
  }

  get cargoCapacity() {
    let capacity = 0;
    this.shipToSend.controls.forEach(shipControl => {
      if (shipControl.controls.count.value <= 0) {
        return;
      }
      let ship = this.getShipFromId(shipControl.controls.ship.value);
      capacity += ship.capacity * shipControl.controls.count.value;
    });
    return capacity;
  }

  get sumConsumptions(): number {
    let consumption = 0;
    this.consumptions?.forEach(resource => {
      consumption += resource.value;
    })
    return consumption;
  }

  get sumCargo(): number {
    let cargo = 0;
    this.cargoToSend.controls.forEach(cargoControl => {
      let parsed = parseInt(cargoControl.value.value);
      if (!isNaN(parsed)) {
        cargo += parsed;
      }
    });
    return cargo;
  }

  get cargoToSend() {
    return this.sendFleetForm.get('cargo') as FormArray<FormGroup<{ slug: FormControl, value: FormControl }>>;
  }

  get availableCargo(): number {
    return this.cargoCapacity - this.sumConsumptions - this.sumCargo;
  }

  getMaxAvailableCargo(resource) {
    let max = resource.pivot.value;
    this.consumptions?.forEach(consumptionResource => {
      if (consumptionResource.name === resource.slug) {
        max -= consumptionResource.value;
      }
    })
    return Math.floor(max);
  }

  getResourceFromSlug(slug: string) {
    return this.resource.find((resource) => resource.slug === slug);
  }

  private fillCommonRequestData<T>(request: CheckSendFleetRequest | GetAvailableMissionRequest): T {
    request.target_model = this.sendFleetForm.value.targetModel;
    request.target = {
      system_x: this.sendFleetForm.value.targetSystemX,
      system_y: this.sendFleetForm.value.targetSystemY,
      system_position: this.sendFleetForm.value.targetSystemPosition
    }
    request.ships = [];
    this.sendFleetForm.value.shipToSend.forEach((value) => {
      if (null === value.count || parseInt(value.count) <= 0) {
        return;
      }
      request.ships.push({
        ship: value.ship,
        count: parseInt(value.count)
      })
    });
    return request as T;
  }

  getAvailableMission() {
    let request = this.fillCommonRequestData<GetAvailableMissionRequest>(new GetAvailableMissionRequest());
    this.fleetService.getAvailableMission(request).subscribe((availableMission) => {
      this.availableMission = availableMission;
      if (this.inputTargetMission) {
        availableMission.forEach(mission => {
          if (mission === this.inputTargetMission && this.sendFleetForm.value.mission !== this.inputTargetMission) {
            this.sendFleetForm.controls.mission.setValue(this.inputTargetMission);
          }
        })
      }
    });
  }

  private buildFleetRequest() {
    let request = this.fillCommonRequestData<CheckSendFleetRequest>(new CheckSendFleetRequest());
    request.speed_factor = this.sendFleetForm.value.speedFactor;
    request.cargo = [];
    this.sendFleetForm.value.cargo.forEach((value) => {
      if (value.value <= 0) {
        return;
      }
      let cargoValue = parseInt(value.value)
      if (!isNaN(cargoValue)) {
        request.cargo.push({
          name: value.slug,
          value: cargoValue
        })
      }
    });
    request.mission = this.sendFleetForm.value.mission;
    console.log(request);
    return request;
  }

  checkSendFleet() {
    this.forceDisable = true;
    this.fleetService.checkSendFleet(this.buildFleetRequest()).subscribe(response => {
      this.forceDisable = false;
      this.canSendFleet = response.success;

      if (!response.success) {
        this.toastService.add({severity: 'error', summary: 'Error', detail: response.message});
      }

      if (typeof response.data !== 'undefined') {
        this.duration = response.data.duration;
        this.consumptions = response.data.consumption;
      } else {
        this.duration = 0;
        this.consumptions = [];
      }
    });
  }

  sendFleet() {
    this.fleetService.sendFleet(this.buildFleetRequest()).subscribe(response => {
      if (response?.success) {
        setTimeout(() => {
          this.store.dispatch(refreshFlyingFleets());
          this.store.dispatch(refreshShips());
        }, 1500);
        this.store.dispatch(refreshResources());
        this.resetSendFleetForm();
      } else {
        this.toastService.add({severity: 'error', summary: 'Error', detail: response.message});
      }
    });
  }

  private resetSendFleetForm() {
    this.sendFleetForm.controls.shipToSend.controls.forEach((value) => {
      value.controls['count'].setValue(0);
    });
    this.sendFleetForm.controls.mission.setValue('');
    this.sendFleetForm.controls.speedFactor.setValue(100);
    this.sendFleetForm.controls.cargo.controls.forEach((value) => {
      value.controls['value'].setValue(0);
    });
    this.duration = 0;
    this.consumptions = [];
    this.availableMission = [];
  }

  get reachDate() {
    return 0 !== this.duration ? new Date((new Date()).getTime() + this.duration * 1000) : null;
  }

  get returnDate() {
    return 0 !== this.duration ? new Date((new Date()).getTime() + (this.duration * 1000) * 2) : null;
  }

  setPlanetCoordinates(planet: Planet) {
    this.sendFleetForm.controls.targetSystemX.setValue(planet.system_x);
    this.sendFleetForm.controls.targetSystemY.setValue(planet.system_y);
    this.sendFleetForm.controls.targetSystemPosition.setValue(planet.system_position);
  }

  fillToMax(resourceGroup, resource) {
    let newValue = Math.min(this.getMaxAvailableCargo(resource), this.availableCargo + (resourceGroup.value.value - 2));

    resourceGroup.controls.value.setValue(newValue);
  }

  protected readonly Planet = Planet;
  protected readonly MissionType = MissionType;
}
