import {Injectable} from '@angular/core';
import {BaseHttpService} from "./base-http.service";
import {Language} from "../../models/Language";
import {catchError, map, Observable} from "rxjs";
import {TranslateLoader} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class DeviceTokenService extends BaseHttpService  {

  addToken(token: string): Observable<any> {
    return this.http.post('api/devices/add', {token});
  }
}
