<div class="text-center pt-2 pb-5">
  <p-button (onClick)="showProductionInfo = true; showEmpire = false;" [outlined]="showEmpire"
            [text]="showEmpire">Production planétaire
  </p-button>
  <p-button (onClick)="showProductionInfo = false; showEmpire = true;" [outlined]="showProductionInfo"
            [text]="showProductionInfo" class="pl-3">Vue empire
  </p-button>
</div>
@if (showProductionInfo) {
  <app-production-info></app-production-info>
} @else if (showEmpire) {
  <app-empire></app-empire>
}
