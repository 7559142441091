import { Component } from '@angular/core';
import {ButtonModule} from "primeng/button";
import {SharedUniverseModule} from "../../shared/shared-universe.module";
import {ProductionInfoComponent} from "../../shared/production-info/production-info.component";

@Component({
  selector: 'app-planet-info',
  standalone: true,
  imports: [
    ButtonModule,
    SharedUniverseModule,
    ProductionInfoComponent,
  ],
  templateUrl: './planet-info.component.html',
  styleUrl: './planet-info.component.scss'
})
export class PlanetInfoComponent {
  showProductionInfo = true;
  showEmpire = false;

}
