<div>
  <p-card>
    <ng-template pTemplate="title">
      <span class="cursor-pointer" (mouseover)="descriptionPanel.show($event)"
            (mouseout)="descriptionPanel.hide()">{{achievement.name}}</span>
      <p-overlayPanel #descriptionPanel>
        <ng-template pTemplate="content">
          <div style="max-width: 25vw;">
            <span>{{achievement.description}}</span>
          </div>
        </ng-template>
      </p-overlayPanel>
    </ng-template>
    <h4>Prérequis</h4>
    <ul>
      @for(need of achievement.needs; track need.model_id) {
        <ng-container *tldLet="getNeed(need.model_type, need.model_id) as needEntity">
          <li [class]="{'colorGreen': needEntity.level >= need.value}">{{needEntity.name}} => {{needEntity.level >= need.value ? need.value : needEntity.level}}/{{need.value}}</li>
        </ng-container>
      }
    </ul>
    <h4>Récompense</h4>
    <ul>
      @for(reward of achievement.resources; track reward.id) {
        <li>{{reward.slug}} => {{reward.value}}</li>
      }
    </ul>
    <ng-template pTemplate="footer">
      <p-button [disabled]="forceDisable || !isAllNeedReach() || isAlreadyClaimed()" [label]="isAlreadyClaimed() ? 'Already claim' :'Claim'" (onClick)="claimAchievement()">
      </p-button>
    </ng-template>
  </p-card>
</div>
