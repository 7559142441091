<p-card [subheader]="research.level === 0 ? '' : 'niveau '+research.level">
  <ng-template pTemplate="title">
    <span class="cursor-pointer" (mouseover)="descriptionPanel.show($event)" (mouseout)="descriptionPanel.hide()">{{research.name}}</span>
    <p-overlayPanel #descriptionPanel>
      <ng-template pTemplate="content">
        <div style="max-width: 25vw;">
          <span >{{research.description}}</span>
        </div>
      </ng-template>
    </p-overlayPanel>
  </ng-template>
  <!--    <ng-template pTemplate="header">-->
  <!--      <img alt="Card" src="https://primefaces.org/cdn/primeng/images/usercard.png" />-->
  <!--    </ng-template>-->
  <div>
    <div>
      Temps de recherche
      <ul>
        <li>{{research.build_time | secondToDHMS}}</li>
      </ul>
    </div>
    <div>
      Coût pour rechercher le prochain niveau
      <app-costs [costs]="research.price"></app-costs>
    </div>
    <div>
      Resource requise pour rechercher le prochain niveau
      <app-costs [costs]="research.energy_needed"></app-costs>
    </div>
    @if (research.requirements.length > 0) {
      <div [class]="{'colorRed': !requirementsMet, 'colorGreen': requirementsMet}" >
        Prérequis pour la recherche
        <app-requirements [requirements]="research.requirements"></app-requirements>
      </div>
    }
  </div>
  <ng-template pTemplate="footer">
    <div>
      <p-button [disabled]="!isBuyable || !requirementsMet || forceDisable || research.level >= research.max_level" (onClick)="addToQueue()"
                [label]="research.level === research.max_level ? 'Max level' : (research.level === 0 ? 'Rechercher' : 'Rechercher le niveau ' + research.next_level)"
      ></p-button>
    </div>
  </ng-template>
</p-card>
