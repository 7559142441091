import { TLDLetDirective } from './tld-let.directive';
export class TLDLetDirectiveContext<T> {
  get $implicit(): T {
    return this.directiveInstance.tldLet;
  }
  get tldLet(): T {
    return this.directiveInstance.tldLet;
  }
  constructor(private directiveInstance: TLDLetDirective<T>) {}
}
