import {cloneDeep} from "lodash-es";

export class Planet {
  public id: number | undefined;
  public name: string | undefined;
  public universe_id: number | undefined;
  public system_x: number | undefined;
  public system_y: number | undefined;
  public system_position: number | undefined;
  public diameter: number | undefined;
  public max_field: number | undefined;
  public used_field: number | undefined;
  public min_temperature: number | undefined;
  public max_temperature: number | undefined;
  public building_queue: string | undefined;
  public hangar_queue: string | undefined;
  public moon_id: number | null | undefined;
  public is_mother_planet: boolean | undefined;
  public displayName: string | undefined;
  public laboratory_busy: boolean | undefined;
  public laboratory_under_construction: boolean | undefined;
  public hangar_busy: boolean | undefined;
  public hangar_under_construction: boolean | undefined;
  public universe_account_id: number | undefined;

  static formatPlanet(planet: Planet) {
    let newPlanet = cloneDeep<Planet>(planet);
    newPlanet.displayName = planet.name + ` [${planet.system_x}:${planet.system_y}:${planet.system_position}]`;
    return newPlanet;
  }
}
