import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as PlanetAction from './planet.actions';
import { planetSelector } from './planet.selector';
import {exhaustMap, map, tap, withLatestFrom} from "rxjs";
import {PlanetService} from "../../services/http/planet.service";

@Injectable()
export class PlanetEffects {
  currentPlanetId = null;
  constructor(
    private store: Store,
    private actions$: Actions,
    private planetService: PlanetService,
    // private sentryService: SentryService,
  ) {}

  updateAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PlanetAction.UPDATE),
        withLatestFrom(this.store.select(planetSelector)),
        tap(([, planet]) => this.currentPlanetId = planet.id),
        // tap(([, planet]) => this.sentryService.setUser(planet)),
      ),
    { dispatch: false },
  );

  refreshAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PlanetAction.REFRESH),
        exhaustMap(() => this.planetService.getPlanet(this.currentPlanetId).pipe(
          map(planet => {
            console.log("reloading planet");
            return PlanetAction.update({planet})
          })
        )),
      ),
  );
}
