import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondToDHMS'
})
export class SecondToDHMSPipe implements PipeTransform {

  transform(seconds: number): string {
    let s = Math.abs(seconds);
    let m = 0;
    let h = 0;
    let d = 0;
    if (s > 59) {
      m = Math.floor(s / 60);
      s = s - m * 60;
    }
    if (m > 59) {
      h = Math.floor(m / 60);
      m = m - h * 60;
    }
    if (h > 24) {
      d = Math.floor(h / 24);
      h = h - d * 24;
    }
    return (seconds < 0 ? '-' : '')+(d > 0 ? this.dezInt(d, 1) + 'd ' : '') + ((h > 0 || d > 0) ? this.dezInt(h, 1) + 'h ' : '') + ((h > 0 || d > 0 || m > 0) ? this.dezInt(m, 2) + "m "  : '')+ this.dezInt(s, 2) + 's';
  }
  dezInt(num, size, prefix = "0") {
    let minus = (num < 0) ? "-" : "",
      result = (prefix == "0") ? minus : "";
    num = Math.abs(parseInt(num, 10));
    size -= ("" + num).length;
    for (let i = 1; i <= size; i++) {
      result += "" + prefix;
    }
    result += ((prefix != "0") ? minus : "") + num;
    return result;
  }

}
