import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {authGuard} from "../../guards/auth.guard";
import {UniverseComponent} from "./universe.component";
import {UniverseGuard} from "../../guards/universe.guard";
import {PageNotFoundComponent} from "../page-not-found/page-not-found.component";
import {PlanetModule} from "./planet/planet.module";

const routeList: Routes = [
  {
    path: 'universe',
    canActivate: [authGuard],
    children: [
      {
        path: ':universeId',
        component: UniverseComponent,
        canActivate: [UniverseGuard],
        loadChildren: () => PlanetModule,
      },
    ]
  },

  {path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routeList)],
  exports: [RouterModule]
})
export class UniverseRoutingModule {
}
