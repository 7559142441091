import {Component, Input} from '@angular/core';
import {Resource} from "../../../../models/Resource";
import {ResourceType} from "../../../../models/Enum/ResourceType";
import {Building} from "../../../../models/Building";
import {PlanetResource} from "../../../../models/PlanetResource";
import {BuildingService} from "../../../../services/http/building.service";
import {MessageService} from "primeng/api";
import {select, Store} from "@ngrx/store";
import {planetResourceSelector} from "../../../../store/resource";
import {tap} from "rxjs/operators";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ResourcesUtils} from "../../../../utils/ResourcesUtils";

@UntilDestroy()
@Component({
  selector: 'app-consumption',
  templateUrl: './consumption.component.html',
  styleUrls: ['./consumption.component.scss']
})
export class ConsumptionComponent {
  @Input() datas: Resource[];
  @Input() datasToSearch: Resource[];

  resources: PlanetResource[] = null;

  constructor(
    private store: Store,
  ) {
    this.store
      .pipe(
        select(planetResourceSelector),
        tap((resources) => {
          this.resources = resources;
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  getMissingResource(name: string, needed: number, resource: Resource): number | null {
    let missing = null;

    if (!this.resources) {
      return missing;
    }

    this.resources.forEach(item => {
      if (item.slug === name && item.type === ResourcesUtils.getResourceTypeByResourceName(resource.name)) {
        if (ResourcesUtils.getResourceTypeByResourceName(resource.name) === ResourceType.ENERGY) {
          missing = item.pivot.max_value - item.pivot.value - needed;
        } else {
          missing = item.pivot.current_production - needed;
        }
      }
    })

    if (missing < 0) {
      return Math.abs(missing)
    } else {
      return null;
    }
  }

  findResourceByName(name: string): Resource {
    let value;
    this.datasToSearch.forEach((resource) => {
      if (resource.name == name) {
        value = resource;
      }
    })
    return value;
  }

  protected readonly ResourceType = ResourceType;
  protected readonly ResourcesUtils = ResourcesUtils;
}
