import { Component } from '@angular/core';
import {ButtonModule} from "primeng/button";
import {AccountService} from "../../../../services/http/account.service";
import {Store} from "@ngrx/store";
import {refresh as refreshUniverseAccountResources} from "../../../../store/universe-account-resource";

@Component({
  selector: 'app-daily-bonus-dialog',
  standalone: true,
  imports: [
    ButtonModule
  ],
  templateUrl: './daily-bonus-dialog.component.html',
  styleUrl: './daily-bonus-dialog.component.scss'
})
export class DailyBonusDialogComponent {

  claimMessage: string = null;
  bonus: number = null;
  streak: number = null;
  errorMessage: string = null;
  remainingTime: string = null;
  constructor(
    private readonly accountService: AccountService,
    private readonly store: Store,
  ) {
  }
  claim() {
    this.accountService.claimDailyBonus().subscribe(response => {
      if (response?.success) {
        this.claimMessage = response.message;
        this.streak = response.data.streak;
        this.bonus = response.data.bonus;
        this.store.dispatch(refreshUniverseAccountResources());
      } else {
        this.errorMessage = response.message;
        if (response.data?.remaining_time) {
          this.remainingTime = response.data.remaining_time;
        }
      }
    });
  }
}
