import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {map, filter, tap} from 'rxjs/operators';
import {environment} from "../../environments/environment";
import {select, Store} from "@ngrx/store";
import {universeSelector} from "../store/universe";
import {Universe} from "../models/Universe";
import {planetSelector} from "../store/planet";
import {Planet} from "../models/Planet";

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {
  private universeId: number | undefined;
  private planetId: number | undefined;
  private universeAccountId: number | undefined;

  constructor(
    private store: Store
  ) {
    this.observeSelectedUniverse();
    this.observeSelectedPlanet();
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!request.url.startsWith('api/')) {
      return next.handle(request);
    }
    let url = this.replaceApi(request.url);
    if (url.includes('{universeId}')) {
      url = this.replaceUniverseId(url);
    }
    if (url.includes('{planetId}')) {
      url = this.replacePlanetId(url);
    }
    if (url.includes('{universeAccountId}')) {
      url = this.replaceUniverseAccountId(url);
    }
    const sendableRequest: HttpRequest<any> = request.clone({
      url,
      // setHeaders: {
      //   'app': APP_CODE,
      //   'app-platform': APP_PLATFORM,
      //   'app-version': packageInfo.version,
      // },
    });
    return next.handle(sendableRequest);
  }

  private observeSelectedUniverse(): void {
    this.store
      .pipe(
        select(universeSelector),
        filter((universe: Universe) => !!universe),
        map((universe: Universe) => universe.id),
        tap((universeId: number | undefined) => (this.universeId = universeId)),
      )
      .subscribe();
  }

  private observeSelectedPlanet(): void {
    this.store
      .pipe(
        select(planetSelector),
        filter((planet: Planet) => !!planet),
        map((planet: Planet) => {
          this.universeAccountId = planet.universe_account_id;
          return planet.id
        }),
        tap((planetId: number | undefined) => (this.planetId = planetId)),
      )
      .subscribe();
  }

  private replaceApi(url: string) {
    return `${environment.api.baseUrl}/${url.replace(
      'api/',
      '',
    )}`;
  }

  private replaceUniverseId(url: string): string {
    return url.replace('{universeId}', String(this.universeId));
  }

  private replacePlanetId(url: string): string {
    return url.replace('{planetId}', String(this.planetId));
  }
  private replaceUniverseAccountId(url: string): string {
    return url.replace('{universeAccountId}', String(this.universeAccountId));
  }
}
