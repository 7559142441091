import {NgModule} from "@angular/core";
import {NumberDotPipe} from "./pipe/number-dot.pipe";
import { SecondToDHMSPipe } from './pipe/second-to-dhms.pipe';
import {TLDLetDirective} from "./directives/tld-let/tld-let.directive";
import {CastPipe} from "./pipe/cast.pipe";
@NgModule({
  declarations: [NumberDotPipe, SecondToDHMSPipe, TLDLetDirective, CastPipe],
// exports is required so you can access your component/pipe in other modules
exports: [NumberDotPipe, SecondToDHMSPipe, TLDLetDirective, CastPipe]
})
export class SharedModule{}
