import {Injectable} from '@angular/core';
import {BaseHttpService} from "./base-http.service";
import {catchError} from "rxjs";
import {TradeRoute} from "../../models/TradeRoute";
import {DefaultMessageResponse} from "../../models/DefaultMessageResponse";

@Injectable({
  providedIn: 'root'
})
export class TradeRouteService extends BaseHttpService {
  getTradeRoutes() {
    return this.http.get<TradeRoute[]>('api/universe/{universeId}/tradeRoutes/list').pipe(
      catchError(this.handleError<TradeRoute[]>('getTradeRoutes', []))
    );
  }

  getTradeRoute(id: number) {
    return this.http.get<TradeRoute>('api/universe/{universeId}/tradeRoutes/' + id).pipe(
      catchError(this.handleError<TradeRoute>('getTradeRoute'))
    );
  }

  createTradeRoute(data: any) {
    return this.http.post<DefaultMessageResponse>('api/universe/{universeId}/tradeRoutes', data).pipe(
      catchError(this.handleError<DefaultMessageResponse>('createTradeRoute'))
    );
  }

  updateTradeRoute(id: number, data: any) {
    return this.http.put<TradeRoute>('api/universe/{universeId}/tradeRoutes/' + id, data).pipe(
      catchError(this.handleError<TradeRoute>('updateTradeRoute'))
    );
  }

  deactivateTradeRoute(id: number) {
    return this.http.post<DefaultMessageResponse>('api/universe/{universeId}/tradeRoutes/' + id + '/deactivate', {}).pipe(
      catchError(this.handleError<DefaultMessageResponse>('deactivateTradeRoute'))
    );
  }

  activateTradeRoute(id: number) {
    return this.http.post<DefaultMessageResponse>('api/universe/{universeId}/tradeRoutes/' + id + '/activate', {}).pipe(
      catchError(this.handleError<DefaultMessageResponse>('activateTradeRoute'))
    );
  }
}
