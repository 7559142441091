<ul style="color: initial">
  @for (data of requirements; track data.id) {
    <li>
      <ng-container *tldLet="getModelLevel(data.type, data.id) as modelLevel">
        <span [class]="{ 'colorRed': modelLevel < data.level, 'colorGreen': modelLevel >= data.level }">
      {{data.name}} niveau {{modelLevel >= data.level ? data.level : modelLevel}}/{{data.level}}
        </span>
      </ng-container>
    </li>
  }
</ul>
