import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as UniverseActions from './universe.actions';
import { universeSelector } from './universe.selector';
import {Universe} from "../../models/Universe";
import {tap, withLatestFrom} from "rxjs";
@Injectable()
export class UniverseEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    // private sentryService: SentryService,
  ) {}

  updateAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UniverseActions.UPDATE),
        withLatestFrom(this.store.select(universeSelector)),
        // tap(([, universe]) => this.sentryService.setUser(universe)),
      ),
    { dispatch: false },
  );
}
