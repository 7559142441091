<div class="mr-8 ml-8">
  <form [formGroup]="recycleForm">
    <div>
      <div>
        <span>Defenses:</span>
      </div>
      <div formArrayName="recycleForm" class="flex flex-wrap">
        @for (defenseGroup of defenseToRecycle.controls; track defenseGroup.value.defense; let i = $index) {
          <div class="flex flex-column pr-2">
            <ng-container *tldLet="getDefenseFromId(defenseGroup.value.defense) as defense">
              <span>{{ defense.name }} ({{ defense.count }})</span>
              <p-inputNumber inputId="shipToSend-{{i}}" [formControl]="defenseGroup.controls.count" mode="decimal"
                             [min]="0" [max]="defense.count"></p-inputNumber>
            </ng-container>
          </div>
        }
      </div>
      <div>
        <span>Ships:</span>
      </div>
      <div formArrayName="recycleForm" class="flex flex-wrap">
        @for (shipGroup of shipToRecycle.controls; track shipGroup.value.ship; let i = $index) {
          <div class="flex flex-column pr-2">
            <ng-container *tldLet="getShipFromId(shipGroup.value.ship) as ship">
              <span>{{ ship.name }} ({{ ship.count }})</span>
              <p-inputNumber inputId="shipToSend-{{i}}" [formControl]="shipGroup.controls.count" mode="decimal"
                             [min]="0" [max]="ship.count"></p-inputNumber>
            </ng-container>
          </div>
        }
      </div>
    </div>
  </form>
  <br>
  <p-button (onClick)="recycle()" label="Recycler"
            [disabled]="!canRecycle() || forceDisable"></p-button>
  <br>
  <p>Vous récupérez seulement 70% des ressources lors du recyclage</p>
</div>
