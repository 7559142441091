<div style="width: 100%">
  <div class="flex justify-content-center">
    @if (claimMessage) {
      <div>
        <div>
          <span>{{ claimMessage }}</span>
        </div>
        <div>
          <span>{{ bonus }} tldCash gagnés</span>
        </div>
        <div>
          <span>{{ streak }} jour(s) d'affilée</span>
        </div>
      </div>
    } @else if (errorMessage) {
      <div>
        <div>
          <span>{{ errorMessage }}</span>
        </div>
        @if (remainingTime) {
          <div>
            <span>Temps avant le prochain claim {{ remainingTime }}</span>
          </div>
        }
      </div>
    } @else {
      <p-button label="Claim" (onClick)="claim()"/>
    }
  </div>
</div>
