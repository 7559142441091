import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from '@ngrx/effects';

import * as PlanetListActions from './planet-list.actions';
import {planetListSelector} from './planet-list.selector';
import {exhaustMap, map, tap, withLatestFrom} from "rxjs";
import {PlanetService} from "../../services/http/planet.service";

@Injectable()
export class PlanetListEffects {
    constructor(
        private readonly store: Store,
        private readonly actions$: Actions,
        private readonly planetService: PlanetService
    ) {
    }

    updateAction$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(PlanetListActions.UPDATE),
                withLatestFrom(this.store.select(planetListSelector)),
                // tap(([, planet]) => this.sentryService.setUser(planet)),
            ),
        {dispatch: false},
    );

    refreshAction$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(PlanetListActions.REFRESH),
                exhaustMap(() => this.planetService.getPlanets().pipe(
                    map(planets => {
                        console.log("reloading planets list");
                        return PlanetListActions.update({planets})
                    })
                )),
            ),
    );
}
