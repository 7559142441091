import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as FlyingFleetsActions from './flying-fleets.actions';
import {FlyingFleetsSelector} from './flying-fleets.selector';
import {exhaustMap, interval, map, Subscription, switchMap, take, tap, withLatestFrom} from "rxjs";
import {FleetService} from "../../services/http/fleet.service";
import {refresh as RefreshResource} from "../resource/planet-resource.actions";
import {FlyingFleet} from "../../models/FlyingFleet";
import {FleetUtils} from "../../utils/FleetUtils";
import {refresh as refreshShips} from "../ships/ships.actions";
import {refresh as refreshPlanetList} from "../planet-list/planet-list.actions";
import {refresh as refreshMessageCount} from "../messages-count";
import {MissionType} from "../../models/Enum/MissionType";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {planetSelector} from "../planet";
import {Planet} from "../../models/Planet";
import {Fleet} from "../../models/Fleet";

@UntilDestroy()
@Injectable()
export class FlyingFleetsEffects {
  currentPosition = null;

  constructor(
    private store: Store,
    private actions$: Actions,
    private fleetService: FleetService,
  ) {
    this.store.pipe(
      select(planetSelector),
      untilDestroyed(this)
    ).subscribe(planet => {
      this.currentPosition = {x: planet?.system_x, y: planet?.system_y, position: planet?.system_position}
    })
  }

  subscription: Subscription;
  refreshing = false;

  tick() {
    this.subscription = interval(1000).pipe(
      switchMap(() => this.store.select(FlyingFleetsSelector)),
      take(1),
      tap((flyingFleet: FlyingFleet) => {
        if (this.refreshing) {
          return;
        }
        let refreshType = [];
        let refreshCurrent = false;
        let needRefresh = false;
        const checkFleet = (fleet: Fleet) => {
          if (1 === fleet.is_lock) {
            return;
          }
          if (0 >= FleetUtils.getRemainingTime(fleet)) {
            needRefresh = true;
            refreshType.push(fleet.mission_type);
            let target = FleetUtils.getTargetPosition(fleet);
            if (target.x === this.currentPosition.x && target.y === this.currentPosition.y && target.position === this.currentPosition.position) {
              refreshCurrent = true;
            }
          }
        }
        flyingFleet.own.forEach(checkFleet);
        flyingFleet.coming.forEach(checkFleet);
        if (needRefresh) {
          this.refreshing = true;
          this.store.dispatch(FlyingFleetsActions.refresh());
          setTimeout(() => {
            if (refreshCurrent) {
              this.store.dispatch(RefreshResource());
              this.store.dispatch(refreshShips());
            }
            if (refreshType.includes(MissionType.COLONIZATION)) {
              this.store.dispatch(refreshPlanetList());
            }
            this.store.dispatch(refreshMessageCount());
          }, 500);
        }
      }),
      tap(() => this.tick())
    ).subscribe();
  }


  updateAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FlyingFleetsActions.UPDATE),
        withLatestFrom(this.store.select(FlyingFleetsSelector)),
        // tap(([, universe]) => this.sentryService.setUser(universe)),
      ),
    {dispatch: false},
  );

  refreshAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FlyingFleetsActions.REFRESH),
        tap(() => {
          this.refreshing = true;
        }),
        exhaustMap(() => this.fleetService.getFlyingFleets().pipe(
          map(flyingFleet => {
            console.log("reloading flying-fleets");
            this.refreshing = false;
            return FlyingFleetsActions.update({flyingFleet: flyingFleet})
          })
        )),
        tap(() => {
          if (!this.subscription) {
            this.tick();
          }
        })
      ),
  );
}
