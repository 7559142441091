import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {ResearchService} from "../../../../services/http/research.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {take, tap} from "rxjs";
import {refresh as refreshResources} from "../../../../store/resource";
import {refresh as refreshResearches} from "../../../../store/researches";
import {select, Store} from '@ngrx/store';
import {ResearchQueueSelector, refresh as refreshResearchQueue} from "../../../../store/research-queue";
import {cloneDeep} from "lodash-es";
import {ResearchQueue} from "../../../../models/ResearchQueue";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {BuildingQueue} from "../../../../models/BuildingQueue";

@UntilDestroy()
@Component({
    selector: 'app-research-queue',
    templateUrl: './research-queue.component.html',
    styleUrls: ['./research-queue.component.scss']
})
export class ResearchQueueComponent implements OnDestroy {
  @Input() showHeader = false;
  researchQueue: ResearchQueue[] = [];

    private readonly intervalId: number;
    percentCurrentItem: number = 0;
    remainingTime: number = 0;

    constructor(
        private researchService: ResearchService,
        private toastService: MessageService,
        private store: Store,
        private confirmationService: ConfirmationService,
    ) {
        store
            .pipe(
                select(ResearchQueueSelector),
                tap((researchQueue) => {
                    //console.log('refresh building', buildingList)
                    this.researchQueue = cloneDeep<ResearchQueue[]>(researchQueue);
                }),
              untilDestroyed(this),
            )
            .subscribe();
        this.store.dispatch(refreshResearchQueue());
        this.intervalId = setInterval(() => {
            this.tick();
        }, 1000);
    }

    ngOnDestroy() {
        clearInterval(this.intervalId)
    }

    refreshAll() {
        this.store.dispatch(refreshResearchQueue());
        this.store.dispatch(refreshResearches());
        this.store.dispatch(refreshResources());
    }

  removeQueueItem(item: ResearchQueue, isFirst: boolean) {
    if (false === isFirst) {
      this.removeFromQueue(item.id);
      return;
    }
    this.confirmationService.confirm({
      header: 'Supprimer ' + item.name + ' de la file ?',
      message: 'Vous allez récuperer que 75% des ressources engagées',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.removeFromQueue(item.id);
      },
      reject: () => {
      }
    });
  }

  removeFromQueue(id: string) {
        this.researchService.removeFromQueue(id).subscribe((response) => {
            let severity;
            if (response.success) {
                severity = 'success';
                this.refreshAll();
            } else {
                severity = 'error';
            }
            this.toastService.add({severity, summary: 'Success', detail: response.message});
        })
    }

    tick() {
        if (!this.researchQueue || 0 === this.researchQueue.length) {
            return;
        }
        let currentItem = this.researchQueue[0];
        let startDate = new Date(currentItem.starting_date);
        let endDate = new Date(currentItem.ending_date);
        let startTime = startDate.getTime();
        let maxTime = (endDate.getTime() - startDate.getTime())
        let now = new Date();
        let elapsedTime = (now.getTime()) - startTime;
        this.percentCurrentItem = Math.min(Math.floor(elapsedTime / maxTime * 100), 100);
        this.remainingTime = Math.max((maxTime - elapsedTime) / 1000 + 1, 0);
        if (this.remainingTime <= 0 && this.percentCurrentItem >= 100) {
            this.researchQueue.shift();
            this.percentCurrentItem = 0;
            this.refreshAll();
        }
    }
}
