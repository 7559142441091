<div class="text-center mb-8">
  <div>
    <div>
      <p-dropdown [options]="planets" (onChange)="onPlanetChangeEvent($event)" optionLabel="displayName" [ngModel]="selectedPlanet"></p-dropdown>
      <fa-icon [icon]="faPenToSquare" class="cursor-pointer" (click)="editPlanet()"></fa-icon>
    </div>
    <div class="resourcesList">
      <div>
        <ul>
          <li>name</li>
          <li>current_value</li>
          <li>max_value</li>
          <li>current_prod</li>
        </ul>
      </div>
      @for (resource of buildResources; track resource.pivot.resource_id) {
        <div [id]="'resource_'+resource.pivot.resource_id">
          <ul [pTooltip]="tooltipContent" tooltipPosition="bottom">
            <li class="resouce_name"><span>{{ resource.slug }}</span></li>
            <li class="resouce_curent_value"><span [class]="{ 'colorRed': resource.pivot.value >= resource.pivot.max_value }">{{ resource.pivot.value | numberDot }}</span></li>
            <li class="resouce_max_value"><span class="colorGreen">{{ resource.pivot.max_value | numberDot }}</span></li>
            <li class="resouce_current_production"><span>{{ resource.pivot.current_production | numberDot }}/h</span></li>
          </ul>
          <ng-template #tooltipContent>
            <ul>
              <li>{{ resource.pivot.current_production * 24 | numberDot }}/j</li>
              <li>{{ resource.pivot.current_production * 24 * 7 | numberDot }}/s</li>
            </ul>
          </ng-template>
        </div>
      }
      @for (resource of energyResources; track resource.pivot.resource_id) {
        <div [id]="'resource_'+resource.pivot.resource_id">
          <ul>
            <li class="resouce_name"><span>{{ resource.slug }}</span></li>
            <li class="resouce_curent_value"><span [class]="{ 'colorRed': resource.pivot.max_value - resource.pivot.value < 0, 'colorGreen': resource.pivot.max_value - resource.pivot.value > 0 }">{{ resource.pivot.max_value - resource.pivot.value | numberDot }}</span></li>
            <li class="resouce_max_value"><span class="colorYellow">{{ resource.pivot.value | numberDot }}</span></li>
            <li class="resouce_current_production"><span class="colorGreen">{{ resource.pivot.max_value | numberDot }}</span></li>
          </ul>
        </div>
      }
      <div>
        <ul>
          <li>name</li>
          <li>available</li>
          <li>current_consumption</li>
          <li>current_prod</li>
        </ul>
      </div>
      @for (resource of universeAccountResource; track resource.pivot.resource_id) {
        <div [id]="'resource_'+resource.pivot.resource_id">
          <ul>
            <li class="resouce_name"><span>{{ resource.slug }}</span></li>
            <li class="resouce_curent_value"><span>{{ resource.pivot.value | numberDot }}</span></li>
          </ul>
        </div>
      }
    </div>
  </div>
</div>
