export const environment = {
  vapidKey: 'BHq3o76_MYjnbJg75vap3F9YYkalEzKq8ImjTOqhQR32YzU8ocUNyEUsty2GZEBJzTGtSDQ69u7L_L7bfDR9yaA',
  firebase: {
    projectId: 'tld-wars-phoenix',
    appId: '1:576580861756:web:78dd609c5b001600aea839',
    databaseURL: 'https://tld-wars-phoenix.firebaseio.com',
    storageBucket: 'tld-wars-phoenix.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyDBT5bw2eN590AGogYWSIb6lPe5RdXSxz8',
    authDomain: 'tld-wars-phoenix.firebaseapp.com',
    messagingSenderId: '576580861756',
    measurementId: 'G-G7FZF5426X',
  },
  api: {
    baseUrl: 'https://api.beta.tld-wars.ovh/api',
  },
  version: 'b1e12a35'
}
