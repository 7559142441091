export class TradeRoute {
  public id: number | undefined;
  public universe_account_id: number | undefined;
  public source_type: string | undefined;
  public source_id: number | undefined;
  public target_type: string | undefined;
  public target_id: number | undefined;
  public resource_id: number | undefined;
  public quantity: number | undefined;
  public trigger_time: string | undefined;
  public active_until: string | undefined;
}
