import {createFeatureSelector, createSelector} from "@ngrx/store";
import {ResearchesFeatureKey} from "./researches.reducer";
import {Research} from "../../models/Research";

export const ResearchesSelector = createFeatureSelector<Research[]>(ResearchesFeatureKey);

export const selectShipById = ({research_id}) => createSelector(
  ResearchesSelector,
  (researches: Research[]) => researches?.find(research => research.id === research_id),
);
