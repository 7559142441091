<app-sidenav/>
<div class="content">
  <app-top-nav/>
  <p-dialog header="Bonus quotidien disponible" [(visible)]="showDailyBonusDialog">
    <app-daily-bonus-dialog/>
  </p-dialog>
  <div class="game" appKonami (konami)="goToNewFeature()">
    <p-confirmDialog/>
    <router-outlet/>
  </div>
</div>
