import {Injectable} from '@angular/core';
import {BaseHttpService} from "./base-http.service";
import {catchError} from "rxjs";
import {DefaultMessageResponse} from "../../models/DefaultMessageResponse";
import {HttpParams} from "@angular/common/http";
import {Message} from "../../models/Message";
import {MessageCount} from "../../models/MessageCount";
import {PaginatedResponse} from "../../models/PaginatedResponse";

@Injectable({
  providedIn: 'root'
})
export class MessageService extends BaseHttpService {

  list(type: string = null, page: number = 1) {
    let param = {};
    if (null !== type) {
      param['params'] = (new HttpParams()).set('type', type);
    }
    if (typeof param['params'] === 'undefined') {
      param['params'] = (new HttpParams()).set('page', page);
    } else {
      param['params'] = param['params'].set('page', page);
    }
    return this.http.get<PaginatedResponse<Message>>('api/universe/{universeId}/messages/list', param).pipe(
      catchError(this.handleError<PaginatedResponse<Message>>('list')),
    );
  }

  messageCountByType() {
    return this.http.get<MessageCount[]>('api/universe/{universeId}/messages/count').pipe(
      catchError(this.handleError<MessageCount[]>('messageCountByType', []))
    );
  }

  markMessageAsRead(id: string) {
    return this.http.post<Message>('api/universe/{universeId}/message/' + id + '/markAsRead', {}).pipe(
      catchError(this.handleError<Message>('markMessageAsRead'))
    );
  }

  deleteMessage(id: string) {
    return this.http.delete<DefaultMessageResponse>('api/universe/{universeId}/message/' + id + '/delete', {}).pipe(
      catchError(this.handleError<DefaultMessageResponse>('deleteMessage'))
    );
  }

  markTypeAsRead(type: string) {
    let params = (new HttpParams()).set('type', type)
    return this.http.post<DefaultMessageResponse>('api/universe/{universeId}/messages/markAsRead', params).pipe(
      catchError(this.handleError<DefaultMessageResponse>('markTypeAsRead'))
    );
  }

  deleteMessagesOfType(type: string) {
    let params = (new HttpParams()).set('type', type)
    return this.http.post<DefaultMessageResponse>('api/universe/{universeId}/messages/delete', params).pipe(
      catchError(this.handleError<DefaultMessageResponse>('deleteMessagesOfType'))
    );
  }

}
