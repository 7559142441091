import {NgModule} from '@angular/core';
import {BuildingCardComponent} from './building-card/building-card.component';
import {BuildingQueueComponent} from './building-queue/building-queue.component';
import {DatePipe, JsonPipe, KeyValuePipe, NgForOf, NgIf, CommonModule} from "@angular/common";
import {ProgressBarModule} from "primeng/progressbar";
import {SharedModule} from "../../../shared.module";
import {CardModule} from "primeng/card";
import {ButtonModule} from "primeng/button";
import {ChatComponent} from './chat/chat.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HangarQueueComponent} from './hangar-queue/hangar-queue.component';
import {ShipCardComponent} from './ship-card/ship-card.component';
import {ResearchCardComponent} from "./research-card/research-card.component";
import {ResearchQueueComponent} from "./research-queue/research-queue.component";
import {RequirementsComponent} from './requirements/requirements.component';
import {CostsComponent} from './costs/costs.component';
import {
  DisplayCurrentNextResourcesComponent
} from './display-current-next-resources/display-current-next-resources.component';
import {ConsumptionComponent} from './consumption/consumption.component';
import {OverlayPanelModule} from "primeng/overlaypanel";
import {FleetTableComponent} from './fleet-table/fleet-table.component';
import {SendFleetComponent} from './send-fleet/send-fleet.component';
import {TableModule} from "primeng/table";
import {DropdownModule} from "primeng/dropdown";
import {InputNumberModule} from "primeng/inputnumber";
import {RadioButtonModule} from "primeng/radiobutton";
import {InputTextModule} from "primeng/inputtext";
import {SliderModule} from "primeng/slider";
import {EditPlanetComponent} from './edit-planet/edit-planet.component';
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {DefenseCardComponent} from "./defense-card/defense-card.component";
import {DialogModule} from "primeng/dialog";
import {TabViewModule} from "primeng/tabview";
import {PanelModule} from "primeng/panel";
import {OrganizationChartModule} from "primeng/organizationchart";
import {KonamiDirective} from "../../../directives/konami/konami.directive";
import {TooltipModule} from "primeng/tooltip";
import {CombatReportComponent} from './combat-report/combat-report.component';
import {CombatReportUserCardComponent} from './combat-report-user-card/combat-report-user-card.component';
import {TradeRouteCardComponent} from './trade-route-card/trade-route-card.component';
import {CalendarModule} from "primeng/calendar";
import {SidenavService} from "./sidenav/sidenav.service";
import {TradeRouteManagerComponent} from "./trade-route-manager/trade-route-manager.component";
import {TranslateModule} from "@ngx-translate/core";
import {EmpireComponent} from "./empire/empire.component";

@NgModule({
  declarations: [
    BuildingCardComponent,
    BuildingQueueComponent,
    ChatComponent,
    HangarQueueComponent,
    ShipCardComponent,
    ResearchCardComponent,
    ResearchQueueComponent,
    RequirementsComponent,
    CostsComponent,
    DisplayCurrentNextResourcesComponent,
    ConsumptionComponent,
    FleetTableComponent,
    SendFleetComponent,
    EditPlanetComponent,
    DefenseCardComponent,
    KonamiDirective,
    CombatReportComponent,
    CombatReportUserCardComponent,
    TradeRouteCardComponent,
    TradeRouteManagerComponent,
    EmpireComponent
  ],
  imports: [
    NgForOf,
    NgIf,
    ProgressBarModule,
    KeyValuePipe,
    JsonPipe,
    SharedModule,
    CardModule,
    ButtonModule,
    DatePipe,
    FormsModule,
    OverlayPanelModule,
    TableModule,
    DropdownModule,
    InputNumberModule,
    RadioButtonModule,
    InputTextModule,
    SliderModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    FontAwesomeModule,
    DialogModule,
    TabViewModule,
    PanelModule,
    OrganizationChartModule,
    TooltipModule,
    CalendarModule,
    TranslateModule,
    CommonModule,
  ],
  exports: [
    BuildingCardComponent,
    BuildingQueueComponent,
    ShipCardComponent,
    HangarQueueComponent,
    ResearchCardComponent,
    ResearchQueueComponent,
    FleetTableComponent,
    SendFleetComponent,
    DefenseCardComponent,
    KonamiDirective,
    CombatReportComponent,
    TradeRouteCardComponent,
    TradeRouteManagerComponent,
    EmpireComponent
  ],
  providers: [
    SidenavService
  ]
})
export class SharedUniverseModule {
}
