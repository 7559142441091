<app-fleet-table></app-fleet-table>
<div class="text-center pt-5">
  <p-button (onClick)="showSendFleet = true; showTradeRouteManager = false; showBattleSimulator= false;"
            [outlined]="!showSendFleet"
            [text]="!showSendFleet">Envoi de vaisseau
  </p-button>
  <p-button (onClick)="showSendFleet = false; showTradeRouteManager = true; showBattleSimulator= false;"
            [outlined]="!showTradeRouteManager"
            [text]="!showTradeRouteManager" class="pl-3">Route de transport
  </p-button>
  <p-button (onClick)="showSendFleet = false; showTradeRouteManager = false; showBattleSimulator= true;"
            [outlined]="!showBattleSimulator"
            [text]="!showBattleSimulator" class="pl-3">Simulateur de combat
  </p-button>
</div>
@if (showSendFleet) {
  <app-send-fleet/>
} @else if (showTradeRouteManager) {
  <app-trade-route-manager/>
} @else if (showBattleSimulator) {
  <app-battle-simulator/>
}
