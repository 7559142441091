import {Injectable} from '@angular/core';
import {BaseHttpService} from "./base-http.service";
import {catchError} from "rxjs";
import {HttpParams} from "@angular/common/http";
import {MarketOffer, PaginatedMarketOffers} from "../../models/MarketOffer";
import {DefaultMessageResponse} from "../../models/DefaultMessageResponse";
import {CreateMarketOffer} from "../../models/requests/CreateMarketOffer";

@Injectable({
  providedIn: 'root'
})
export class MarketService extends BaseHttpService {

  public getMarketOffers(type: string, page: number) {
    let param = {};
    if (null !== type) {
      param['params'] = (new HttpParams()).set('type', type);
    }
    if (typeof param['params'] === 'undefined') {
      param['params'] = (new HttpParams()).set('page', page);
    } else {
      param['params'] = param['params'].set('page', page);
    }
    return this.http.get<PaginatedMarketOffers>('api/universe/{universeId}/market/list', param).pipe(
      catchError(this.handleError<PaginatedMarketOffers>('getMarketOffers'))
    );
  }

  public buyOffer(offerId: string, fromId: number, fromType: string) {
    return this.http.post<DefaultMessageResponse>(`api/universe/{universeId}/market/${offerId}/buy`, {
      from_id: fromId,
      from_type: fromType
    }).pipe(
      catchError(this.handleError<DefaultMessageResponse>('buyOffer'))
    );
  }

  public deleteOffer(offerId: string) {
    return this.http.delete<DefaultMessageResponse>(`api/universe/{universeId}/market/${offerId}`).pipe(
      catchError(this.handleError<DefaultMessageResponse>('deleteOffer'))
    );
  }

  public createOffer(marketOffer: CreateMarketOffer) {
    return this.http.post<MarketOffer|DefaultMessageResponse>(`api/universe/{universeId}/market`, marketOffer).pipe(
      catchError(this.handleError<MarketOffer|DefaultMessageResponse>('createOffer'))
    );
  }
}
