import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Requirement} from "../../../../models/Requirement";
import {select, Store} from "@ngrx/store";
import {BuildingsSelector} from "../../../../store/buildings";
import {ResearchesSelector} from "../../../../store/researches";
import {tap} from "rxjs/operators";
import {Building} from "../../../../models/Building";
import {Research} from "../../../../models/Research";
import {RequirementsUtils} from "../../../../utils/RequirementsUtils";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: 'app-requirements',
    templateUrl: './requirements.component.html',
    styleUrls: ['./requirements.component.scss']
})
export class RequirementsComponent {
    @Input() requirements: Requirement[];
    private buildings: Building[];
    private researches: Research[];

    constructor(
        private store: Store,
    ) {
        this.store
            .pipe(select(BuildingsSelector), tap((buildings) => {
                this.buildings = buildings;
            }),
              untilDestroyed(this),
              )
            .subscribe();
        this.store
            .pipe(select(ResearchesSelector), tap((buildings) => {
                this.researches = buildings;
            }),
              untilDestroyed(this),
              )
            .subscribe();
    }

    getModelLevel(modelType: string, modelId: string | number) {
        switch (modelType) {
            case 'Building':
                let building = this.buildings.find((element) => element.id == modelId);
                return building?.level;
            case 'Research':
                let research = this.researches.find((element) => element.id == modelId);
                return research?.level;
            default:
                return -1;
        }
    }

    public get isRequirementMet(): boolean {
        return RequirementsUtils.isRequirementMet(this.buildings, this.researches, this.requirements)
    }
}
