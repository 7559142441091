import {PlanetResource} from "../models/PlanetResource";
import {Resource} from "../models/Resource";
import {ResourceType} from "../models/Enum/ResourceType";

export class ResourcesUtils {
  static isBuyable(resources: PlanetResource[], costs: Resource[]): boolean {
    if (typeof resources === 'undefined' || typeof costs === 'undefined') {
      return false;
    }
    let isBuyable = true;
    costs.forEach(item => {
      if (null !== this.getLeftToPayByName(resources, item.name, item.value)) {
        isBuyable = false;
      }
    });

    return isBuyable;
  }

  static getLeftToPayByName(resources: PlanetResource[], resourceName: string, valueToPay: number): number | null {
    let resourceType = this.getResourceTypeByResourceName(resourceName);
    let resourceValue = null;
    if (resourceType === ResourceType.ENERGY) {
      resourceValue = this.getMaxResourceValueByName(resources, resourceName);
    } else {
      resourceValue = this.getResourceValueByName(resources, resourceName);
    }


    if (resourceValue === null) {
      return null;
    }

    let leftToPay = Math.floor(resourceValue) - Math.floor(valueToPay);

    if (leftToPay < 0) {
      return Math.abs(leftToPay)
    } else {
      return null;
    }
  }

  static getResourceValueByName(resources: PlanetResource[], name: string): number | null {
    let value = null;

    if (!resources) {
      return value;
    }

    resources.forEach(item => {
      if (item.slug === name) {
        value = item.pivot.value;
      }
    })

    return value;
  }

  static getMaxResourceValueByName(resources: PlanetResource[], name: string): number | null {
    let value = null;

    if (!resources) {
      return value;
    }

    resources.forEach(item => {
      if (item.slug === name) {
        value = item.pivot.max_value;
      }
    })

    return value;
  }

  static getResourceTypeByResourceName(name: string) {
    switch (name) {
      case 'energy':
        return ResourceType.ENERGY;
      case 'tld_cash':
        return ResourceType.PREMIUM;
      default:
        return ResourceType.BUILD;
    }
  }
}
