import {Component, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {MessageService} from "primeng/api";
import {ReloadAfterPlanetChanged, ReloadService} from "../../../../services/ReloadService";
import {tap} from "rxjs";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Ship} from "../../../../models/Ship";
import {Defense} from "../../../../models/Defense";
import {cloneDeep} from "lodash-es";
import {refresh as refreshShips, ShipsSelector} from "../../../../store/ships";
import {refresh as refreshDefenses, DefensesSelector} from "../../../../store/defenses";
import {HangarService} from "../../../../services/http/hangar.service";
import {refresh as refreshResources} from "../../../../store/resource";

@UntilDestroy()

@Component({
  selector: 'app-scrap-dealer',
  templateUrl: './scrap-dealer.component.html',
  styleUrls: ['./scrap-dealer.component.scss']
})
export class ScrapDealerComponent implements OnInit, ReloadAfterPlanetChanged {

  recycleForm = this.fb.group({
    shipToRecycle: this.fb.array<FormGroup>([]),
    defenseToRecycle: this.fb.array<FormGroup>([]),
  });

  ships: Ship[];
  defenses: Defense[];

  forceDisable = false;

  constructor(
    private hangarService: HangarService,
    private readonly store: Store,
    private readonly fb: FormBuilder,
    private toastService: MessageService,
    private readonly reloadService: ReloadService
  ) {

  }

  readonly planetChangedSub = this.reloadService.planetChanged
    .pipe(
      tap(() => this.resetRecycleForm()),
      untilDestroyed(this),
    )
    .subscribe();

  canRecycle() {
    let canRecycle = false;
    this.recycleForm.value.shipToRecycle.forEach((value) => {
      if (value.count > 0) {
        canRecycle = true;
      }
    });
    this.recycleForm.value.defenseToRecycle.forEach((value) => {
      if (value.count > 0) {
        canRecycle = true;
      }
    });
    return canRecycle;
  }

  ngOnInit() {
    this.store
      .pipe(
        select(ShipsSelector),
        tap((ships) => {
          this.ships = cloneDeep<Ship[]>(ships);
          this.initShipList();
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store
      .pipe(
        select(DefensesSelector),
        tap((defenses) => {
          this.defenses = cloneDeep<Defense[]>(defenses);
          this.initDefenseList();
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private initShipList() {
    if (this.ships) {
      this.ships.forEach(ship => {
        let alreadyInGroup = false;
        let controlIndex = null;
        this.recycleForm.controls.shipToRecycle.controls.forEach((value, index) => {
          if (ship.id === value.controls['ship'].value) {
            alreadyInGroup = true;
            controlIndex = index;
          }
        })
        if (ship.count === 0 && alreadyInGroup) {
          this.recycleForm.controls.shipToRecycle.controls.splice(controlIndex, 1)
        }
        if (alreadyInGroup || ship.count === 0) {
          return;
        }
        let count = 0;
        const shipControl = this.fb.group({
          ship: [ship.id],
          count: [count]
        });
        this.recycleForm.controls.shipToRecycle.push(shipControl);
        this.sortShipToRecycle();
      });
    }
  }

  private sortShipToRecycle() {
    let array = this.recycleForm.controls.shipToRecycle.value
    array.sort((a, b) => a.ship - b.ship)
    this.recycleForm.controls.shipToRecycle.patchValue(array)
  }

  private initDefenseList() {
    if (this.defenses) {
      this.defenses.forEach(defense => {
        let alreadyInGroup = false;
        let controlIndex = null;
        this.recycleForm.controls.defenseToRecycle.controls.forEach((value, index) => {
          if (defense.id === value.controls['defense'].value) {
            alreadyInGroup = true;
            controlIndex = index;
          }
        })
        if (defense.count === 0 && alreadyInGroup) {
          this.recycleForm.controls.defenseToRecycle.controls.splice(controlIndex, 1)
        }
        if (alreadyInGroup || defense.count === 0) {
          return;
        }
        let count = 0;
        const shipControl = this.fb.group({
          defense: [defense.id],
          count: [count]
        });
        this.recycleForm.controls.defenseToRecycle.push(shipControl);
        this.sortDefenseToRecycle();
      });
    }
  }

  private sortDefenseToRecycle() {
    let array = this.recycleForm.controls.shipToRecycle.value
    array.sort((a, b) => a.ship - b.ship)
    this.recycleForm.controls.shipToRecycle.patchValue(array)
  }

  private resetRecycleForm() {
    this.recycleForm.controls.shipToRecycle.controls.forEach((value) => {
      value.controls['count'].setValue(0);
    });
    this.recycleForm.controls.defenseToRecycle.controls.forEach((value) => {
      value.controls['count'].setValue(0);
    });
  }

  getShipFromId(id: number) {
    return this.ships.find((ship) => ship.id === id);
  }

  get shipToRecycle() {
    return this.recycleForm.get('shipToRecycle') as FormArray<FormGroup<{ ship: FormControl, count: FormControl }>>;
  }

  getDefenseFromId(id: number) {
    return this.defenses.find((defense) => defense.id === id);
  }

  get defenseToRecycle() {
    return this.recycleForm.get('defenseToRecycle') as FormArray<FormGroup<{
      defense: FormControl,
      count: FormControl
    }>>;
  }

  recycle() {
    let request = {
      ships: [],
      defenses: []
    }
    this.recycleForm.value.shipToRecycle.forEach((value) => {
      if (value.count <= 0) {
        return;
      }
      request.ships.push({
        ship: value.ship,
        count: value.count
      })
    });
    this.recycleForm.value.defenseToRecycle.forEach((value) => {
      if (value.count <= 0) {
        return;
      }
      request.defenses.push({
        defense: value.defense,
        count: value.count
      })
    });

    this.hangarService.recycle(request).subscribe(response => {
      if (response?.success) {
        setTimeout(() => {
          this.store.dispatch(refreshShips());
          this.store.dispatch(refreshDefenses());
        }, 1500);
        this.store.dispatch(refreshResources());
        this.resetRecycleForm();
        this.toastService.add({severity: 'success', summary: 'Success', detail: response.message});
      } else {
        this.toastService.add({severity: 'error', summary: 'Error', detail: response.message});
      }
    });
  }
}
