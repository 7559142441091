<ul>
  @for (data of datas; track data.name) {
    <li>
      <ng-container *tldLet="findResourceByName(data.name) as resource">
        <ng-container *tldLet="getMissingResource(data.name, data.value - resource.value, resource) as missingValue">
          @if (ResourcesUtils.getResourceTypeByResourceName(resource.name) === ResourceType.ENERGY) {
            {{ data.name }}: {{ resource.value | numberDot }} => {{ data.value | numberDot }}
            @if (missingValue) {
              <span class="colorRed">({{ missingValue | numberDot }})</span>
            } @else {
              <span class="colorYellow">({{ data.value - resource.value | numberDot }})</span>
            }
          } @else {
            {{ data.name }}: {{ resource.value | numberDot }} => {{ data.value | numberDot }}
            @if (missingValue) {
              <span class="colorRed">({{ missingValue | numberDot }})</span>
            }
          }
        </ng-container>
      </ng-container>
    </li>
  }
</ul>
