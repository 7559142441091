import {Component} from '@angular/core';
import {UniverseService} from "../../services/http/universe.service";
import {Universe} from 'src/app/models/Universe';
import {Store} from "@ngrx/store";
import {update as updateUniverse} from "../../store/universe";
import {update as updatePlanet} from "../../store/planet";
import {PlanetService} from "../../services/http/planet.service";
import {Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-universe',
  templateUrl: './universe.selection.component.html',
  styleUrls: ['./universe.selection.component.scss']
})
export class UniverseSelectionComponent {

  universes: Universe[] = [];

  constructor(
    private readonly universeService: UniverseService,
    private readonly planetService: PlanetService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly auth: AuthService
  ) {
    this.getUniverse();

  }

  getUniverse() {
    this.universeService.getUniverses().subscribe(data => {
      this.universes = data;
    });
  }

  selectUniverse(universe: Universe) {
    this.store.dispatch(updateUniverse({universe}));
    this.planetService.getLastActivePlanet().subscribe(planet => {
      this.store.dispatch(updatePlanet({planet}));
      let route = ['universe', universe.id, 'planet', planet.id, 'overview'];
      this.router.navigate(route);
    })
  }

  getMaintenanceMessage(maintenance_message: string) {
    let jsonMessage = JSON.parse(maintenance_message);

    return jsonMessage[this.auth.userLocale];
  }
}
