<ngx-loading-bar [includeSpinner]="false" color="#FFD54F"></ngx-loading-bar>
<header>
  <div class="container">
    @if (visible) {
      <p-menubar [model]="items">
        <ng-template pTemplate="start">
          <div class="flex">
            <a routerLink="/" class="logo">{{ title }} - {{ version }}</a>
            @if (auth.isLoggedIn()) {
              <span class="pl-3">{{ auth.username }}</span>
            }
          </div>
        </ng-template>
        <ng-template pTemplate="end">
          @if (auth.isLoggedIn()) {
            <div class="w-full">
              <p-button styleClass="p-button-rounded p-button-danger" icon="pi pi-power-off"
                        (click)="auth.logout()"></p-button>
            </div>
          } @else {
            <a routerLink="/login">Login</a>

          }
        </ng-template>
      </p-menubar>
    }
  </div>
</header>
<p-toast/>
<p-toast position="top-left" key="updateVersion"/>
<router-outlet/>
