<div class="flex justify-content-center mt-8">
  @for (universe of universes; track universe.id) {
    <p-card [header]="universe.name" [subheader]="'Nombre de joueur: ' + universe.universe_accounts_count">
      {{universe.flags}}
      <div>
        <p>Date d'ouverture: {{universe.created_at | date:'yyyy-MM-dd'}}</p>
        <ul style="list-style: none">
          <li>Build speed: {{universe.build_speed}}</li>
          <li>Research speed: {{universe.research_speed}}</li>
          <li>Fleet speed: {{universe.fleet_speed}}</li>
          <li>Creation speed: {{universe.creation_speed}}</li>
        </ul>
      </div>
      @if (universe.maintenance_mode) {
        <div>
          {{ getMaintenanceMessage(universe.maintenance_message) }}
        </div>
      }

      <ng-template pTemplate="footer">
        <div class="flex justify-content-center">
          <p-button [disabled]="universe.maintenance_mode"
                    [styleClass]="universe.maintenance_mode ? 'p-button-danger' : 'p-button-success' + ' p-button-raised'"
                    (onClick)="selectUniverse(universe)"
                    [label]="universe.maintenance_mode ? 'Maintenance' : (universe.hasAccount ? 'Se connecter' : 'Rejoindre l\'univers')"></p-button>
        </div>
      </ng-template>
    </p-card>
  }
</div>
