import {Injectable} from '@angular/core';
import {BaseHttpService} from "./base-http.service";
import {DatabaseUser} from "../../models/DatabaseUser";
import {BehaviorSubject, catchError, map, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {MessageService} from "primeng/api";
import {Points} from "../../models/Points";
import {UniverseAccountResource} from "../../models/UniverseAccountResource";
import {DefaultMessageResponseWithData} from "../../models/DefaultMessageResponse";


@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseHttpService {

  private databaseUserSubject: BehaviorSubject<DatabaseUser | null>;
  public databaseUser: Observable<DatabaseUser | null>;

  constructor(
    protected override http: HttpClient,
    protected override toastService: MessageService
  ) {
    super(http, toastService);
    this.databaseUserSubject = new BehaviorSubject<DatabaseUser | null>(null);
    this.databaseUser = this.databaseUserSubject.asObservable();
  }

  get currentUser() {
    return this.databaseUserSubject.value;
  }

  loadDatabaseUser() {
    this.http.get<DatabaseUser>('api/user/me').pipe(
      catchError(this.handleError<DatabaseUser>('loadDatabaseUser'))
    ).subscribe(user => {
      this.databaseUserSubject.next(user);
    });
  }

  logout() {
    this.databaseUserSubject.next(null);
  }

  finalizeUser(username: string, locale: number) {
    let data = {
      username: username,
      locale: locale
    }
    return this.http.post<DatabaseUser>('api/user/finalize', data).pipe(map(user => {
        this.databaseUserSubject.next(user);
      }),
      catchError(this.handleError<DatabaseUser>('finalizeUser'))
    );
  }

  getAccountPoints() {
    return this.http.get<{ points: Points }>('api/universe/{universeId}/account/me/points').pipe(
      catchError(this.handleError<{ points: Points }>('getAccountPoints', {
        points: {
          building: 0,
          research: 0,
          ship: 0,
          defense: 0
        }
      }))
    );
  }

  getAccountResources() {
    return this.http.get<UniverseAccountResource[]>('api/universe/{universeId}/account/me/resources').pipe(
      catchError(this.handleError<UniverseAccountResource[]>('getAccountResources', []))
    );
  }

  getDailyBonusStatus() {
    return this.http.get<{ is_bonus_available: boolean }>('api/universe/{universeId}/account/me/dailyBonus/status').pipe(
      catchError(this.handleError<{ is_bonus_available: boolean }>('getAccountResources', {is_bonus_available: false}))
    );
  }

  claimDailyBonus() {
    return this.http.post<DefaultMessageResponseWithData>('api/universe/{universeId}/account/me/dailyBonus/claim', {}).pipe(
      catchError(this.handleError<DefaultMessageResponseWithData>('claimDailyBonus'))
    );
  }
}
