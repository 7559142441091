import {Component, Input,  Output, EventEmitter} from '@angular/core';
import {Resource} from "../../../../models/Resource";
import {PlanetResource} from "../../../../models/PlanetResource";
import {select, Store} from "@ngrx/store";
import {planetResourceSelector} from "../../../../store/resource";
import {tap} from "rxjs/operators";
import {ResourcesUtils} from "../../../../utils/ResourcesUtils";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-costs',
  templateUrl: './costs.component.html',
  styleUrls: ['./costs.component.scss']
})
export class CostsComponent {
  @Input() costs: Resource[];
  @Input() count: number = null;
  @Output() isBuyable = new EventEmitter<boolean>();

  resources: PlanetResource[] = null;

  constructor(
    private store: Store,
  ) {
    this.store
      .pipe(
        select(planetResourceSelector),
        tap((resources) => {
          this.resources = resources;
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  getLeftToPayByName(resourceName: string, valueToPay: number): number|null {
    return ResourcesUtils.getLeftToPayByName(this.resources, resourceName, valueToPay);
  }
}
