import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'numberDot'
})
export class NumberDotPipe implements PipeTransform {

  transform(value: number, args?: any): string {
    if (typeof value !== 'number') {
      return value;
    }
    const [integerPart, decimalPart] = Math.floor(value).toFixed(2).split('.');
    return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
}
