import {Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {refresh as refreshPlanetList} from "../../store/planet-list";

@Component({
  selector: 'app-universe',
  templateUrl: './universe.component.html',
  styleUrls: ['./universe.component.scss']
})
export class UniverseComponent implements OnInit {
  constructor(
      private readonly store: Store
  ) {
  }

  ngOnInit() {
    this.store.dispatch(refreshPlanetList());
  }
}
