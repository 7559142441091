import {AfterViewInit, Component, Input, OnInit, viewChild, ViewChild} from '@angular/core';
import {Ship} from "../../../../models/Ship";
import {PlanetResource} from "../../../../models/PlanetResource";
import {BuildingService} from "../../../../services/http/building.service";
import {MessageService, TreeNode} from "primeng/api";
import {select, Store} from "@ngrx/store";
import {planetResourceSelector, refresh as refreshResources} from "../../../../store/resource";
import {tap} from "rxjs/operators";
import {HangarService} from "../../../../services/http/hangar.service";
import {refresh as refreshHangarQueue} from "../../../../store/hangar-queue";
import {OverlayPanel} from "primeng/overlaypanel";
import {ResourcesUtils} from "../../../../utils/ResourcesUtils";
import {BuildingsSelector} from "../../../../store/buildings";
import {RequirementsUtils} from "../../../../utils/RequirementsUtils";
import {ResearchesSelector} from "../../../../store/researches";
import {Building} from "../../../../models/Building";
import {Research} from "../../../../models/Research";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {planetSelector} from "../../../../store/planet";
import {Planet} from "../../../../models/Planet";
import {ShipsSelector} from "../../../../store/ships";
import {DefensesSelector} from "../../../../store/defenses";
import {Defense} from "../../../../models/Defense";

@UntilDestroy()
@Component({
  selector: 'app-ship-card',
  templateUrl: './ship-card.component.html',
  styleUrls: ['./ship-card.component.scss']
})
export class ShipCardComponent implements AfterViewInit {
  @Input() ship: Ship;
  @ViewChild('requirementPanel') requirementPanel: OverlayPanel;

  forceDisable = false;
  resources: PlanetResource[] = null;
  count: number = 1;
  requirementsMet = false;
  isBuyable = false;
  private buildings: Building[] = [];
  private researches: Research[] = [];
  visibleDialog: boolean = false;
  infoTech = [];
  techTree: TreeNode[] = [];
  planet: Planet;
  protected readonly faCircleInfo = faCircleInfo;
  activeIndex: number;
  private shipList: Ship[] = [];
  private defenseList: Defense[] = [];
  rapidFire = [];

  constructor(
    private hangarService: HangarService,
    private toastService: MessageService,
    private store: Store,
  ) {
    this.store
      .pipe(
        select(planetResourceSelector),
        tap((resources) => {
          this.resources = resources;
          if (typeof this.ship !== 'undefined') {
            this.isBuyable = ResourcesUtils.isBuyable(this.resources, this.ship.price);
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store
      .pipe(select(BuildingsSelector), tap((buildings) => {
          this.buildings = buildings;
          if (typeof this.ship !== 'undefined') {
            this.requirementsMet = RequirementsUtils.isRequirementMet(this.buildings, this.researches, this.ship.requirements)
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store
      .pipe(select(ResearchesSelector), tap((researches) => {
          this.researches = researches;
          if (typeof this.ship !== 'undefined') {
            this.requirementsMet = RequirementsUtils.isRequirementMet(this.buildings, this.researches, this.ship.requirements)
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store
      .pipe(
        select(planetSelector),
        tap((planet) => {
          this.planet = planet;
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store
      .pipe(
        select(ShipsSelector),
        tap((shipList) => {
          this.shipList = shipList;
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store
      .pipe(
        select(DefensesSelector),
        tap((defenseList) => {
          this.defenseList = defenseList;
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  formatRapidFire() {
    if (this.rapidFire.length > 0) {
      return;
    }
    if (this.ship?.rapid_fire?.ship.length > 0 && this.shipList.length > 0) {
      this.ship.rapid_fire.ship.forEach((value) => {
        this.rapidFire.push({
          name: this.shipList.find((ship) => ship.id == value.id)?.name ?? value.id,
          value: value.value
        })
      })
    }
    if (this.ship?.rapid_fire?.defense.length > 0 && this.defenseList.length > 0) {
      this.ship.rapid_fire.defense.forEach((value) => {
        this.rapidFire.push({
          name: this.defenseList.find((defense) => defense.id == value.id)?.name ?? value.id,
          value: value.value
        })
      })
    }
  }

  addShipToQueue() {
    this.forceDisable = true;
    this.hangarService.addToQueue('ship', this.ship.id, this.count).subscribe((response) => {
      this.forceDisable = false;
      this.count = 1;
      let severity: string;
      let summary: string;

      if (response.success) {
        severity = 'success';
        summary = 'Success'
        setTimeout(() => this.store.dispatch(refreshHangarQueue()), 250);
        setTimeout(() => this.store.dispatch(refreshResources()), 500);
      } else {
        severity = 'error';
        summary = 'Error'
      }
      this.toastService.add({severity, summary, detail: response.message});
    })
  }

  ngAfterViewInit() {
    if (typeof this.ship !== 'undefined') {
      this.isBuyable = ResourcesUtils.isBuyable(this.resources, this.ship.price);
      this.requirementsMet = RequirementsUtils.isRequirementMet(this.buildings, this.researches, this.ship.requirements)
      this.infoTech = [
        {desc: 'Points de structure', value: this.ship.shell, base: this.ship.base_shell},
        {desc: 'Puissance du bouclier', value: this.ship.shield, base: this.ship.base_shield},
        {desc: 'Valeur d\'attaque', value: this.ship.attack, base: this.ship.base_attack},
        {desc: 'Vitesse', value: this.ship.speed, base: this.ship.base_speed},
        {desc: 'Cargo', value: this.ship.capacity, base: this.ship.capacity}
      ]
      this.ship.production.forEach((value) => {
        if (value.name === 'energy') {
          this.infoTech.push({
            desc: 'Production d\'energy',
            value: (value.value + this.planet.max_temperature) / 6,
            base: (value.value + this.planet.max_temperature) / 6
          })
        }
      });
    }
  }

  getTechTree() {
    return [RequirementsUtils.extractTechTreeBranch(this.ship.requirementTree)];
  }


  onHide() {
    this.techTree = [];
  }

  onShow() {
    this.formatRapidFire();
    this.techTree = this.getTechTree();
  }

  showDialog() {
    this.visibleDialog = true
    this.activeIndex = 0;
  }
}
