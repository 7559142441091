import {Injectable} from '@angular/core';
import {BaseHttpService} from "./base-http.service";
import {Language} from "../../models/Language";
import {catchError, Observable} from "rxjs";
import {CombatReport} from "../../models/CombatReport";
import {TradeRoute} from "../../models/TradeRoute";
import {DefaultMessageResponse} from "../../models/DefaultMessageResponse";
import {Achievement, AchievementCategory} from "../../models/Achievement";
import {AccountAchievement} from "../../models/AccountAchievement";

@Injectable({
  providedIn: 'root'
})
export class AchievementService extends BaseHttpService {
  getAchievements() {
    return this.http.get<AchievementCategory[]>('api/universe/{universeId}/achievements/list').pipe(
      catchError(this.handleError<AchievementCategory[]>('getAchievements', []))
    );
  }

  getAccountAchievements() {
    return this.http.get<AccountAchievement[]>('api/universe/{universeId}/account/me/achievements').pipe(
      catchError(this.handleError<AccountAchievement[]>('getAccountAchievements', []))
    );
  }

  claimAchievement(achievementId: number) {
    return this.http.post<DefaultMessageResponse>('api/universe/{universeId}/achievements/'+achievementId+'/claim', {}).pipe(
      catchError(this.handleError<DefaultMessageResponse>('claimAchievement'))
    );
  }
}
