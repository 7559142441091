<p-table [columns]="cols" [value]="data" styleClass="p-datatable-gridlines">
  <ng-template pTemplate="caption">
    <div class="flex align-items-center justify-content-between">
      <span translate [translateParams]="{name: planet.name}">productionInfo.table.title</span>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      @for (col of columns; track $index) {
        @if (col.field === 'name') {
          <th>{{ col.header }}</th>
        } @else {
          <th class="my-center-text">{{ col.header }}</th>
        }
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      @for (col of columns; track col.field) {
        @if (col.field === 'name') {
          <td>{{ rowData[col.field] | translate }} {{ rowData['level'] ? '(' + rowData['level'] + ')' : '' }}</td>
        } @else {
          <td class="my-center-text">{{ rowData[col.field] | numberDot }}</td>
        }
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="1" class="my-center-text">
        <span translate>common.loading</span>
      </td>
    </tr>
  </ng-template>
</p-table>
