import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PlanetComponent} from "./planet.component";
import {PlanetGuard} from "../../../guards/planet.guard";
import {OverviewComponent} from "./overview/overview.component";
import {BuildingsComponent} from "./buildings/buildings.component";
import {ChatComponent} from "../shared/chat/chat.component";
import {HangarComponent} from "./hangar/hangar.component";
import {LaboratoryComponent} from "./laboratory/laboratory.component";
import {GalaxyComponent} from "./galaxy/galaxy.component";
import {MissionCenterComponent} from "./mission-center/mission-center.component";
import {UsersPointsComponent} from "./users-points/users-points.component";
import {MessagesComponent} from "./messages/messages.component";
import {RecordsComponent} from "./records/records.component";
import {ScrapDealerComponent} from "../shared/scrap-dealer/scrap-dealer.component";
import {AchievementsComponent} from "./achievements/achievements.component";
import {BattleSimulatorComponent} from "../shared/battle-simulator/battle-simulator.component";
import {PlanetInfoComponent} from "./planet-info/planet-info.component";
import {MarketComponent} from "./market/market.component";

const routeList: Routes = [
  {
    path: 'planet/:planetId',
    component: PlanetComponent,
    canActivate: [PlanetGuard],
    children: [
      {
        path: 'overview',
        component: OverviewComponent,
      },
      {
        path: 'buildings',
        component: BuildingsComponent,
      },
      {
        path: 'hangar',
        component: HangarComponent,
      },
      {
        path: 'missionCenter',
        component: MissionCenterComponent,
      },
      {
        path: 'galaxy',
        component: GalaxyComponent,
      },
      {
        path: 'laboratory',
        component: LaboratoryComponent,
      },
      {
        path: 'chat',
        component: ChatComponent,
      },
      {
        path: 'usersPoints',
        component: UsersPointsComponent,
      },
      {
        path: 'messages',
        component: MessagesComponent,
      },
      {
        path: 'records',
        component: RecordsComponent,
      },
      {
        path: 'achievements',
        component: AchievementsComponent,
      },
      {
        path: 'info',
        component: PlanetInfoComponent,
      },
      {
        path: 'market',
        component: MarketComponent,
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routeList)],
  exports: [RouterModule]
})
export class PlanetRoutingModule {
}
