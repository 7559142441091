import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as BuildingsActions from './buildings.actions';
import {BuildingsSelector} from './buildings.selector';
import {exhaustMap, map, switchMap, take, interval, tap, withLatestFrom, Subscription} from "rxjs";
import {BuildingService} from "../../services/http/building.service";
import {Building} from "../../models/Building";
import {cloneDeep} from "lodash-es";
import {RequirementsUtils} from "../../utils/RequirementsUtils";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {REFRESH_ONE_BUILDING} from "./buildings.actions";

@UntilDestroy()
@Injectable()
export class BuildingsEffects {

  buildings: Building[];

  constructor(
    private store: Store,
    private actions$: Actions,
    private buildingService: BuildingService,
  ) {
    this.store
      .pipe(select(BuildingsSelector), tap((buildings) => {
          this.buildings = buildings;
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  refreshBuilding(id: number) {
    this.buildingService.getBuildingById(id).subscribe(building => {
      let buildings = cloneDeep<Building[]>(this.buildings);
      let index = buildings.findIndex(building => building.id === id)
      if (index !== -1) {
        buildings[index] = building;
        this.store.dispatch(BuildingsActions.update({buildings}))
      }
    })
  }


  updateAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BuildingsActions.UPDATE),
        withLatestFrom(this.store.select(BuildingsSelector)),
        // tap(([, universe]) => this.sentryService.setUser(universe)),
      ),
    {dispatch: false},
  );

  refreshAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BuildingsActions.REFRESH),
        exhaustMap(() => this.buildingService.getBuildingList().pipe(
          map(buildings => {
            console.log("reloading buildings");
            return BuildingsActions.update({buildings})
          })
        )),
      ),
  );

  refreshOneBuildingAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BuildingsActions.REFRESH_ONE_BUILDING),
        tap((data: {buildingId: number, type:string}) => {
          this.refreshBuilding(data.buildingId);
        }),
      ),
    {dispatch: false},
  );
}
