@if (columns.length > 0 && values.length > 0) {
  <p-table [columns]="columns" [value]="values" [tableStyle]="{ 'min-width': '50rem' }">
    <ng-template pTemplate="header" let-columns>
      <tr>
        @for (col of columns; track col; let first = $first) {
          @if (!first) {
            <th>{{ col }}</th>
          } @else {
            <th pFrozenColumn>{{ col }}</th>
          }
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        @if (columns.length > rowData.length) {
          <td>-</td>
        }
        @for (data of rowData; track data) {
          <td>{{ data }}</td>
        }
      </tr>
    </ng-template>
  </p-table>

} @else {
  <div class="text-center">
    <span translate>common.loading</span>
  </div>
}
