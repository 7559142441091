import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class BaseHttpService {
  constructor(
    protected http: HttpClient,
    protected toastService: MessageService
  ) {
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error('error', error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // console.log(`${operation} failed: ${error.message} => ${error.status}`);
      if (error.status !== 499) {
        const errorMessage = error.error.message || error.statusText;
        this.toastService.add({severity: 'error', summary: 'Error', detail: errorMessage});
      }

      // Let the app keep running by returning an empty result.
      if (result) {
        return of(result as T);
      }

      if (error.status !== 499) {
        return of(error.error as T);
      } else {
        return of(null as T);
      }
    };
  }
}
