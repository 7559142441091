import {Component} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {planetSelector, update as PlanetUpdate} from "../../../../store/planet";
import {tap} from "rxjs/operators";
import {Planet} from "../../../../models/Planet";
import {cloneDeep} from "lodash-es";
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {PlanetService} from "../../../../services/http/planet.service";
import {refresh as refreshPlanetList} from "../../../../store/planet-list";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-edit-planet',
  templateUrl: './edit-planet.component.html',
  styleUrls: ['./edit-planet.component.scss']
})
export class EditPlanetComponent {
  planet: Planet;
  constructor(
    private store: Store,
    private ref: DynamicDialogRef,
    private planetService: PlanetService
  ) {
    this.store
      .pipe(
        select(planetSelector),
        tap((planet) => {
          this.planet = cloneDeep<Planet>(planet);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  save()
  {
    this.planetService.patchPlanet({
      name: this.planet.name
    }).subscribe(planet =>{
      if (planet){
        this.store.dispatch(refreshPlanetList());
        this.store.dispatch(PlanetUpdate({planet}));
        this.ref.close();
      }
    })
  }
}
