import {Component, OnDestroy} from '@angular/core';
import {PlanetResource} from "../../../../models/PlanetResource";
import {select, Store} from "@ngrx/store";
import {planetResourceSelector} from "../../../../store/resource";
import {tap} from "rxjs/operators";
import {cloneDeep} from "lodash-es";
import {ResourceType} from "../../../../models/Enum/ResourceType";
import {planetListSelector} from "../../../../store/planet-list";
import {Planet} from "../../../../models/Planet";
import {planetSelector} from "../../../../store/planet";
import {universeAccountResourceSelector} from "../../../../store/universe-account-resource";
import {ReloadService} from "../../../../services/ReloadService";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {DialogService} from "primeng/dynamicdialog";
import {EditPlanetComponent} from "../../shared/edit-planet/edit-planet.component";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {UniverseAccountResource} from "../../../../models/UniverseAccountResource";

@UntilDestroy()
@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent {
  resources: PlanetResource[] = null;
  planets: Planet[] = [];
  currentPlanet: Planet = null;
  selectedPlanet: Planet = null;
  universeAccountResource: UniverseAccountResource[];

  constructor(
    private store: Store,
    private reloadService: ReloadService,
    private dialogService: DialogService
  ) {
    this.store
      .pipe(
        select(planetResourceSelector),
        tap((resources) => {
          this.resources = cloneDeep<PlanetResource[]>(resources);
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store
      .pipe(
        select(planetSelector),
        tap((planet) => {
          this.currentPlanet = planet;
          this.updateSelectedPlanet();
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store
      .pipe(
        select(planetListSelector),
        tap(planets => {
          this.planets = [];
          planets.forEach((planet) => {
            this.planets.push(Planet.formatPlanet(planet));
          });
          this.updateSelectedPlanet();
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store
      .pipe(
        select(universeAccountResourceSelector),
        tap((universeAccountResource) => {
          this.universeAccountResource = cloneDeep<UniverseAccountResource[]>(universeAccountResource);
        }),
        untilDestroyed(this),
      ).subscribe();
  }

  editPlanet() {
    this.dialogService.open(EditPlanetComponent, {header: 'Edit Planet'});
  }

  updateSelectedPlanet() {
    if (null !== this.currentPlanet && this.planets.length > 0) {
      this.selectedPlanet = this.planets.find(planet => this.currentPlanet.id == planet.id);
    }
  }

  onPlanetChangeEvent(event) {
    if (null !== event.value) {
      this.reloadService.navigateToPlanet(event.value);
    }
  }

  get buildResources(): PlanetResource[] {
    if (!this.resources) {
      return [];
    }

    return this.resources.filter((resource) => {
      return resource.type === ResourceType.BUILD;
    })
  }

  get energyResources(): PlanetResource[] {
    if (!this.resources) {
      return [];
    }

    return this.resources.filter((resource) => {
      return resource.type === ResourceType.ENERGY;
    })
  }

  protected readonly faPenToSquare = faPenToSquare;
}
