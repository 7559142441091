import {Injectable} from '@angular/core';
import {BaseHttpService} from "./base-http.service";
import {catchError, Observable} from "rxjs";
import {DefaultMessageResponse} from "../../models/DefaultMessageResponse";
import {Research} from "../../models/Research";
import {ResearchQueue} from "../../models/ResearchQueue";

@Injectable({
  providedIn: 'root'
})
export class ResearchService extends BaseHttpService {

  getResearchList() {
    return this.http.get<Research[]>('api/universe/{universeId}/planet/{planetId}/researches/list').pipe(
      catchError(this.handleError<Research[]>('getResearchList', []))
    );
  }

  getResearchQueue() {
    return this.http.get<ResearchQueue[]>('api/universe/{universeId}/planet/{planetId}/researches/queue').pipe(
      catchError(this.handleError<ResearchQueue[]>('getResearchQueue', []))
    );
  }

  addToQueue(research: Research): Observable<DefaultMessageResponse> {
    return this.http.post<DefaultMessageResponse>('api/universe/{universeId}/planet/{planetId}/researches/addToQueue', {research_id: research.id}).pipe(
      catchError(this.handleError<DefaultMessageResponse>('addResearchToQueue'))
    );
  }

  removeFromQueue(queueId): Observable<DefaultMessageResponse> {
    return this.http.post<DefaultMessageResponse>('api/universe/{universeId}/planet/{planetId}/researches/removeFromQueue', {id: queueId}).pipe(
      catchError(this.handleError<DefaultMessageResponse>('removeResearchFromQueue'))
    );
  }

}
