import {Observable, Subject, Subscription, tap} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {Planet} from "../models/Planet";
import {update as updatePlanet} from "../store/planet";
import {Store} from "@ngrx/store";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";


export interface Reload {
    reload(): void;
}

export interface ReloadAfterPlanetChanged {
    readonly planetChangedSub: Subscription;
}

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class ReloadService {
    private readonly navigationSubject$ = new Subject<void>();

    private currentPlanetId?: number;
    private readonly planetChangedSubject$ = new Subject<void>();

    /**
     * This Observable can still be used for views that can be updated and do not rely on url or query params
     * (mostly components that rely on the stored selected planet)
     */
    get navigationSubject(): Observable<void> {
        return this.navigationSubject$.asObservable();
    }

    /**
     * This Observable can be used for views that needs to be updated after the selected planet has changed.
     * As it is updated after the route has been updated it is safe to use for views such as lists which rely on query params.
     */
    get planetChanged(): Observable<void> {
        return this.planetChangedSubject$.asObservable();
    }

    constructor(
        private router: Router,
        private store: Store
    ) {
        this.currentPlanetId = this.getPlanetIdFromUrl();
        router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .pipe(
                tap(() => {
                    const currentPlanetId = this.getPlanetIdFromUrl();
                    console.log('current', this.currentPlanetId, currentPlanetId)
                    if (this.currentPlanetId !== currentPlanetId) {
                        this.currentPlanetId = currentPlanetId;
                        this.planetChangedSubject$.next();
                    }
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }

    private getPlanetIdFromUrl(){
        const url = this.router?.url || '';
        if (/^\/universe\/\d+\/planet\/\d+\//.test(url)) {
            const planetIdString = url.split('/planet/').pop()?.split('/')?.shift();
            return +planetIdString;
        }
        return null;
    }

    navigateToPlanet(planet: Planet){
        const currentUrl = this.router.url;
        console.log(currentUrl);
        const redirectUrl = currentUrl.replace(/\/planet\/\d+\//, `\/planet\/${planet.id}\/`);
        console.log(redirectUrl);
        this.store.dispatch(updatePlanet({ planet}));
        void this.router.navigate([redirectUrl], {queryParams: {}});
    }

    reloadCurrentView(): void {
        this.navigationSubject$.next();
    }
}
