import {Injectable} from '@angular/core';
import {BaseHttpService} from "./base-http.service";
import {catchError, Observable} from "rxjs";
import {DefaultMessageResponse, DefaultMessageResponseWithData} from "../../models/DefaultMessageResponse";
import {GetAvailableMissionRequest} from "../../models/requests/GetAvailableMissionRequest";
import {FlyingFleet} from "../../models/FlyingFleet";
import {CheckSendFleetRequest} from "../../models/requests/CheckSendFleetRequest";

@Injectable({
  providedIn: 'root'
})
export class FleetService extends BaseHttpService {

  getFlyingFleets() {
    return this.http.get<FlyingFleet>('api/universe/{universeId}/galaxy/{universeAccountId}/fleets'
    ).pipe(
      catchError(this.handleError<FlyingFleet>('getFlyingFleets', {own: [], coming: []}))
    );
  }

  getAvailableMission(getAvailableMissionRequest: GetAvailableMissionRequest) {
    return this.http.post<string[]>('api/universe/{universeId}/planet/{planetId}/ships/getAvailableMission',
      getAvailableMissionRequest
    ).pipe(
      catchError(this.handleError<string[]>('getAvailableMission', []))
    );
  }

  checkSendFleet(checkSendFleetRequest: CheckSendFleetRequest) {
    return this.http.post<DefaultMessageResponseWithData>('api/universe/{universeId}/planet/{planetId}/ships/checkSendFleet',
      checkSendFleetRequest
    ).pipe(
      catchError(this.handleError<DefaultMessageResponseWithData>('checkSendFleet'))
    );
  }

  sendFleet(checkSendFleetRequest: CheckSendFleetRequest): Observable<DefaultMessageResponse> {
    return this.http.post<DefaultMessageResponse>('api/universe/{universeId}/planet/{planetId}/ships/sendFleet',
      checkSendFleetRequest
    ).pipe(
      catchError(this.handleError<DefaultMessageResponse>('sendFleet'))
    );
  }

}
