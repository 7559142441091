import {Component} from '@angular/core';
import {MessageService} from "../../../../services/http/message.service";
import {Message} from "../../../../models/Message";
import {MessageType} from "../../../../models/Enum/MessageType";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {ConfirmationService} from "primeng/api";
import {MessageService as ToastService} from "primeng/api";
import {Store} from "@ngrx/store";
import {MessageCountSelector, refresh as refreshMessageCount} from "../../../../store/messages-count";
import {map} from "rxjs";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {UniverseService} from "../../../../services/http/universe.service";
import {CombatReport} from "../../../../models/CombatReport";
import {BattleService} from "../../../../services/http/battle.service";

@UntilDestroy()
@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent {
  protected readonly faTrash = faTrash;


  private messageCountByType: { type: string; not_read: number; read: number }[];
  messages: Message[] = [];
  messageType: string[] = [];
  selectedCategory: string = null;
  currentPage: number = 0;
  prevPage: string;
  nextPage: string;
  forceDisable: boolean = false;
  showCombatReportDialog = false
  combatReportData: CombatReport = null;
  showSpyReportDialog = false;
  spyReport = null;

  constructor(
    private readonly messageService: MessageService,
    private readonly battleService: BattleService,
    private readonly universeService: UniverseService,
    private readonly confirmationService: ConfirmationService,
    private readonly toastService: ToastService,
    private readonly store: Store,
  ) {
    Object.values(MessageType).forEach((value) => {
      this.messageType.push(value)
    })
    store.select(MessageCountSelector).pipe(
      map((messagesCount) => this.messageCountByType = messagesCount),
      untilDestroyed(this),
    ).subscribe();
    this.store.dispatch(refreshMessageCount());
  }

  getMessageCountByType(type: string): { type: string; not_read: number; read: number } {
    let data = {
      type,
      not_read: 0,
      read: 0
    };
    this.messageCountByType?.forEach(value => {
      if (value.type === type) {
        data = value;
      }
    });
    return data;
  }

  getMessageListByType(type: string, page: number) {
    this.forceDisable = true;
    this.messageService.list(type, page).subscribe(paginatedMessage => {
      this.prevPage = paginatedMessage.prev_page_url;
      this.nextPage = paginatedMessage.next_page_url;
      this.currentPage = paginatedMessage.current_page;
      this.messages = paginatedMessage.data;
      this.selectedCategory = type;
      this.forceDisable = false;
    });
  }

  getDisplayableDate(date: string) {
    return (new Date(date)).toLocaleString();
  }

  markMessageAsRead(collapsedChange: boolean, id: string) {
    if (collapsedChange === true) {
      return;
    }
    let index = this.messages.findIndex(message => message.id === id);
    if (typeof this.messages[index].read_at === 'string') {
      return;
    }
    this.messageService.markMessageAsRead(id).subscribe(message => {
      this.messages[index].read_at = message.read_at;
      this.store.dispatch(refreshMessageCount());
    });
  }

  markTypeAsRead() {
    this.confirmationService.confirm({
      header: 'Marquer tous les messages comme lu ?',
      message: 'Marquer tous les messages de la category ' + this.selectedCategory + ' comme lu ?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.messageService.markTypeAsRead(this.selectedCategory).subscribe(response => {
          this.getMessageListByType(this.selectedCategory, 1);
          this.store.dispatch(refreshMessageCount());
          this.toastService.add({severity: 'success', summary: 'Success', detail: response.message});
        });
      },
      reject: () => {
      }
    });
  }

  deleteAllMessageFromType() {
    this.confirmationService.confirm({
      header: 'Supprimer tout les messages ?',
      message: 'Supprimer tout les message de la category ' + this.selectedCategory,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.messageService.deleteMessagesOfType(this.selectedCategory).subscribe(response => {
          this.getMessageListByType(this.selectedCategory, 1);
          this.store.dispatch(refreshMessageCount());
          this.toastService.add({severity: 'success', summary: 'Success', detail: response.message});
        });
      },
      reject: () => {
      }
    });
  }

  deleteMessage(event: MouseEvent, messageToDelete: Message) {
    event.stopPropagation();
    event.preventDefault();
    event.stopImmediatePropagation();
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      header: 'Supprimer le message ?',
      message: this.getDisplayableDate(messageToDelete.created_at) + ' | ' + messageToDelete.subject,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        let index = this.messages.findIndex(message => message.id === messageToDelete.id);
        this.messageService.deleteMessage(messageToDelete.id).subscribe(response => {
          this.messages.splice(index, 1);
          this.store.dispatch(refreshMessageCount());
          this.toastService.add({severity: 'success', summary: 'Success', detail: response.message});
        });
      },
      reject: () => {
      }
    });
  }

  openCombatReport(combatReportId: string) {
    this.showCombatReportDialog = true;
    this.battleService.getCombatReport(combatReportId).subscribe(response => {
      this.combatReportData = response;
    })
  }

  openSpyReport(spyReportId: string) {
    this.showSpyReportDialog = true;
    this.universeService.getSpyReport(spyReportId).subscribe((spyReport) => {
      this.spyReport = spyReport;
    });
  }
}
