import {Injectable} from '@angular/core';
import {AngularFireMessaging} from "@angular/fire/compat/messaging";
import {Observable} from "rxjs";
import {trace} from "@angular/fire/compat/performance";
import {tap} from "rxjs/operators";
import {DeviceTokenService} from "./http/device-token.service";

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  currentToken: string = null;
  token$: Observable<any>;
  message$: Observable<any>;

  constructor(
    private readonly messaging: AngularFireMessaging,
    private readonly deviceTokenService: DeviceTokenService
  ) {
    this.message$ = messaging.messages;
    this.token$ = messaging.tokenChanges.pipe(
      trace('token'),
      tap(token => token)
    );
  }

  public init() {
    if (null === this.currentToken) {
      this.messaging.requestPermission.subscribe({
        next: response => {
          console.log('Permission granted!')
          this.token$.subscribe(token => {
            console.log('fcm token', token)
            this.currentToken = token;
            this.deviceTokenService.addToken(token).subscribe();
          });
        },
        error: err => console.log('Permission denied!', err)
      });
    }
  }
}
