export class Records {
  public buildings: Record[] | undefined;
  public researches: Record[] | undefined;
}

export class Record {
  public name: string | undefined;
  public level: number | undefined;
  public users: string[] | undefined;
}
