import {ShipCountList} from "./ShipCountList";
import {Target} from "./Target";
import {Resource} from "../Resource";

export class CheckSendFleetRequest{
    mission: string;
    ships: ShipCountList[];
    target_model: string;
    target: Target;
    speed_factor: number;
    cargo: Resource[];
    stay_duration: number = 0;
}
