<p-button (onClick)="visibleDialog = true" label="Ajouter une route de transport"></p-button>
<p-dialog header="Ajouter une route de transport" [(visible)]="visibleDialog">
  <div style="min-height: 30vh;min-width: 25vw">
    <app-trade-route-card [isCreation]="true" (created)="tradeRouteCreated()" />
  </div>
</p-dialog>
<div class="trade-route-list">
  @for (tradeRoute of tradeRoutes; track tradeRoute.id) {
    <div>
      <app-trade-route-card [tradeRoute]="tradeRoute" />
    </div>
  }
</div>
