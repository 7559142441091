import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {PlanetResource} from "../../../../models/PlanetResource";
import {MessageService, TreeNode} from "primeng/api";
import {select, Store} from "@ngrx/store";
import {planetResourceSelector, refresh as refreshResources} from "../../../../store/resource";
import {tap} from "rxjs/operators";
import {HangarService} from "../../../../services/http/hangar.service";
import {refresh as refreshHangarQueue} from "../../../../store/hangar-queue";
import {OverlayPanel} from "primeng/overlaypanel";
import {ResourcesUtils} from "../../../../utils/ResourcesUtils";
import {BuildingsSelector} from "../../../../store/buildings";
import {RequirementsUtils} from "../../../../utils/RequirementsUtils";
import {ResearchesSelector} from "../../../../store/researches";
import {Building} from "../../../../models/Building";
import {Research} from "../../../../models/Research";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Defense} from "../../../../models/Defense";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {Ship} from "../../../../models/Ship";
import {ShipsSelector} from "../../../../store/ships";
import {DefensesSelector} from "../../../../store/defenses";

@UntilDestroy()
@Component({
  selector: 'app-defense-card',
  templateUrl: './defense-card.component.html',
  styleUrls: ['./defense-card.component.scss']
})
export class DefenseCardComponent implements AfterViewInit {
  @Input() defense: Defense;
  @ViewChild('requirementPanel') requirementPanel: OverlayPanel;

  forceDisable = false;
  resources: PlanetResource[] = null;
  count: number = 1;
  requirementsMet = false;
  isBuyable = false;
  private buildings: Building[] = [];
  private researches: Research[] = [];
  visibleDialog: boolean = false;
  infoTech = [];
  techTree: TreeNode[] = [];
  protected readonly faCircleInfo = faCircleInfo;
  activeIndex: number;
  private shipList: Ship[] = [];
  private defenseList: Defense[] = [];
  rapidFire = [];

  constructor(
    private hangarService: HangarService,
    private toastService: MessageService,
    private store: Store,
  ) {
    this.store
      .pipe(
        select(planetResourceSelector),
        tap((resources) => {
          this.resources = resources;
          if (typeof this.defense !== 'undefined') {
            this.isBuyable = ResourcesUtils.isBuyable(this.resources, this.defense.price);
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store
      .pipe(select(BuildingsSelector), tap((buildings) => {
          this.buildings = buildings;
          if (typeof this.defense !== 'undefined') {
            this.requirementsMet = RequirementsUtils.isRequirementMet(this.buildings, this.researches, this.defense.requirements)
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store
      .pipe(select(ResearchesSelector), tap((researches) => {
          this.researches = researches;
          if (typeof this.defense !== 'undefined') {
            this.requirementsMet = RequirementsUtils.isRequirementMet(this.buildings, this.researches, this.defense.requirements)
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store
      .pipe(
        select(ShipsSelector),
        tap((shipList) => {
          this.shipList = shipList;
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store
      .pipe(
        select(DefensesSelector),
        tap((defenseList) => {
          this.defenseList = defenseList;
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  formatRapidFire() {
    if (this.rapidFire.length > 0) {
      return;
    }
    if (this.defense?.rapid_fire?.ship.length > 0 && this.shipList.length > 0) {
      this.defense.rapid_fire.ship.forEach((value) => {
        this.rapidFire.push({
          name: this.shipList.find((ship) => ship.id == value.id)?.name ?? value.id,
          value: value.value
        })
      })
    }
    if (this.defense?.rapid_fire?.defense.length > 0 && this.defenseList.length > 0) {
      this.defense.rapid_fire.defense.forEach((value) => {
        this.rapidFire.push({
          name: this.defenseList.find((defense) => defense.id == value.id)?.name ?? value.id,
          value: value.value
        })
      })
    }
  }

  get isDisabled() {
    return !this.isBuyable
      || !this.requirementsMet
      || this.count < 1
      || null === this.count
      || this.forceDisable
      || this.defense.count === this.defense.max_count
  }

  addDefenseToQueue() {
    this.forceDisable = true;
    this.hangarService.addToQueue('defense', this.defense.id, this.count).subscribe((response) => {
      this.forceDisable = false;
      this.count = 1;
      let severity: string;
      let summary: string;

      if (response.success) {
        severity = 'success';
        summary = 'Success'
        this.store.dispatch(refreshHangarQueue())
        this.store.dispatch(refreshResources())
      } else {
        severity = 'error';
        summary = 'Error'
      }
      this.toastService.add({severity, summary, detail: response.message});
    })
  }

  ngAfterViewInit() {
    if (typeof this.defense !== 'undefined') {
      this.isBuyable = ResourcesUtils.isBuyable(this.resources, this.defense.price);
      this.requirementsMet = RequirementsUtils.isRequirementMet(this.buildings, this.researches, this.defense.requirements)
      this.infoTech = [
        {desc: 'Points de structure', value: this.defense.shell, base: this.defense.base_shell},
        {desc: 'Puissance du bouclier', value: this.defense.shield, base: this.defense.base_shield},
        {desc: 'Valeur d\'attaque', value: this.defense.attack, base: this.defense.base_attack}
      ]
    }
  }

  getTechTree() {
    return [RequirementsUtils.extractTechTreeBranch(this.defense.requirementTree)];
  }


  onHide() {
    this.techTree = [];
  }

  onShow() {
    this.techTree = this.getTechTree();
    this.formatRapidFire();
  }

  showDialog() {
    this.visibleDialog = true
    this.activeIndex = 0;
  }
}
