@if (showHeader && (flyingFleet?.own.length > 0 || flyingFleet?.coming.length > 0)) {
  <div>
    Mouvement de flotte
  </div>
}
<p-table [value]="flyingFleet?.own" [tableStyle]="{ 'min-width': '50rem' }">
  <ng-template pTemplate="body" let-data>
    <ng-container *tldLet="(data | cast : Fleet) as fleet">
      <tr>
        <td>{{ fleet.mission_type }}</td>
        <td [pTooltip]="unitTemplate" tooltipPosition="top" class="cursor-pointer">units</td>
        <td [pTooltip]="cargoTemplate" tooltipPosition="top" class="cursor-pointer">cargo</td>
        <td>{{ fleet.mission_status }}</td>
        <td>{{ formatPosition(fleet) }}</td>
        <td>{{ FleetUtils.getRemainingTime(fleet, true) | secondToDHMS }}</td>
      </tr>
      <ng-template #unitTemplate>
        <p-table [value]="fleet.formattedUnit">
          <ng-template pTemplate="body" let-unit>
            <tr>
              <td>{{ unit.name }}</td>
              <td>{{ unit.count }}</td>
            </tr>
          </ng-template>
        </p-table>
      </ng-template>
      <ng-template #cargoTemplate>
        <p-table [value]="MissingStatus.RETURNING === fleet.mission_status ? fleet.returning_cargo : fleet.cargo">
          <ng-template pTemplate="body" let-cargo>
            <tr>
              <td>{{ cargo.name }}</td>
              <td>{{ cargo.value | numberDot }}</td>
            </tr>
          </ng-template>
        </p-table>
      </ng-template>
    </ng-container>
  </ng-template>
</p-table>
<p-table [value]="flyingFleet?.coming" [tableStyle]="{ 'min-width': '50rem' }">
  <ng-template pTemplate="body" let-data>
    <ng-container *tldLet="(data | cast : Fleet) as fleet">
      <tr>
        <td>{{ fleet.mission_type }}</td>
        <td>{{ fleet.mission_status }}</td>
        <td>{{ formatPosition(fleet) }}</td>
        <td>{{ FleetUtils.getRemainingTime(fleet, true) | secondToDHMS }}</td>
      </tr>
    </ng-container>
  </ng-template>
</p-table>
