@if (laboratory && laboratory.level > 0) {
  <div>
    <app-research-queue></app-research-queue>
    <div class="list">
      @for (research of researchList; track research.id) {
        <div>
          <app-research-card [research]="research"></app-research-card>
        </div>
      }
    </div>
  </div>
} @else {
  <div class="flex text-center justify-content-center">
    <span>
      <span translate [translateParams]="{name: 'building.list.laboratory.name' | translate}">common.missing_on_planet</span>
    </span>
  </div>
}
