import { Component } from '@angular/core';
import {UniverseService} from "../../../../services/http/universe.service";
import {Store} from "@ngrx/store";
import {planetSelector} from "../../../../store/planet";
import {universeSelector} from "../../../../store/universe";
import {Universe} from "../../../../models/Universe";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-galaxy',
  templateUrl: './galaxy.component.html',
  styleUrls: ['./galaxy.component.scss']
})
export class GalaxyComponent {

  dataToDisplay = [];

  x:number;
  y:number
  planet:number;
  universe: Universe;
  constructor(
    private store: Store,
    private universeService: UniverseService,
  ) {
    this.store.select(universeSelector).pipe(untilDestroyed(this)).subscribe((universe) => {
      this.universe = universe;
      this.initList();
    })
    this.store.select(planetSelector).pipe(untilDestroyed(this)).subscribe((planet) => {
      this.x = planet.system_x;
      this.y = planet.system_y;
      this.planet = planet.id;
      this.getSystemData();
    })
  }

  initList() {
    this.dataToDisplay = [];
    for (let i = 0; i < this.universe.max_planet_per_system; i++) {
      this.dataToDisplay.push({
        system_x: this.x,
        system_y: this.y,
        system_position: i+1,
        name: '',
        owner_name: '',
      });
    }
  }

  getSystemData() {
    this.universeService.getPlanetInSystem(this.x, this.y, this.planet).subscribe((planets) => {
      this.initList();
      for (let i = 0; i < this.universe.max_planet_per_system; i++) {
        let planet = planets.find((planet) => planet.system_position == i+1);
        console.log(i, planet);
        if (typeof planet !== 'undefined'){
          this.dataToDisplay[i] = planet
        }
      }
    });
  }
}
