import {Component, OnDestroy, OnInit} from '@angular/core';
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {AuthService} from "../../../../services/auth.service";
import {Store} from "@ngrx/store";
import {selectedUniverseIdSelector} from "../../../../store/universe";
import firebase from 'firebase/compat/app';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {

  messageToSend: string = '';
  messageRef: firebase.database.Reference;
  usersOnlineRef: firebase.database.Reference;
  onlineRef: firebase.database.Reference;
  onlineUser = [];
  messages = [];

  constructor(
    private authService: AuthService,
    private messageDB: AngularFireDatabase,
    private store: Store,
  ) {

  }

  ngOnInit(): void {
    this.store.select(selectedUniverseIdSelector).pipe(untilDestroyed(this)).subscribe((universeId) => {
      let domain = window.location.hostname.replaceAll('.', '_');
      this.messageRef = this.messageDB.database.ref(domain + "/chat/" + universeId + "/_global/_messages");
      this.usersOnlineRef = this.messageDB.database.ref(domain + "/chat/" + universeId + "/_global/_online");
      this.onlineRef = this.messageDB.database.ref(domain + "/chat/" + universeId + "/_global/_online/" + this.authService.userId);
      this.initMessage();
    });

  }

  initMessage() {
    this.usersOnlineRef.on('child_added', data => {
      this.onlineUser.push({username: data.val(), id: data.key});
    });
    this.usersOnlineRef.on('child_removed', data => {
      this.onlineUser.splice(this.onlineUser.indexOf(this.onlineUser.find(user => user.id === data.key)), 1);
    });

    this.messageRef.on('child_added', data => {
      let messageData = data.val()
      this.messages.push({
        user: messageData.username,
        text: messageData.message,
        date: messageData.timestamp,
        id: data.key
      });
    });
    this.messageRef.on('child_removed', data => {
      this.messages.splice(this.messages.indexOf(this.messages.find(message => message.id === data.key)), 1);
    });
    this.messageRef.on('child_changed', data => {
      let messageData = data.val()
      this.messages[this.messages.indexOf(this.messages.find(message => message.id === data.key))].text = messageData.message;
    })
    this.onlineRef.set(this.authService.username).then(() => console.log('Online presence set'));
    this.onlineRef.onDisconnect().remove().then(() => console.log('Disconnect function configured'));
  }

  sendMessage($event) {
    if ($event.keyCode === 13 && !$event.altKey && !$event.ctrlKey && !$event.shiftKey) {
      let message = this.messageToSend.replace(/\n$/, "").trim()
      if (message.length > 0) {
        let messageObject = {
          message: message,
          username: this.authService.username,
          user: this.authService.userId,
          timestamp: firebase.database.ServerValue.TIMESTAMP,
        };
        console.log(messageObject);
        this.messageRef.push(messageObject).then(() => {
          this.messageToSend = ''
        }).catch((error) => {
            console.log(error)
          }
        );
      }
    }
  }

  addPseudoToMessage(pseudo: string) {
    this.messageToSend = this.messageToSend + '@' + pseudo;
  }

  highlightMessage(message) {
    const regex = new RegExp('.*@' + this.authService.username + '.*', 'g');
    return null !== regex.exec(message);
  }

  ngOnDestroy(): void {
    this.onlineRef.remove();
  }
}
