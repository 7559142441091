<div class="mr-8 ml-8">
  <form [formGroup]="sendFleetForm">
    <div>
      <div>
        <span>Ships:</span>
      </div>
      <div formArrayName="shipToSend" class="flex flex-wrap">
        @for (shipGroup of shipToSend.controls; track shipGroup.value.ship;let i=$index) {
          <div class="flex flex-column pr-2">
            <ng-container *tldLet="getShipFromId(shipGroup.value.ship) as ship">
              <span>{{ship.name}} ({{ship.count}})</span>
              <p-inputNumber inputId="shipToSend-{{i}}" [formControl]="shipGroup.controls.count" mode="decimal"
                             [min]="0" [max]="ship.count"></p-inputNumber>
            </ng-container>
          </div>
        }
      </div>
    </div>

    <div>
      <div>
        <span>Target:</span>
        <div>
          <p-inputNumber formControlName="targetSystemX" inputId="targetSystemX" mode="decimal"
                         [min]="-universe.max_system_x" [max]="universe.max_system_x"></p-inputNumber>
          <p-inputNumber formControlName="targetSystemY" inputId="targetSystemY" mode="decimal"
                         [min]="-universe.max_system_y" [max]="universe.max_system_y"></p-inputNumber>
          <p-inputNumber formControlName="targetSystemPosition" inputId="targetSystemPosition" mode="decimal"
                         [min]="1" [max]="universe.max_planet_per_system"></p-inputNumber>
          <p-dropdown formControlName="targetModel" [options]="availableTarget"></p-dropdown>
        </div>
      </div>
      <div class="pt-3">
        <span>Your planets:</span>
        <div class="pt-2">
          @for (planet of planets; track planet.id) {
            <span class="cursor-pointer ml-2" (click)="setPlanetCoordinates(planet)"
                  [class]="{'colorYellow': planet.system_x === sendFleetForm.value.targetSystemX && planet.system_y === sendFleetForm.value.targetSystemY && planet.system_position === sendFleetForm.value.targetSystemPosition}">
          <ng-container *tldLet="Planet.formatPlanet(planet) as planet">
            {{planet.displayName}}
          </ng-container>
        </span>
          }
        </div>
      </div>
    </div>
    <div class="pt-3">
      <div>
            <span>
            Speed Factor:
            </span>
      </div>
      <div>
        <input type="text" readonly pInputText [value]="sendFleetForm.value.speedFactor" class="text-center"/>
        <p-slider formControlName="speedFactor" [min]="1" [max]="100"></p-slider>
      </div>
    </div>
    <br>
    <br>
    <div class="flex flex-column gap-3">
      Available mission:
      @for (mission of availableMission; track mission) {
        <div class="field-checkbox">
          <p-radioButton formControlName="mission" [inputId]="mission" name="mission"
                         [value]="mission"></p-radioButton>
          <label [for]="mission" class="ml-2">{{ mission }}</label>
        </div>
      }
    </div>
    <br>
    @if (0 !== duration) {
      <div class="flex flex-column">
        <div>Duration: {{duration | secondToDHMS}}</div>
        <div>Reach: {{reachDate.toLocaleString()}}</div>
        <div>Return: {{returnDate.toLocaleString()}}</div>
        <div>
          @for (consumption of consumptions; track consumption.name) {
            <ul>
              <li>{{consumption.name}}:{{consumption.value}}</li>
            </ul>
          }
        </div>
      </div>
    }
    <br>
    @if (0 !== duration && sendFleetForm.value.mission !== MissionType.RECYCLE) {
      <div>
        <div>
        <span>Cargo: (<span
          [class]="{'colorRed':0 > availableCargo}">{{availableCargo | numberDot}}</span>/{{cargoCapacity | numberDot}}
          )</span>
        </div>
        <div formArrayName="cargo" class="flex flex-wrap pt-3">
          @for (resourceGroup of cargoToSend.controls; track resourceGroup.value.slug; let i=$index) {
            <div>
              <div class="flex flex-column pr-2 pb-5">
                <ng-container *tldLet="getResourceFromSlug(resourceGroup.value.slug) as resource">
                  <div class="flex align-items-center justify-content-center ">
                    <span class="capitalize vertical-align-middle pr-3">{{resource.slug}}</span>
                    <p-button size="small" severity="success" [rounded]="true" (onClick)="fillToMax(resourceGroup, resource)" label="fill to max"></p-button>
                  </div>
                  <ng-container *tldLet="getMaxAvailableCargo(resource) as maxValue">
                    <p-inputNumber inputId="cargo-{{i}}" [formControl]="resourceGroup.controls.value" mode="decimal"
                                   [min]="0" [max]="maxValue"></p-inputNumber>
                  </ng-container>

                </ng-container>
              </div>
            </div>
          }
        </div>
      </div>
    } @else if (0 !== duration && sendFleetForm.value.mission === MissionType.RECYCLE) {
      <div>
        <div>
          <span>Cargo: {{cargoCapacity | numberDot}}</span>
        </div>
      </div>
    }
  </form>
  <br>
  <p-button (onClick)="sendFleet()" label="Send fleet"
            [disabled]="!canSendFleet || !canSendCheckSendFleet() || forceDisable"></p-button>
  <br>
</div>
