import { Component } from '@angular/core';

@Component({
  selector: 'app-mission-center',
  templateUrl: './mission-center.component.html',
  styleUrls: ['./mission-center.component.scss']
})
export class MissionCenterComponent {
  showSendFleet: boolean = true;
  showTradeRouteManager: boolean = false;
  showBattleSimulator: boolean = false;
}
