import {Injectable} from '@angular/core';
import {BaseHttpService} from "./base-http.service";
import {Language} from "../../models/Language";
import {catchError, map, Observable} from "rxjs";
import {TranslateLoader} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class LanguageService extends BaseHttpService implements TranslateLoader  {

  getAvailableLanguages(): Observable<Language[]> {
    return this.http.get<Language[]>('api/languages').pipe(
      catchError(this.handleError<Language[]>('getAvailableLanguages', []))
    );
  }

  getTranslation(language: string): Observable<any> {
    return this.http.get(`api/languages/${language}/file`).pipe(
      map((response: JSON) => {
        return response;
      }),
      catchError(this.handleError('getTranslation', {}))
    );
  }
}
