import {Universe} from "../../models/Universe";
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {universeFeatureKey} from "./universe.reducer";


export const universeSelector = createFeatureSelector<Universe>(universeFeatureKey);

export const selectedUniverseIdSelector = createSelector(
  universeSelector,
  (universe: Universe) => universe?.id,
);
