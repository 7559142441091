import {createFeatureSelector, createSelector} from "@ngrx/store";
import {planetFeatureKey} from "./planet.reducer";
import {Planet} from "../../models/Planet";


export const planetSelector = createFeatureSelector<Planet>(planetFeatureKey);

export const selectedPlanetIdSelector = createSelector(
  planetSelector,
  (planet: Planet) => planet?.id,
);
