import { Component } from '@angular/core';
import {UniverseService} from "../../../../services/http/universe.service";

@Component({
  selector: 'app-users-points',
  templateUrl: './users-points.component.html',
  styleUrls: ['./users-points.component.scss']
})
export class UsersPointsComponent {

  usersPoints = [];
  constructor(
      private universeService: UniverseService
  ) {
    universeService.getUsersPoints().subscribe(usersPoints => this.usersPoints = usersPoints)
  }
}
