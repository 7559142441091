import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as ResearchesActions from './researches.actions';
import {ResearchesSelector} from './researches.selector';
import {exhaustMap, map, withLatestFrom} from "rxjs";
import {ResearchService} from "../../services/http/research.service";

@Injectable()
export class ResearchesEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private researchService: ResearchService,
  ) {
  }


  updateAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResearchesActions.UPDATE),
        withLatestFrom(this.store.select(ResearchesSelector)),
        // tap(([, universe]) => this.sentryService.setUser(universe)),
      ),
    {dispatch: false},
  );

  refreshAction$ = createEffect(
      () =>
        this.actions$.pipe(
          ofType(ResearchesActions.REFRESH),
          exhaustMap(() => this.researchService.getResearchList().pipe(
            map(researches => {
              console.log("reloading researches");
              return ResearchesActions.update({researches})
            })
          )),
        ),
    );
}
