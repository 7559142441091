import {Component, Input} from '@angular/core';
import {FleetService} from "../../../../services/http/fleet.service";
import {FlyingFleet} from "../../../../models/FlyingFleet";
import {Fleet, Unit} from "../../../../models/Fleet";
import {MissingStatus} from "../../../../models/Enum/MissingStatus";
import {FleetUtils} from "../../../../utils/FleetUtils";
import {Store} from "@ngrx/store";
import {FlyingFleetsSelector, refresh as refreshFlyingFleets} from "../../../../store/flying-fleets";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ShipsSelector} from "../../../../store/ships";
import {Ship} from "../../../../models/Ship";
import {cloneDeep} from "lodash-es";

@UntilDestroy()
@Component({
  selector: 'app-fleet-table',
  templateUrl: './fleet-table.component.html',
  styleUrls: ['./fleet-table.component.scss']
})
export class FleetTableComponent {
  @Input() showHeader = false;
  flyingFleet: FlyingFleet = null;
  private ships: Ship[];

  constructor(
    private readonly store: Store
  ) {
    this.store.select(FlyingFleetsSelector).pipe(untilDestroyed(this)).subscribe(fleets => {
      let fleetsCopy = cloneDeep<FlyingFleet>(fleets)
      fleetsCopy?.own.map((value, index, array) => {
        array[index] = {
          ...value,
          formattedUnit: this.formatUnit(value)
        }
      })
      console.log(fleetsCopy)
      this.flyingFleet = fleetsCopy;
    });
    this.store.select(ShipsSelector).pipe(untilDestroyed(this)).subscribe(ships => {
      this.ships = ships;
    })
    this.store.dispatch(refreshFlyingFleets());
  }

  formatPosition(fleet: Fleet) {
    let startDisplay = `[${fleet.start_system_x}:${fleet.start_system_y}:${fleet.start_system_position}]`;
    let targetDisplay = `[${fleet.target_system_x}:${fleet.target_system_y}:${fleet.target_system_position}]`;
    let direction = '';
    switch (fleet.mission_status) {
      case MissingStatus.GOING:
        direction = '==>';
        break
      case MissingStatus.STAYING:
        direction = '<==>';
        break
      case MissingStatus.RETURNING:
        direction = '<==';
        break;
    }
    return `${startDisplay} ${direction} ${targetDisplay}`;
  }

  formatUnit(fleet: Fleet,) {
    let data = [];
    switch (fleet.mission_status) {
      case MissingStatus.GOING:
      case MissingStatus.STAYING:
        fleet.units.forEach(value => {
          data.push({
            name: this.ships.find(ship => ship.id === value.ship)?.name ?? value.ship,
            count: value.count
          })
        })
        break
      case MissingStatus.RETURNING:
        fleet.returning_units.forEach(value => {
          data.push({
            name: this.ships.find(ship => ship.id === value.ship)?.name ?? value.ship,
            count: value.count
          })
        })
        break;
    }

    return data;
  }

  protected readonly Fleet = Fleet;
  protected readonly FleetUtils = FleetUtils;
  protected readonly MissingStatus = MissingStatus;
}
