import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as HangarQueueActions from './hangar-queue.actions';
import {HangarQueueSelector} from './hangar-queue.selector';
import {exhaustMap, map, switchMap, take, interval, tap, withLatestFrom, Subscription} from "rxjs";
import {HangarService} from "../../services/http/hangar.service";
import * as ShipsActions from "../ships/ships.actions";
import {HangarQueue} from "../../models/HangarQueue";
import * as ResourceActions from "../resource/planet-resource.actions";

@Injectable()
export class HangarQueueEffects {
    constructor(
        private store: Store,
        private actions$: Actions,
        private hangarService: HangarService,
    ) {
    }

    subscription: Subscription;

    tick() {
        this.subscription = interval(1000).pipe(
            switchMap(() => this.store.select(HangarQueueSelector)),
            take(1),
            tap((currentQueue: HangarQueue[]) => {
                if (!currentQueue || 0 === currentQueue.length) {
                    return;
                }
                let currentItem = currentQueue[0];
                let startDate = new Date(currentItem.starting_date);
                let endDate = new Date(currentItem.ending_date);
                let startTime = startDate.getTime();
                let maxTime = (endDate.getTime() - startDate.getTime())
                let now = new Date();
                let elapsedTime = (now.getTime()) - startTime;
                let remainingTime = (maxTime - elapsedTime) / 1000;
                if (remainingTime <= 0) {
                    setTimeout(() => {
                        this.store.dispatch(ShipsActions.refresh())
                        setTimeout(() => this.store.dispatch(HangarQueueActions.refresh()), 250)
                        setTimeout(() => this.store.dispatch(ResourceActions.refresh()), 250)
                    }, 500)
                }
            }),
            tap(() => this.tick())
        ).subscribe();
    }

    updateAction$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(HangarQueueActions.UPDATE),
                withLatestFrom(this.store.select(HangarQueueSelector)),
                // tap(([, universe]) => this.sentryService.setUser(universe)),
            ),
        {dispatch: false},
    );

    refreshAction$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(HangarQueueActions.REFRESH),
                exhaustMap(() => this.hangarService.getHangarQueue().pipe(
                    map(hangarQueue => {
                        console.log("reloading hangar queue");
                        return HangarQueueActions.update({hangarQueue})
                    })
                )),
                tap(() => {
                    if (!this.subscription) {
                        this.tick();
                    }
                })
            ),
    );
}
