import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {environment} from '../environments/environment';
import {provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService} from '@angular/fire/analytics';
import {provideAuth, getAuth} from '@angular/fire/auth';
import {provideDatabase, getDatabase} from '@angular/fire/database';
import {provideMessaging, getMessaging} from '@angular/fire/messaging';
import {providePerformance, getPerformance} from '@angular/fire/performance';
import {AngularFireModule} from "@angular/fire/compat";
import {firebase, FirebaseUIModule} from "firebaseui-angular";
import {AngularFireAuthModule} from "@angular/fire/compat/auth";
import {LoginComponent} from './components/login/login.component';
import {AccountFinalizeComponent} from './components/account-finalize/account-finalize.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {UniverseSelectionComponent} from './components/universe-selection/universe.selection.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {TokenInterceptor} from "./interceptors/token-interceptor";
import {HomeComponent} from './components/home/home.component';
import {FlagComponent} from './components/utils/flag/flag.component';
import {ReactiveFormsModule} from "@angular/forms";
import {ApiInterceptor} from "./interceptors/api-interceptor";
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from "@ngrx/effects";
import {UniverseEffects, universeReducer} from "./store/universe";
import {PlanetEffects, planetReducer} from "./store/planet";
import {PlanetResourceEffects, planetResourceReducer} from "./store/resource";
import {BuildingsEffects, BuildingsReducer} from "./store/buildings";
import {BuildingQueueEffects, BuildingQueueReducer} from "./store/building-queue";
import {UniverseModule} from "./components/universe/universe.module";
import {ToastModule} from "primeng/toast";
import {ConfirmationService, MessageService} from "primeng/api";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MenubarModule} from "primeng/menubar";
import {NgOptimizedImage} from "@angular/common";
import {CardModule} from "primeng/card";
import {ButtonModule} from "primeng/button";
import {HangarQueueEffects, HangarQueueReducer} from './store/hangar-queue';
import {ShipsEffects, ShipsReducer} from "./store/ships";
import {SharedModule} from "./shared.module";
import {ResearchesEffects, ResearchesReducer} from "./store/researches";
import {ResearchQueueEffects, ResearchQueueReducer} from "./store/research-queue";
import {FlyingFleetsEffects, FlyingFleetsReducer} from "./store/flying-fleets";
import {PlanetListEffects, PlanetListReducer} from "./store/planet-list";
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {DialogService} from "primeng/dynamicdialog";
import {MessageCountEffects, MessageCountReducer} from "./store/messages-count";
import {LoadingBarHttpClientModule} from "@ngx-loading-bar/http-client";
import {DefensesEffects, DefensesReducer} from "./store/defenses";
import {TabView} from "primeng/tabview";
import {UniverseAccountResourceEffect, UniverseAccountResourceReducer} from "./store/universe-account-resource";
import {AngularFireMessagingModule, SERVICE_WORKER, VAPID_KEY} from "@angular/fire/compat/messaging";
import {AccountAchievementEffect, AccountAchievementsReducer} from "./store/account-achievements";
import {TradeRoutesEffect, TradeRoutesReducer} from "./store/trade-routes";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {LanguageService} from "./services/http/language.service";

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // {
    //   scopes: [
    //     'email',
    //   ],
    //   customParameters: {
    //     'auth_type': 'reauthenticate'
    //   },
    //   provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID
    // },
    // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    //{
    //  requireDisplayName: true,
    //  provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
    //},
  ],
  tosUrl: '<your-tos-link>',
  privacyPolicyUrl: '<your-privacyPolicyUrl-link>',
  // credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AccountFinalizeComponent,
    PageNotFoundComponent,
    UniverseSelectionComponent,
    HomeComponent,
    FlagComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    UniverseModule,
    StoreModule.forRoot({
      universe: universeReducer,
      planet: planetReducer,
      planetResource: planetResourceReducer,
      buildings: BuildingsReducer,
      buildingQueue: BuildingQueueReducer,
      hangarQueue: HangarQueueReducer,
      ships: ShipsReducer,
      researches: ResearchesReducer,
      researchQueue: ResearchQueueReducer,
      flyingFleet: FlyingFleetsReducer,
      planets: PlanetListReducer,
      messageCount: MessageCountReducer,
      defenses: DefensesReducer,
      universeAccountResource: UniverseAccountResourceReducer,
      accountAchievement: AccountAchievementsReducer,
      tradeRoutes: TradeRoutesReducer,
    }),
    EffectsModule.forRoot([
      UniverseEffects,
      PlanetEffects,
      PlanetResourceEffects,
      BuildingsEffects,
      BuildingQueueEffects,
      HangarQueueEffects,
      ShipsEffects,
      ResearchesEffects,
      ResearchQueueEffects,
      FlyingFleetsEffects,
      PlanetListEffects,
      MessageCountEffects,
      DefensesEffects,
      UniverseAccountResourceEffect,
      AccountAchievementEffect,
      TradeRoutesEffect,
    ]),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
    provideMessaging(() => getMessaging()),
    providePerformance(() => getPerformance()),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireMessagingModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    ToastModule,
    MenubarModule,
    NgOptimizedImage,
    CardModule,
    ButtonModule,
    SharedModule,
    FontAwesomeModule,
    LoadingBarHttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: LanguageService,
        deps: [HttpClient, MessageService]
      }
    }),
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    MessageService,
    ConfirmationService,
    DialogService,
    TabView,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
    { provide: VAPID_KEY, useValue: environment.vapidKey },
    { provide: SERVICE_WORKER, useFactory: () => typeof navigator !== 'undefined' && navigator.serviceWorker?.register('firebase-messaging-sw.js', { scope: '__' }) || undefined },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
