import {Injectable} from '@angular/core';
import {BaseHttpService} from "./base-http.service";
import {catchError} from "rxjs";
import {Planet} from "../../models/Planet";
import {PlanetResource} from "../../models/PlanetResource";
import {ProductionInfo} from "../../models/ProductionInfo";

@Injectable({
  providedIn: 'root'
})
export class PlanetService extends BaseHttpService {

  getPlanet(id) {
    return this.http.get<Planet>('api/universe/{universeId}/planet/' + id).pipe(
      catchError(this.handleError<Planet>('getPlanet'))
    );
  }

  patchPlanet(planet) {
    return this.http.patch<Planet>('api/universe/{universeId}/planet/{planetId}', planet).pipe(
      catchError(this.handleError<Planet>('patchPlanet'))
    );
  }

  getPlanets() {
    return this.http.get<Planet[]>('api/universe/{universeId}/planets/list').pipe(
      catchError(this.handleError<Planet[]>('getPlanet'))
    );
  }

  getMotherPlanet() {
    return this.http.get<Planet>('api/universe/{universeId}/planets/mother').pipe(
      catchError(this.handleError<Planet>('getMotherPlanet'))
    );
  }

  getLastActivePlanet() {
    return this.http.get<Planet>('api/universe/{universeId}/planets/lastActive').pipe(
      catchError(this.handleError<Planet>('getLastActivePlanet'))
    );
  }

  getPlanetResources() {
    return this.http.get<PlanetResource[]>('api/universe/{universeId}/planet/{planetId}/resources').pipe(
      catchError(this.handleError<PlanetResource[]>('getPlanetResources'))
    );
  }

  getPlanetProductionInfo() {
    return this.http.get<ProductionInfo>('api/universe/{universeId}/planet/{planetId}/productionInfo').pipe(
      catchError(this.handleError<ProductionInfo>('getPlanetProductionInfo'))
    );
  }
}
