import {Component} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {tap} from "rxjs/operators";
import {Ship} from "../../../../models/Ship";
import {ShipsSelector, refresh as refreshShips} from "../../../../store/ships";
import {selectHangar} from "../../../../store/buildings";
import {Building} from "../../../../models/Building";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {DefensesSelector, refresh as refreshDefenses} from "../../../../store/defenses";
import {Defense} from "../../../../models/Defense";

@UntilDestroy()
@Component({
  selector: 'app-hangar',
  templateUrl: './hangar.component.html',
  styleUrls: ['./hangar.component.scss']
})
export class HangarComponent {
  shipList: Ship[] = [];
  defenseList: Defense[] = [];
  hangar: Building = null;
  showShip = true;
  showDefense = false;
  showScrapDealer = false;


  constructor(
    private store: Store
  ) {
    store.select(selectHangar).pipe(untilDestroyed(this)).subscribe(hangar => {
      this.hangar = hangar;
    });
    store
      .pipe(
        select(ShipsSelector),
        tap((shipList) => {
          this.shipList = shipList;
        }),
        untilDestroyed(this),
      )
      .subscribe();
    store.dispatch(refreshShips());
    store
      .pipe(
        select(DefensesSelector),
        tap((defenseList) => {
          this.defenseList = defenseList;
        }),
        untilDestroyed(this),
      )
      .subscribe();
    store.dispatch(refreshDefenses());
  }
}
