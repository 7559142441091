import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AuthService} from "./services/auth.service";
import {environment} from "../environments/environment";
import {MenuItem} from "primeng/api";
import {AccountService} from "./services/http/account.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import firebase from 'firebase/compat/app';
import {MessageService} from "primeng/api";
import {MessagingService} from "./services/messaging.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'tld-wars-front';
  version = environment.version;
  items: MenuItem[];
  visible: boolean = true;
  versionRef: firebase.database.Reference;

  constructor(
    public auth: AuthService,
    public accountService: AccountService,
    private readonly messageDB: AngularFireDatabase,
    private readonly toastService: MessageService,
    private readonly messagingService: MessagingService,
    private readonly translate: TranslateService,
  ) {
    this.translate.setDefaultLang('fr');
  }

  private getMenuItem(array: any, label: string): any {
    return array.find(item => item.label === label);
  }

  initVersionCheck() {
    this.versionRef = this.messageDB.database.ref("front-config/version");
    console.log(this.versionRef);
    this.versionRef.on('value', data => {
      if (data.val() !== this.version) {
        this.toastService.clear('updateVersion');
        this.toastService.add({
          key: 'updateVersion',
          severity: 'info',
          summary: 'Nouvelle version disponible',
          detail: 'Actualisez la page pour obtenir la nouvelle version',
          sticky: true
        });
      }
    })
  }

  ngOnInit() {
    this.items = [
      {
        label: 'Selection de l\'univers',
        routerLink: 'universes',
        visible: false
      },
      {
        label: 'Admin',
        command: () => {
          let url = environment.api.baseUrl;
          url = url.substring(0, url.length - 3) + 'admin/login?token=' + this.auth.userToken;
          window.open(url, "_blank");
        },
        visible: false
      },
      {
        label: 'BugReport',
        command: () => {
          window.open("https://trello.com/invite/b/cHXMnDiK/ATTI1bb5c6067778b34d824c862c523a4d75AF000665/retour-tld-wars", "_blank");
        },
        visible: false
      }
    ];
    this.accountService.databaseUser.subscribe((user) => {
      if (user) {
        this.messagingService.init();
      }
      let universeSelection = this.getMenuItem(this.items, 'Selection de l\'univers');
      let admin = this.getMenuItem(this.items, 'Admin');
      let bugReport = this.getMenuItem(this.items, 'BugReport');
      let needRefresh = false;
      if (user) {
        if (!universeSelection.visible) {
          universeSelection.visible = true;
          needRefresh = true;
        }
        if (!bugReport.visible) {
          bugReport.visible = true;
          needRefresh = true;
        }
        if (user.has_dashboard_access) {
          if (!admin.visible) {
            admin.visible = true;
            needRefresh = true;
          }
        }
      } else {
        if (admin.visible) {
          admin.visible = false;
          needRefresh = true;
        }
        if (universeSelection.visible) {
          universeSelection.visible = false;
          needRefresh = true;
        }
        if (bugReport.visible) {
          bugReport.visible = false;
          needRefresh = true;
        }
      }
      if (needRefresh) {
        this.triggerMenuRefresh();
      }
    })
  }

  triggerMenuRefresh(): void {
    this.visible = false;
    setTimeout(() => this.visible = true, 0);
  }

  ngAfterViewInit(): void {
    this.initVersionCheck();
  }
}

