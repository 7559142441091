import {Routes} from "@angular/router";
import {UniverseSelectionComponent} from "./components/universe-selection/universe.selection.component";
import {AccountFinalizeComponent} from "./components/account-finalize/account-finalize.component";
import {LoginComponent} from "./components/login/login.component";
import {PageNotFoundComponent} from "./components/page-not-found/page-not-found.component";
import {authGuard} from "./guards/auth.guard";
import {HomeComponent} from "./components/home/home.component";

export const ROUTE_HOME = 'home';
export const ROUTE_LOGIN = 'login';
export const ROUTE_ACCOUNT = 'account';
export const ROUTE_ACCOUNT_FINALIZE = ROUTE_ACCOUNT + '/finalize';
export const ROUTE_UNIVERSES = 'universes';
export const routeList: Routes = [
  { path: '', redirectTo: ROUTE_HOME, pathMatch: 'full' },
  { path: ROUTE_LOGIN, component: LoginComponent },
  { path: ROUTE_HOME, component: HomeComponent },
  { path: ROUTE_UNIVERSES, component: UniverseSelectionComponent, canActivate: [authGuard]},
  { path: ROUTE_ACCOUNT_FINALIZE, component: AccountFinalizeComponent, canActivate: [authGuard]},
];
