@if (spyReport) {
  <div class="text-center">
    <div>
      <h3
        [translateParams]="{'targetPlanetName': spyReport.spy_data.planet.name, 'targetPlanetPosition': spyReport.spy_data.planet.position}"
        translate>message.spy.template.head</h3>
      <h4>{{ reportDate }}</h4>
    </div>
    <div class="text-left">
      <p-accordion [multiple]="true">
        <p-accordionTab header="Ressources">
          <ul class="list-none">
            @for (resource of spyReport.spy_data.resources; track resource.slug) {
              <li>
                {{ resource.slug }}: {{ resource.available_to_steal }} ({{ resource.on_planet }}
                - {{ resource.protected }})
              </li>
            }
          </ul>
        </p-accordionTab>
        <p-accordionTab
          header="Bâtiments{{ !spyReport.spy_data.spy_buildings ? ' (niveau d\'espionnage trop bas)' : '' }}"
          [disabled]="!spyReport.spy_data.spy_buildings">
          <ul class="list-none">
            @for (building of spyReport.spy_data.buildings; track building.name) {
              <li>
                <span translate>{{ building.name }}</span>: {{ building.level }}
              </li>
            } @empty {
              <li>
                <span translate>message.spy.template.no_buildings</span>
              </li>
            }
          </ul>
        </p-accordionTab>
        <p-accordionTab header="Recherches{{ !spyReport.spy_data.spy_tech ? ' (niveau d\'espionnage trop bas)' : '' }}"
                        [disabled]="!spyReport.spy_data.spy_tech">
          <ul class="list-none">
            @for (tech of spyReport.spy_data.tech; track tech.name) {
              <li>
                <span translate>{{ tech.name }}</span>: {{ tech.level }}
              </li>
            } @empty {
              <li>
                <span translate>message.spy.template.no_tech</span>
              </li>
            }
          </ul>
        </p-accordionTab>
        <p-accordionTab
          header="Défenses{{ !spyReport.spy_data.spy_defenses ? ' (niveau d\'espionnage trop bas)' : '' }}"
          [disabled]="!spyReport.spy_data.spy_defenses">
          <ul class="list-none">
            @for (defense of spyReport.spy_data.defenses; track defense.name) {
              <li>
                <span translate>{{ defense.name }}</span>: {{ defense.count }}
              </li>
            } @empty {
              <li>
                <span translate>message.spy.template.no_defenses</span>
              </li>
            }
          </ul>
        </p-accordionTab>
        <p-accordionTab header="Flottes{{ !spyReport.spy_data.spy_ships ? ' (niveau d\'espionnage trop bas)' : '' }}"
                        [disabled]="!spyReport.spy_data.spy_ships">
          <ul class="list-none">
            @for (ship of spyReport.spy_data.ships; track ship.name) {
              <li>
                <span translate>{{ ship.name }}</span>: {{ ship.count }}
              </li>
            } @empty {
              <li>
                <span translate>message.spy.template.no_ships</span>
              </li>
            }
          </ul>
        </p-accordionTab>
      </p-accordion>
      <div class="pt-2">
        @if (spyReport.spy_data.destroyed) {
          <span translate>message.spy.template.spy_probe_destroyed</span>
        } @else {
          <span [translateParams]="{'destroyChance': spyReport.spy_data.destroy_chance}" translate>message.spy.template.spy_probe_destroy_chance</span>
        }
      </div>
      <div class="pt-3 text-center">
        <p-button label="Ouvrir le simulateur" (onClick)="openSimulator()"/>
      </div>
    </div>
    <p-dialog [(visible)]="showSimulator" appendTo="body" header="Simulateur de combat">
      <app-battle-simulator/>
    </p-dialog>
  </div>
} @else {
  <div class="text-center">
    <span translate>common.loading</span>
  </div>
}
