import { Component } from '@angular/core';
import {select, Store} from "@ngrx/store";
import {tap} from "rxjs/operators";
import {ResearchesSelector, refresh as refreshResearches} from "../../../../store/researches";
import {selectLaboratory} from "../../../../store/buildings";
import {Building} from "../../../../models/Building";
import {Research} from "../../../../models/Research";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-laboratory',
  templateUrl: './laboratory.component.html',
  styleUrls: ['./laboratory.component.scss']
})
export class LaboratoryComponent {
  researchList: Research[] = [];
  laboratory: Building = null;
  constructor(
    private store: Store
  ) {
    store.select(selectLaboratory).pipe(untilDestroyed(this)).subscribe(laboratory => {
      this.laboratory = laboratory;
    });
    store
      .pipe(
        select(ResearchesSelector),
        tap((researchList) => {
          //console.log('refresh building', buildingList)
          this.researchList = researchList;
        }),
        untilDestroyed(this),
      )
      .subscribe();
    store.dispatch(refreshResearches());
  }
}
