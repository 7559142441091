import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as ShipsActions from './ships.actions';
import {ShipsSelector} from './ships.selector';
import {exhaustMap, map, switchMap, take, interval, tap, withLatestFrom, Subscription} from "rxjs";
import {BuildingService} from "../../services/http/building.service";
import {HangarService} from "../../services/http/hangar.service";

@Injectable()
export class ShipsEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private hangarService: HangarService,
  ) {
  }


  updateAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShipsActions.UPDATE),
        withLatestFrom(this.store.select(ShipsSelector)),
        // tap(([, universe]) => this.sentryService.setUser(universe)),
      ),
    {dispatch: false},
  );

  refreshAction$ = createEffect(
      () =>
        this.actions$.pipe(
          ofType(ShipsActions.REFRESH),
          switchMap(() => this.hangarService.getShipList().pipe(
            map(ships => {
              console.log("reloading ships");
              return ShipsActions.update({ships})
            })
          )),
        ),
    );
}
