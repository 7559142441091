import {createAction, props} from "@ngrx/store";
import {Planet} from "../../models/Planet";

export const UPDATE = '[Planet] Update';
export const REFRESH = '[Planet] Refresh';

export const update = createAction(UPDATE, props<{ planet: Planet }>());

export const refresh = createAction(REFRESH);

