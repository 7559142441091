import {Component} from '@angular/core';
import {LanguageService} from 'src/app/services/http/language.service';
import {Language} from "../../models/Language";
import {FormBuilder, Validators} from "@angular/forms";
import {AccountService} from "../../services/http/account.service";
import {Router} from "@angular/router";
import * as routes from "../../routes";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-account-finalize',
  templateUrl: './account-finalize.component.html',
  styleUrls: ['./account-finalize.component.scss']
})
export class AccountFinalizeComponent {
  accountForm = this.formBuilder.group({
    username: ['', [
      Validators.required,
      Validators.maxLength(255),
      Validators.pattern(/^[\w\-_]+$/)
    ]],
    locale: [0, Validators.required],
  });
  availableLanguages: Language[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private langService: LanguageService,
    private accountService: AccountService,
    private router: Router,
  ) {
    this.updateForm();
    accountService.databaseUser.pipe(untilDestroyed(this)).subscribe((user) => {
      if (user) {
        this.updateForm();
      }
    })
    langService.getAvailableLanguages().subscribe(availableLanguages => {
      this.availableLanguages = availableLanguages;
    });
  }

  private updateForm() {
    console.log(this.accountService.currentUser?.username, this.accountService.currentUser?.language_id)
    this.accountForm.patchValue({
      username: this.accountService.currentUser?.username,
      locale: this.accountService.currentUser?.language_id
    });
  }

  updateAccount() {
    console.log(this.accountForm.invalid, this.accountForm.errors)

    if (!this.accountForm.invalid) {
      if (this.accountForm.value.username && this.accountForm.value.locale) {
        this.accountService.finalizeUser(this.accountForm.value.username, this.accountForm.value.locale).subscribe(() => {
          this.router.navigate([routes.ROUTE_UNIVERSES]);
        });
      }
    }
  }

}
