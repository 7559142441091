import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as DefensesActions from './defenses.actions';
import {DefensesSelector} from './defenses.selector';
import {exhaustMap, map, switchMap, take, interval, tap, withLatestFrom, Subscription} from "rxjs";
import {HangarService} from "../../services/http/hangar.service";

@Injectable()
export class DefensesEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private hangarService: HangarService,
  ) {
  }

  updateAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DefensesActions.UPDATE),
        withLatestFrom(this.store.select(DefensesSelector)),
        // tap(([, universe]) => this.sentryService.setUser(universe)),
      ),
    {dispatch: false},
  );

  refreshAction$ = createEffect(
      () =>
        this.actions$.pipe(
          ofType(DefensesActions.REFRESH),
          exhaustMap(() => this.hangarService.getDefenseList().pipe(
            map(defenses => {
              console.log("reloading defenses");
              return DefensesActions.update({defenses})
            })
          )),
        ),
    );
}
