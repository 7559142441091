<div class="flex justify-content-center">
  <p-card [header]="planet.name" [subheader]="planet.used_field + ' / ' + planet.max_field">
        <ng-template pTemplate="header">
          <div class="text-center">
            Points => <span>@if(points !== -1) {{{points | numberDot }}} @else {loading}</span>
          </div>
        </ng-template>
    <div>
      <ul class="list-none p-0">
        <li>position: [{{planet.system_x}}:{{planet.system_y}}:{{planet.system_position}}]</li>
        <li>temperature minimal: {{planet.min_temperature}}</li>
        <li>temperature maximal: {{planet.max_temperature}}</li>
        <li>planète mère: {{planet.is_mother_planet ? 'oui' : 'non'}}</li>
        <li>lune: {{planet.moon_id ? 'oui' : 'non'}}</li>
      </ul>
    </div>
  </p-card>
</div>
<div>
  <app-building-queue [showHeader]="true"></app-building-queue>
  <app-hangar-queue [showHeader]="true"></app-hangar-queue>
  <app-research-queue [showHeader]="true"></app-research-queue>
  <app-fleet-table [showHeader]="true"></app-fleet-table>
</div>
