import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as messageCountActions from './message-count.actions';
import {MessageCountSelector} from './message-count.selector';
import {exhaustMap, map, withLatestFrom} from "rxjs";

import {MessageService} from "../../services/http/message.service";

@Injectable()
export class MessageCountEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private messageService: MessageService,
  ) {
  }


  updateAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(messageCountActions.UPDATE),
        withLatestFrom(this.store.select(MessageCountSelector)),
        // tap(([, universe]) => this.sentryService.setUser(universe)),
      ),
    {dispatch: false},
  );

  refreshAction$ = createEffect(
      () =>
        this.actions$.pipe(
          ofType(messageCountActions.REFRESH),
          exhaustMap(() => this.messageService.messageCountByType().pipe(
            map(messageCount => {
              console.log("reloading messageCount");
              return messageCountActions.update({messageCount})
            })
          )),
        ),
    );
}
