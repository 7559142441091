import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {TradeRoute} from 'src/app/models/TradeRoute';
import {select, Store} from "@ngrx/store";
import {planetListSelector} from "../../../../store/planet-list";
import {filter, tap} from "rxjs/operators";
import {Planet} from "../../../../models/Planet";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {faAngleDoubleRight} from "@fortawesome/free-solid-svg-icons";
import {planetResourceSelector} from "../../../../store/resource";
import {cloneDeep} from "lodash-es";
import {PlanetResource} from "../../../../models/PlanetResource";
import {take} from "rxjs";
import {ResourceType} from "../../../../models/Enum/ResourceType";
import {TradeRouteService} from "../../../../services/http/trade-route.service";
import {MessageService} from "primeng/api";
import {refresh as refreshTradeRoutes} from "../../../../store/trade-routes";

@UntilDestroy()
@Component({
  selector: 'app-trade-route-card',
  templateUrl: './trade-route-card.component.html',
  styleUrls: ['./trade-route-card.component.scss']
})
export class TradeRouteCardComponent implements AfterViewInit {
  @Input() tradeRoute: TradeRoute = null;
  @Input() isCreation: boolean = false;
  @Output() created = new EventEmitter();

  protected readonly faAngleDoubleRight = faAngleDoubleRight;

  planets: Planet[];
  resources: PlanetResource[];
  selectedSourcePlanet: Planet = null;
  selectedTargetPlanet: Planet = null;
  selectedResource: PlanetResource = null;

  constructor(
    private readonly store: Store,
    private readonly tradeRouteService: TradeRouteService,
    private toastService: MessageService,
  ) {

    this.store
      .pipe(
        select(planetListSelector),
        tap(planets => {
          this.planets = [];
          planets.forEach((planet) => {
            this.planets.push(Planet.formatPlanet(planet));
          });
          this.refreshSelected();
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.store
      .pipe(
        select(planetResourceSelector),
        filter((resources) => !!resources),
        take(1),
        untilDestroyed(this),
      )
      .subscribe((resources) => {
        this.resources = cloneDeep<PlanetResource[]>(resources).filter((resource) => {
          return resource.type === ResourceType.BUILD;
        });
        this.refreshSelected();
      });
  }

  update() {
    let updatedTradeRoute = cloneDeep<TradeRoute>(this.tradeRoute);
    updatedTradeRoute.source_id = this.selectedSourcePlanet.id;
    updatedTradeRoute.target_id = this.selectedTargetPlanet.id;
    updatedTradeRoute.resource_id = this.selectedResource.id;
    this.tradeRouteService.updateTradeRoute(this.tradeRoute.id, updatedTradeRoute).subscribe()
  }

  ngAfterViewInit(): void {
    if (this.isCreation) {
      this.tradeRoute = new TradeRoute();
      this.tradeRoute.quantity = 1;
      let date = new Date();
      this.tradeRoute.trigger_time = date.getHours() + ':' + date.getMinutes()
    } else {
      this.refreshSelected();
    }
  }

  refreshSelected() {
    if (false === this.isCreation && this.tradeRoute) {
      if (this.planets) {
        this.selectedSourcePlanet = this.planets.find(planet => planet.id == this.tradeRoute.source_id);
        this.selectedTargetPlanet = this.planets.find(planet => planet.id == this.tradeRoute.target_id);
      }
      if (this.resources) {
        this.selectedResource = this.resources.find(resource => resource.id == this.tradeRoute.resource_id);
      }
    }
  }

  create() {
    let updatedTradeRoute = cloneDeep<TradeRoute>(this.tradeRoute);
    updatedTradeRoute.source_id = this.selectedSourcePlanet.id;
    updatedTradeRoute.source_type = 'planet';
    updatedTradeRoute.target_id = this.selectedTargetPlanet.id;
    updatedTradeRoute.target_type = 'planet';
    updatedTradeRoute.resource_id = this.selectedResource.id;
    this.tradeRouteService.createTradeRoute(updatedTradeRoute).subscribe((response) => {
      if (response?.success) {
        this.created.emit();
        this.toastService.add({severity: 'success', summary: 'Success', detail: response.message});
      }
    })
  }

  deactivate() {
    this.tradeRouteService.deactivateTradeRoute(this.tradeRoute.id).subscribe(() => {
      this.store.dispatch(refreshTradeRoutes());
    })
  }

  activate() {
    this.tradeRouteService.activateTradeRoute(this.tradeRoute.id).subscribe(() => {
      this.store.dispatch(refreshTradeRoutes());
    })
  }

  isActive() {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    let activeUntil = new Date(this.tradeRoute.active_until);
    activeUntil.setHours(0, 0, 0, 0);
    return activeUntil >= currentDate;
  }

  canRenewActivation() {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    let activeUntil = new Date(this.tradeRoute.active_until);
    activeUntil.setHours(0, 0, 0, 0);
    return activeUntil <= currentDate;
  }
}
