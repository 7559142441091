<div>
  <form [formGroup]="battleForm">
    <div>
      <div formArrayName="attacker">
        <h2>Attacker</h2>
        <p-button (onClick)="fillWithMyData()">Remplir avec mes données</p-button>
        <div>
          <h3>Bonus</h3>
          <div formArrayName="bonus" class="flex flex-wrap">
            <div class="flex flex-column pr-2">
              <span translate>research.list.military_tech.name</span>
              <p-inputNumber inputId="bonusToSend-military-attacker"
                             [formControl]="battleForm.controls.attacker.controls.bonus.controls.attack" mode="decimal"
                             [min]="0"></p-inputNumber>
            </div>
            <div class="flex flex-column pr-2">
              <span translate>research.list.defence_tech.name</span>
              <p-inputNumber inputId="bonusToSend-defense-attacker"
                             [formControl]="battleForm.controls.attacker.controls.bonus.controls.hull" mode="decimal"
                             [min]="0"></p-inputNumber>
            </div>
            <div class="flex flex-column pr-2">
              <span translate>research.list.shield_tech.name</span>
              <p-inputNumber inputId="bonusToSend-shield-attacker"
                             [formControl]="battleForm.controls.attacker.controls.bonus.controls.shield" mode="decimal"
                             [min]="0"></p-inputNumber>
            </div>
          </div>
          <h3>Vaisseaux</h3>
          <div formArrayName="ships" class="flex flex-wrap">
            @for (shipGroup of attackerShip.controls; track shipGroup.value.ship; let i = $index) {
              <div class="flex flex-column pr-2">
                <ng-container *tldLet="getShipFromId(shipGroup.value.ship) as ship">
                  <span>{{ ship.name }}</span>
                  <p-inputNumber inputId="shipToSend-{{i}}" [formControl]="shipGroup.controls.count" mode="decimal"
                                 [min]="0"></p-inputNumber>
                </ng-container>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
    <div>
      <h2>Defender</h2>
      <div>
        <h3>Bonus</h3>
        <div formArrayName="bonus" class="flex flex-wrap">
          <div class="flex flex-column pr-2">
            <span translate>research.list.military_tech.name</span>
            <p-inputNumber inputId="bonusToSend-military-defender"
                           [formControl]="battleForm.controls.defender.controls.bonus.controls.attack" mode="decimal"
                           [min]="0"></p-inputNumber>
          </div>
          <div class="flex flex-column pr-2">
            <span translate>research.list.defence_tech.name</span>
            <p-inputNumber inputId="bonusToSend-defense-defender"
                           [formControl]="battleForm.controls.defender.controls.bonus.controls.hull" mode="decimal"
                           [min]="0"></p-inputNumber>
          </div>
          <div class="flex flex-column pr-2">
            <span translate>research.list.shield_tech.name</span>
            <p-inputNumber inputId="bonusToSend-shield-defender"
                           [formControl]="battleForm.controls.defender.controls.bonus.controls.shield" mode="decimal"
                           [min]="0"></p-inputNumber>
          </div>
        </div>
        <h3>Vaisseaux</h3>
        <div formArrayName="defender">
          <div formArrayName="ships" class="flex flex-wrap">
            @for (shipGroup of defenderShip.controls; track shipGroup.value.ship; let i = $index) {
              <div class="flex flex-column pr-2">
                <ng-container *tldLet="getShipFromId(shipGroup.value.ship) as ship">
                  <span>{{ ship.name }}</span>
                  <p-inputNumber inputId="shipToSend-{{i}}" [formControl]="shipGroup.controls.count" mode="decimal"
                                 [min]="0"></p-inputNumber>
                </ng-container>
              </div>
            }
          </div>
        </div>
      </div>
      <div>
        <h3>Defense</h3>
        <div formArrayName="defender">
          <div formArrayName="defenses" class="flex flex-wrap">
            @for (defenseGroup of defenderDefense.controls; track defenseGroup.value.defense; let i = $index) {
              <div class="flex flex-column pr-2">
                <ng-container *tldLet="getDefenseFromId(defenseGroup.value.defense) as defense">
                  <span>{{ defense.name }}</span>
                  <p-inputNumber inputId="shipToSend-{{i}}" [formControl]="defenseGroup.controls.count" mode="decimal"
                                 [min]="0" [max]="defense.max_count > 0 ? defense.max_count : null"></p-inputNumber>
                </ng-container>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="pt-3 text-center">
    <p-button (onClick)="simulateBattle()"
              [disabled]="showSimulationReportDialog || 0 == attackerUnitCount || 0 == defenderUnitCount"
              label="Simulate battle"></p-button>
  </div>
  <p-dialog [(visible)]="showSimulationReportDialog" (onHide)="simulationReport = null" appendTo="body">
    <app-simulation-report [data]="simulationReport"></app-simulation-report>
  </p-dialog>
</div>
