import {createFeatureSelector, createSelector, select} from "@ngrx/store";
import {BuildingsFeatureKey} from "./buildings.reducer";
import {Building} from "../../models/Building";
import {BuildingType} from "../../models/Enum/BuildingType";

export const BuildingsSelector = createFeatureSelector<Building[]>(BuildingsFeatureKey);

export const selectHangar = createSelector(
  BuildingsSelector,
  (buildings: Building[]) => buildings?.find(building => building.type === BuildingType.HANGAR),
);

export const selectLaboratory = createSelector(
  BuildingsSelector,
  (buildings: Building[]) => buildings?.find(building => building.type === BuildingType.LABORATORY),
);
