import {HostListener, Component, OnInit} from '@angular/core';
import {refresh as refreshResources} from "../../../store/resource";
import {refresh as refreshUniverseAccountResources} from "../../../store/universe-account-resource";
import {refresh as refreshAccountAchievement} from "../../../store/account-achievements";
import {refresh as refreshBuildings} from "../../../store/buildings";
import {refresh as refreshBuildingQueue} from "../../../store/building-queue";
import {refresh as refreshHangarQueue} from "../../../store/hangar-queue";
import {refresh as refreshShips} from "../../../store/ships";
import {refresh as refreshDefenses} from "../../../store/defenses";
import {refresh as refreshResearchQueue} from "../../../store/research-queue";
import {refresh as refreshResearches} from "../../../store/researches";
import {refresh as refreshFlyingFleets} from "../../../store/flying-fleets";
import {refresh as refreshMessageCount} from "../../../store/messages-count";
import {refresh as refreshTradeRoutes} from "../../../store/trade-routes";
import {Store} from "@ngrx/store";
import {ReloadAfterPlanetChanged, ReloadService} from "../../../services/ReloadService";
import {tap} from 'rxjs';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ActivatedRoute, Router} from "@angular/router";
import {AccountService} from "../../../services/http/account.service";

@UntilDestroy()
@Component({
  selector: 'app-planet',
  templateUrl: './planet.component.html',
  styleUrls: ['./planet.component.scss']
})
export class PlanetComponent implements OnInit, ReloadAfterPlanetChanged {

  showDailyBonusDialog = false;

  constructor(
    private readonly store: Store,
    private readonly reloadService: ReloadService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly accountService: AccountService,
  ) {
    this.accountService.getDailyBonusStatus().subscribe(response => {
      this.showDailyBonusDialog = response.is_bonus_available;
    });
  }

  readonly planetChangedSub = this.reloadService.planetChanged
    .pipe(
      tap(() => this.refresh()),
      untilDestroyed(this),
    )
    .subscribe();

  @HostListener('window:focus')
  onFocus() {
    this.store.dispatch(refreshResources());
  }

  ngOnInit() {
    this.initPlanet();
  }

  initPlanet() {
    this.refresh();
    this.store.dispatch(refreshResearchQueue());
    this.store.dispatch(refreshFlyingFleets());
    this.store.dispatch(refreshMessageCount());
    this.store.dispatch(refreshUniverseAccountResources());
    this.store.dispatch(refreshAccountAchievement());
    this.store.dispatch(refreshTradeRoutes());
  }

  refresh() {
    this.store.dispatch(refreshResources());
    this.store.dispatch(refreshBuildingQueue());
    this.store.dispatch(refreshBuildings());
    this.store.dispatch(refreshHangarQueue());
    this.store.dispatch(refreshShips());
    this.store.dispatch(refreshDefenses());
    this.store.dispatch(refreshResearches());
  }

  goToNewFeature() {
    this.router.navigate(['market'], {relativeTo: this.route});
  }
}
