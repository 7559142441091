<div>
  <div [formGroup]="accountForm">
    <div>
      <label for="username">Username:</label>
      <input id="username" name="username" formControlName="username">
      <ng-container *tldLet="accountForm.controls.username as username">
        @if (accountForm.invalid && (username.dirty || username.touched)) {
          <div class="alert alert-danger">
            @if (username.errors?.['required']) {
              <div>
                Username is required.
              </div>
            }
            @if (username.errors?.['pattern']) {
              <div>
                Username can only have alphanumeric characters, underscores and dashes.
              </div>
            }
          </div>
        }

      </ng-container>
      <div>
        <label for="locale">Locale: </label>
        <select id="locale" formControlName="locale">
          @for (lang of availableLanguages; track lang.id) {
            <option [value]="lang.id">{{lang.slug}}</option>
          }
        </select>
        <ng-container *tldLet="accountForm.controls.locale as locale">
          @if (accountForm.invalid && (locale.dirty || locale.touched)) {
            <div class="alert alert-danger">
              @if (locale.errors?.['required']) {
                <div>
                  Locale is required.
                </div>
              }
            </div>
          }
        </ng-container>
      </div>
    </div>
    <div>
      <button (click)="updateAccount()">Enregister</button>
    </div>
  </div>
  <div>
    {{ accountForm.invalid }}
  </div>
</div>
