import {Component} from '@angular/core';
import {AccountService} from 'src/app/services/http/account.service';
import {Store} from "@ngrx/store";
import {planetSelector} from "../../../../store/planet";
import {Planet} from "../../../../models/Planet";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss']
})
export class OverviewComponent {
    points = -1;
    planet: Planet;

    constructor(
        private store: Store,
        private accountService: AccountService,
    ) {
        this.accountService.getAccountPoints().subscribe((data) => {
            this.points = data.points.building + data.points.research + data.points.ship + data.points.defense;
        });
        this.store.select(planetSelector).pipe(untilDestroyed(this)).subscribe((planet) => {
            this.planet = planet;
        })
    }
}
