import {Component, Input} from '@angular/core';
import {SharedModule} from "../../../../../shared.module";
import {CombatPlayer} from "../../../../../models/CombatRound";
import {select, Store} from "@ngrx/store";
import {ShipsSelector} from "../../../../../store/ships";
import {filter, tap} from "rxjs/operators";
import {take} from "rxjs";
import {cloneDeep} from "lodash-es";
import {Ship} from "../../../../../models/Ship";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {DefensesSelector} from "../../../../../store/defenses";
import {Defense} from "../../../../../models/Defense";

@UntilDestroy()
@Component({
  selector: 'app-simulation-report-user-card',
  standalone: true,
    imports: [
        SharedModule
    ],
  templateUrl: './simulation-report-user-card.component.html',
  styleUrl: './simulation-report-user-card.component.scss'
})
export class SimulationReportUserCardComponent {
  @Input() data: CombatPlayer;
  private shipList: Ship[];
  private defenseList: Defense[];


  constructor(
    private readonly store: Store,
  ) {
    this.store.pipe(
      select(ShipsSelector),
      filter((ships) => !!ships && ships.length > 0),
      take(1),
      tap((ships) => {
        this.shipList = cloneDeep<Ship[]>(ships);
      }),
      untilDestroyed(this),
    ).subscribe();
    this.store.pipe(
      select(DefensesSelector),
      filter((defenses) => !!defenses.length && defenses.length > 0),
      take(1),
      tap((defenses) => {
        this.defenseList = cloneDeep<Defense[]>(defenses);
      }),
      untilDestroyed(this),
    )
      .subscribe();
  }

  getShip(){
    return this.data.Units.filter((unit) => unit.type === 'ship').sort((a, b) => a.id - b.id).map((unit) => {
      return {
        ...unit,
        ship: this.getShipFromId(unit.id)
      }
    })
  }

  getDefense(){
    return this.data.Units.filter((unit) => unit.type === 'defense').sort((a, b) => a.id - b.id).map((unit) => {
      return {
        ...unit,
        defense: this.getDefenseFromId(unit.id)
      }
    })
  }

  getShipFromId(id: number) {
    return this.shipList.find((ship) => ship.id === id);
  }

  getDefenseFromId(id: number) {
    return this.defenseList.find((defense) => defense.id === id);
  }
}
