<div>
  <div class="flex">
    <div class="border-1">
      <ul class="list-none p-0 chat" style="white-space: pre;">
        @for (message of messages; track message.id) {
          <li class="chatMessage" [class.chatMessageHighlight]="highlightMessage(message.text)">
            <div>
              <div style="display: inline-block; vertical-align: top">
                ({{ message.date | date:'dd/MM HH:mm' }})
                <span style="cursor:pointer;" (click)="addPseudoToMessage(message.user)">{{ message.user }}</span>:
              </div>
              <div style="display: inline-block">{{ message.text }}</div>
            </div>
          </li>
        }
      </ul>
    </div>
    <div class="border-1">
      <ul class="list-none p-0 userList">
        @for (user of onlineUser; track user.id) {
          <li><span style="cursor:pointer;" (click)="addPseudoToMessage(user.username)">{{ user.username }}</span></li>
        }
      </ul>
    </div>
  </div>
  <div class="messageInput">
    <textarea (keyup)="sendMessage($event)" [(ngModel)]="messageToSend" rows="2"></textarea>
  </div>
</div>
