import {Fleet} from "../models/Fleet";
import {MissingStatus} from "../models/Enum/MissingStatus";

export class FleetUtils {
    static getRemainingTime(fleet: Fleet, addDelay: boolean = false): number {
        let endTime = (new Date()).getTime();
        switch (fleet.mission_status) {
            case MissingStatus.GOING:
                endTime = (new Date(fleet.reach_date)).getTime();
                break;
            case MissingStatus.STAYING:
                endTime = (new Date(fleet.end_stay_date)).getTime();
                break;
            case MissingStatus.RETURNING:
                endTime = (new Date(fleet.end_date)).getTime();
                break;
        }

        return Math.round((endTime - (new Date()).getTime()) / 1000) + (addDelay ? 1 : 0);
    }

    static getTargetPosition(fleet: Fleet)
    {
      switch (fleet.mission_status) {
        case MissingStatus.GOING:
          return {x: fleet.target_system_x, y: fleet.target_system_y, position: fleet.target_system_position};
        case MissingStatus.RETURNING:
          return {x: fleet.start_system_x, y: fleet.start_system_y, position: fleet.start_system_position};
      }
      return null;
    }
}
