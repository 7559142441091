import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as UniverseAccountResourceActions from './universe-account-resource.actions';
import {universeAccountResourceSelector} from './universe-account-resource.selector';
import {map, switchMap, withLatestFrom} from "rxjs";
import {AccountService} from "../../services/http/account.service";

@Injectable()
export class UniverseAccountResourceEffect {
  constructor(
    private store: Store,
    private actions$: Actions,
    private accountService: AccountService,
  ) {
  }

  updateAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UniverseAccountResourceActions.UPDATE),
        withLatestFrom(this.store.select(universeAccountResourceSelector)),
        // tap(([, universe]) => this.sentryService.setUser(universe)),
      ),
    {dispatch: false},
  );

  refreshResources$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UniverseAccountResourceActions.REFRESH),
        switchMap(() => {
          console.log("reloading universeAccountresources");
          return this.accountService.getAccountResources().pipe(
            map(universeAccountResources => {
              return UniverseAccountResourceActions.update({universeAccountResources})
            })
          )
        }),
      )
  );
}
