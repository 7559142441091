import {createFeatureSelector, createSelector} from "@ngrx/store";
import {ShipsFeatureKey} from "./ships.reducer";
import {Building} from "../../models/Building";
import {Universe} from "../../models/Universe";
import {universeSelector} from "../universe";
import {Ship} from "../../models/Ship";

export const ShipsSelector = createFeatureSelector<Ship[]>(ShipsFeatureKey);

export const selectShipById = ({ship_id}) => createSelector(
  ShipsSelector,
  (ships: Ship[]) => ships?.find(ship => ship.id === ship_id),
);
