import {Injectable} from '@angular/core';
import {BaseHttpService} from "./base-http.service";
import {catchError, Observable} from "rxjs";
import {DefaultMessageResponse} from "../../models/DefaultMessageResponse";
import {HangarQueue} from "../../models/HangarQueue";
import {Ship} from "../../models/Ship";
import {Defense} from "../../models/Defense";
import {ShipCountList} from "../../models/requests/ShipCountList";
import {DefenseCountList} from "../../models/requests/DefenseCountList";

@Injectable({
  providedIn: 'root'
})
export class HangarService extends BaseHttpService {

  getShipList() {
    return this.http.get<Ship[]>('api/universe/{universeId}/planet/{planetId}/ships/list').pipe(
      catchError(this.handleError<Ship[]>('getShipList', []))
    );
  }

  getDefenseList() {
    return this.http.get<Defense[]>('api/universe/{universeId}/planet/{planetId}/defenses/list').pipe(
      catchError(this.handleError<Defense[]>('getDefenseList', []))
    );
  }

  getHangarQueue() {
    return this.http.get<HangarQueue[]>('api/universe/{universeId}/planet/{planetId}/hangar/queue').pipe(
      catchError(this.handleError<HangarQueue[]>('getHangarQueue', []))
    );
  }

  addToQueue(type: string, id: number, count: number): Observable<DefaultMessageResponse> {
    return this.http.post<DefaultMessageResponse>('api/universe/{universeId}/planet/{planetId}/hangar/addToQueue', {
      ship_id: id,
      type,
      id,
      count
    }).pipe(
      catchError(this.handleError<DefaultMessageResponse>('addShipToQueue'))
    );
  }

  removeShipFromQueue(queueId): Observable<DefaultMessageResponse> {
    return this.http.post<DefaultMessageResponse>('api/universe/{universeId}/planet/{planetId}/hangar/removeFromQueue', {id: queueId}).pipe(
      catchError(this.handleError<DefaultMessageResponse>('removeShipFromQueue'))
    );
  }

  recycle(request: { ships: ShipCountList[], defenses: DefenseCountList[] }): Observable<DefaultMessageResponse> {
    return this.http.post<DefaultMessageResponse>('api/universe/{universeId}/planet/{planetId}/hangar/recycle', request).pipe(
      catchError(this.handleError<DefaultMessageResponse>('recycle'))
    );
  }

}
