import {Injectable} from '@angular/core';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {Auth, idToken} from '@angular/fire/auth';
import {BehaviorSubject, from, of, Observable, Subscription, switchMap} from "rxjs";
import {AccountService} from './http/account.service';
import {JwtHelperService} from "./jwt-helper.service";
import {Router} from "@angular/router";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private tokenSubject: BehaviorSubject<string | null>;
  readonly token: Observable<string | null>;
  private idTokenSubscription: Subscription;
  private currentToken = null;

  constructor(
    private router: Router,
    private auth: AngularFireAuth,
    private accountService: AccountService,
    private fireAuth: Auth,
    private jwtHelper: JwtHelperService
  ) {
    let idToken$ = idToken(fireAuth);
    this.tokenSubject = new BehaviorSubject<string | null>(null);
    this.idTokenSubscription = idToken$.pipe(untilDestroyed(this)).subscribe((token: string | null) => {
      this.tokenSubject.next(token)
      console.log('idToken$', token);
    })
    this.token = this.tokenSubject.asObservable();
    this.token.pipe(untilDestroyed(this)).subscribe(token => {
      if (token && token != this.currentToken && accountService.currentUser === null) {
        accountService.loadDatabaseUser();
        this.currentToken = token;
      }
    })
  }

  public get currentAccount() {
    return this.accountService.currentUser;
  }

  isAuthenticated(): Observable<string | null> {
    if (this.userToken && !this.jwtHelper.isTokenExpired(this.userToken, 60)) {
      return of(this.userToken);
    }

    return this.auth.user.pipe(switchMap(user => {
      return from(user?.getIdToken()).pipe(switchMap(token => {
        if (this.jwtHelper.isTokenExpired(token, 60)) {
          console.log('token expired, refreshing');
          return from(user.getIdToken(true)).pipe(switchMap(token => {
            this.tokenSubject.next(token);
            return token;
          }));
        }
        this.tokenSubject.next(token);
        return token;
      }));
    }));
  }

  isLoggedIn(): boolean {
    return this.tokenSubject.value !== null;
  }

  logout() {
    this.tokenSubject.next(null);
    this.auth.signOut();
    this.accountService.logout();
    this.router.navigate(['']);
  }

  public get username(): string | undefined {
    return this.accountService.currentUser?.username;
  }

  public get userId(): string | undefined {
    return this.accountService.currentUser?.id;
  }

  public get userLocale(): string | undefined {
    return this.accountService.currentUser?.locale;
  }

  public get userLanguageId(): number | undefined {
    return this.accountService.currentUser?.language_id;
  }

  get userToken() {
    return this.tokenSubject.value;
  }
}
